import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsGantt from 'highcharts/modules/gantt';
import draggablePoints from 'highcharts/modules/draggable-points';

highchartsGantt(Highcharts);
draggablePoints(Highcharts);

let today: Date | number = new Date();

today.setUTCHours(0);
today.setUTCMinutes(0);
today.setUTCSeconds(0);
today.setUTCMilliseconds(0);
today = today.getTime();

Highcharts.setOptions({
  colors: ['#439B22', '#F28264', '#3F424F', '#94949F', '#ECEEE3', '#5E5E5F', '#FF9655', '#96C382', '#8E9097', '#C0C0C5', '#F3F5EE', '#A0A0A1', '#FAB5A4'],
});

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Tauri', sans-serif",
    },
    animation: true,
  },
});

Highcharts.setOptions({
  credits: { enabled: false },
});

const options = {
  chart: {
    spacingLeft: 1,
  },

  title: {
    text: 'Teams',
  },

  plotOptions: {
    series: {
      animation: false,
      dragDrop: {
        draggableX: true,
        draggableY: true,
        dragMinY: 0,
        dragMaxY: 2,
        dragPrecisionX: 0.5,
      },
      dataLabels: {
        enabled: true,
        format: '{point.name}',
        style: {
          cursor: 'default',
          pointerEvents: 'none',
        },
      },
      allowPointSelect: true,
    },
  },

  yAxis: {
    type: 'category',
    categories: ['Adam Clayton', 'Larry Mullen Jr.', 'David Howell Evans'],
    min: 0,
    max: 2,
  },

  xAxis: {
    currentDateIndicator: true,
  },

  tooltip: {
    xDateFormat: '%a %b %d, %H:%M',
  },

  series: [
    {
      name: 'Project 1',
      data: [
        {
          start: today + 1000 * 60 * 60 * 15,
          end: today + 1000 * 60 * 60 * 16.5,
          name: 'Cutting',
          id: 'prototype',
          y: 0,
        },
        {
          start: today + 1000 * 60 * 60 * 17,
          end: today + 1000 * 60 * 60 * 17.5,
          name: 'Pitch Repairs',
          dependency: 'proto_done',
          y: 0,
        },
        {
          start: today + 1000 * 60 * 60 * 16,
          end: today + 1000 * 60 * 60 * 16.5,
          name: 'Cutting',
          y: 1,
        },
        {
          start: today + 1000 * 60 * 60 * 17,
          end: today + 1000 * 60 * 60 * 17.5,
          name: 'Irrigation',
          y: 1,
        },
        {
          start: today + 1000 * 60 * 60 * 15.5,
          end: today + 1000 * 60 * 60 * 16.0,
          name: 'Fertilizing',
          id: 'testing',
          y: 2,
        },
        {
          start: today + 1000 * 60 * 60 * 16.5,
          end: today + 1000 * 60 * 60 * 18,
          name: 'Cleaning',
          dependency: 'testing',
          y: 2,
        },
      ],
    },
  ],
};

const TeamChart: React.FC = () => {
  return <HighchartsReact highcharts={Highcharts} constructorType="ganttChart" options={options} />;
};

export default TeamChart;
