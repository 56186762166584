import { EntityState } from '@reduxjs/toolkit';

export type Status = 'backlog' | 'inProgress' | 'toDo' | 'completed';
export type Type = 'maintenance' | 'usage' | 'test';

export type AgileCard = {
  id: string;
  title: string;
  status: Status;
  type: Type;
  deadline?: string;
  priority: number;
};

export type AgileCards = {
  backlog: AgileCard[];
  inProgress: AgileCard[];
  toDo: AgileCard[];
  completed: AgileCard[];
};
export type TasksBoardCard = {
  date: string;
  content: string;
};
export type TasksBoardCards = {
  type: string;
  cards: TasksBoardCard[];
}[];

export enum TaskStatusEnum {
  'Pending',
  'In Progress',
  'On Hold',
  'Completed',
  'Blocker',
}

export type TaskStatus = 0 | 1 | 2 | 3 | 4;

export type TaskEvent = {
  title: string;
  taskStatus: TaskStatus;
  eventStart: string;
  eventEnd: string;
  eventType: string;
  pitch: string;
  site: string;
  id: number;
  clubId: string;
};

export type TaskPlannerState = {
  fetching: { [key: string]: boolean };
  sending: { [key: string]: boolean };
  agileBoardCards: EntityState<AgileCard>;
  tasksBoardCards: TasksBoardCards;
  taskEvents: EntityState<TaskEvent>;
};

export type TaskEventsPayload = {
  dateFrom: string;
  dateTo: string;
  clubId: string;
  searchContext: string;
};

export type Filter = {
  [key: string]: {
    condition: 'or' | 'and' | 'notOr' | 'notAnd';
    values: (string | number)[];
  };
};
