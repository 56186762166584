import React, { MouseEvent } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Button, DatePicker, Divider, Space } from 'antd';
import getRelativeDate from 'utilities/getRelativeDate';
import { DateTime } from 'luxon';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CalendarOutlined } from '@ant-design/icons';
import { CustomColumnProps } from '../types';
import style from '../Table.module.scss';

function getDateColumn<T extends Record<string, unknown>>(dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  return {
    filterDropdown: function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) {
      const value = selectedKeys?.[0] || '';
      return (
        <div style={{ padding: 8 }}>
          <div className="mb-2">
            <DatePicker
              style={{
                width: '100%',
              }}
              onChange={(date, dateString) => {
                if (setSelectedKeys) {
                  setSelectedKeys([dateString]);
                }
              }}
            />
          </div>
          <div className={style.filterDividerHolder}>
            <Divider className={style.filterDivider} />
          </div>
          <Space>
            <Button onClick={clearFilters} size="small" type="link" disabled={!value} style={{ width: 90 }}>
              Reset
            </Button>
            <Button type="primary" onClick={(confirm as unknown) as (event: MouseEvent<HTMLElement>) => void} size="small" style={{ width: 90 }}>
              Search
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: () => <CalendarOutlined className={style.calendarIcon} />,
    onFilter: (value, record: T) => {
      return ((record[dataIndex] as unknown) as string).toLocaleString() === value;
    },
    render: (day) => getRelativeDate(day),
    sorter: (a, b) => {
      const aDate = DateTime.fromISO((a[dataIndex] as unknown) as string).toMillis();
      const bDate = DateTime.fromISO((b[dataIndex] as unknown) as string).toMillis();
      return aDate - bDate;
    },
    sortDirections: ['descend', 'ascend'],
  };
}

export default getDateColumn;
