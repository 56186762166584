import { EntityState } from '@reduxjs/toolkit';

export type DirectionsData = {
  id: number;
  name: string;
  value: string;
};

export type EmployeesData = {
  id: number;
  firstName: string;
  lastName: string;
};

export type AreasData = {
  id: number;
  fieldId: number;
  name: string;
};

export type FieldsData = {
  id: number;
  areas: AreasData[];
  name: string;
  height: number;
  direction: string;
};

export type MachinesData = {
  id: number;
  model: string;
};

export type StatusData = { [key: string]: string };

export type Profile = {
  id: string;
  directions: DirectionsData[];
  employees: EmployeesData[];
  fields: FieldsData[];
  machines: MachinesData[];
  status: StatusData;
};

export type ProfileState = {
  fetching: { [key: string]: boolean };
  mowing: EntityState<Profile>;
  irrigation: EntityState<Profile>;
};

export enum ProfileStatus {
  pending = 'Pending',
  inprogress = 'In Progress',
  onhold = 'On Hold',
  completed = 'Completed',
  blocker = 'Blocker',
}

export enum Direction {
  'direction-cornertocorner' = 'Corner to Corner',
  'direction-crosswise' = 'Crosswise',
  'direction-lengthwise' = 'Lengthwise',
  'direction-other' = 'Other',
}

export type Context = 'mowing' | 'irrigation';
