import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import style from './Table.module.scss';

type Confirm = (event: SyntheticEvent) => void;

function FilterDropdown<T extends Record<string, unknown>>(
  dataIndex: keyof T,
  { setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps,
): JSX.Element {
  const value = selectedKeys?.[0] || '';
  const { t } = useTranslation();
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={t('Search')}
        value={value}
        onChange={(e) => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={(confirm as unknown) as Confirm}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <div className={style.filterDividerHolder}>
        <Divider className={style.filterDivider} />
      </div>
      <Space>
        <Button onClick={clearFilters} size="small" type="link" disabled={!value} style={{ width: 90 }}>
          {t('Reset')}
        </Button>
        <Button type="primary" onClick={(confirm as unknown) as Confirm} size="small" style={{ width: 90 }}>
          {t('Search')}
        </Button>
      </Space>
    </div>
  );
}

export default FilterDropdown;
