import React from 'react';
import { useTranslation } from 'react-i18next';
import ImportantDates from '../ImportantDates';
import CardShadowWithButtons from '../CardShadow';

const ImportantDatesCard: React.FC = () => {
  const { t } = useTranslation('tournamentDashboard');
  return (
    <CardShadowWithButtons title={t('Important dates')} icon="fe fe-calendar" noButtons>
      <ImportantDates />
    </CardShadowWithButtons>
  );
};

export default ImportantDatesCard;
