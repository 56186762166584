import { createSelector } from '@reduxjs/toolkit';
import { imagesAll, pitchTestsTableAll, pitchUsageAll, workLogTableAll } from './slice';
import { State } from '../../store';

export const pitchUsageSelector = createSelector([pitchUsageAll], (pitchUsage) => pitchUsage);
export const imagesSelector = createSelector([imagesAll], (images) => images);
export const workLogTableSelector = createSelector([workLogTableAll], (rows) => rows);
export const pitchTestsTableSelector = createSelector([pitchTestsTableAll], (rows) => rows);
export const searchImagesSelector = createSelector([(state: State) => state.dashboard.searchImages], (searchImages) => searchImages);
export const searchDocumentsSelector = createSelector([(state: State) => state.dashboard.searchDocuments], (searchDocuments) => searchDocuments);
