import React, { useState } from 'react';
import ChatThread from './ChatThread';
import { ChatMessage, NewChatMessage } from '../../modules/tournamentDashboard/types';
import ChatDrawer from './ChatDrawer';
import style from './Chat.module.scss';

type Props = {
  threads: { parentMessage: ChatMessage; messages: ChatMessage[] }[];
  addMessage: (message: NewChatMessage) => void;
  userName: string;
  className: string;
};

const Chat: React.FC<Props> = ({ threads, className, ...props }: Props): JSX.Element => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedThread, setSelectedThread] = useState(0);

  const onDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const onDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  return (
    <div>
      <ul className={style.chatHolder}>
        {threads.map((item, index) => (
          <div key={item.parentMessage.id}>
            <ChatThread data={item} onDrawerOpen={onDrawerOpen} setSelectedThread={setSelectedThread} index={index} />
          </div>
        ))}
      </ul>
      {threads.length > 0 && (
        <ChatDrawer
          thread={threads[selectedThread].parentMessage}
          messages={threads[selectedThread].messages}
          visible={isDrawerVisible}
          onClose={onDrawerClose}
          {...props}
        />
      )}
    </div>
  );
};

export default Chat;
