import React from 'react';
import { useTranslation } from 'react-i18next';
import MediaTable from '../MediaTable';
import CardShadowWithButtons from '../CardShadow';

const MediaTableCard: React.FC = () => {
  const { t } = useTranslation('tournamentDashboard');

  return (
    <CardShadowWithButtons title={t('Media')} icon="fe fe-file" noButtons>
      <MediaTable />
    </CardShadowWithButtons>
  );
};

export default MediaTableCard;
