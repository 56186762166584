import React from 'react';
import { Col, Input, Row, TimePicker } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormField from '../../FormField';
import styles from './IrrigationForm.module.scss';
import MultiSelect from '../../MultiSelect';
import { useSelector } from '../../../store';
import { getProfileAreasByFieldIdForForm } from '../../../modules/profile/selectors';
import { valueIsNumber } from '../../../utilities/helpers';
import { timePickerFormat } from '../../MaintenanceTasksModal';
import { IrrigationField } from '../../../modules/tasks/types';

type Props = {
  index: number;
  field: IrrigationField;
  isLoading: boolean;
};
const IrrigationForm: React.FC<Props> = ({ index = 0, field, isLoading }: Props) => {
  const { t } = useTranslation('task');
  const areas = useSelector(getProfileAreasByFieldIdForForm)(field.id, 'irrigation');

  const { control, register } = useFormContext();

  return (
    <>
      <input
        type="hidden"
        {...register(`fields.${index}.id`, {
          valueAsNumber: true,
        })}
        value={field.id}
      />
      <Row gutter={32} className={styles.formCols}>
        <Col span={12} className="ant-form ant-form-large">
          <Controller
            control={control}
            name={`fields.${index}.areas`}
            defaultValue={field.areas || []}
            render={({ field: { value, onChange, name } }) => (
              <FormField name={name} label={t('Areas')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 19 }} loading={isLoading}>
                <MultiSelect value={value} name={name} options={areas} onChange={onChange} isNumber />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name={`fields.${index}.amount`}
            defaultValue={field.amount || 0}
            render={({ field: { value, onChange, name } }) => (
              <FormField name={name} label={t('Amount')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 19 }} loading={isLoading}>
                <Input
                  value={value}
                  onChange={(e) => {
                    if (valueIsNumber(e.target.value)) {
                      onChange(e);
                    }
                  }}
                  name={name}
                  suffix="mm"
                  className="short text-right"
                  readOnly={isLoading}
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name={`fields.${index}.duration`}
            defaultValue={field.duration || []}
            render={({ field: { name, ...rest } }) => (
              <FormField name={name} label={t('Duration')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 10 }} loading={isLoading}>
                <TimePicker.RangePicker
                  {...rest}
                  name={name}
                  className={styles.timePicker}
                  format={timePickerFormat}
                  minuteStep={15}
                  allowClear={false}
                  popupClassName={styles.timePickerPopup}
                />
              </FormField>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default IrrigationForm;
