import axiosRequest from '../../utilities/axiosRequest';
import { Profile } from './types';

export async function loadProfile({ clubId, context }: { clubId: string; context: string }): Promise<Profile> {
  return axiosRequest({
    method: 'POST',
    url: `/Calendar/${clubId}/profile`,
    data: { context },
    caller: 'loadProfile',
    targetAPI: 'SYSTEM_API',
  });
}

export default {
  loadProfile,
};
