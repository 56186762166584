import { useEffect, useState } from 'react';

// antd core & themes styles
import 'antd/lib/style/index.less';
import '../../css/vendors/antd/themes/default.less';
import '../../css/vendors/antd/themes/dark.less';

// third-party plugins styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'c3/c3.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// vb vendors styles
import '../../css/vendors/antd/style.scss';
import '../../css/vendors/bootstrap/style.scss';
import '../../css/vendors/perfect-scrollbar/style.scss';
import '../../css/vendors/nprogress/style.scss';
import '../../css/vendors/chartist/style.scss';
import '../../css/vendors/chartist-tooltip-plugin/style.scss';
import '../../css/vendors/jvectormap/style.scss';
import '../../css/vendors/react-sortable-tree/style.scss';
import '../../css/vendors/react-draft-wysiwyg/style.scss';

// vb styles
import '../../css/core.scss';
import '../../css/measurements.scss';
import '../../css/colors.scss';
import '../../css/utils.scss';
import '../../css/layout.scss';
import '../../css/router.scss';
import '../../css/spaces.scss';
import '../../css/position.scss';
import '../../css/export.scss';

// vb extra styles
import '../../css/extra/overwrite.scss';
import '../../css/extra/clean.scss'; // clean styles
import '../../css/extra/air.scss';

import '../../css/helpers.scss';

import { useDispatch, useSelector } from '../../store';
import { getCurrentLanguage, themeSettingsSelector } from '../../modules/core/selectors';
import { setThemeProps } from '../../modules/core/slice';
import { useSetUserLanguage } from '../../modules/core/hooks'; // air styles
import settings, { Languages } from '../../settings';
import LoadingScreen from '../LoadingScreen';

const { supportedLanguages }: { supportedLanguages: Languages } = settings;

let isLoaded = false;

export default function StylesLoader({ children }: { children: JSX.Element }): JSX.Element {
  const dispatch = useDispatch();
  const { version, theme, primaryColor } = useSelector(themeSettingsSelector);
  const currentLanguage = useSelector(getCurrentLanguage);
  const setUserLanguage = useSetUserLanguage();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [fontsLoaded, setFontsLoaded] = useState(!document.fonts);

  useEffect(() => {
    const loadFonts = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await Promise.all(['Feather', 'Segoe UI'].map((font) => document.fonts.load(`16px "${font}"`)));
      setFontsLoaded(true);
    };

    loadFonts();
    // If fonts won't load
    setTimeout(() => setFontsLoaded(true), 3000);
  }, []);

  useEffect(() => {
    if (supportedLanguages[currentLanguage]?.lng) {
      setUserLanguage(supportedLanguages[currentLanguage].lng);
    }
  }, [setUserLanguage, currentLanguage]);

  // listen & set vb-version (pro, air, fluent, ...)
  useEffect(() => {
    document?.querySelector('html')?.setAttribute('data-vb-version', version);
  }, [version]);

  // listen & set vb-theme (dark, default, ...)
  useEffect(() => {
    document?.querySelector('html')?.setAttribute('data-vb-theme', theme);
    dispatch(
      setThemeProps({
        theme,
      }),
    );

    if (isLoaded) {
      dispatch(
        setThemeProps({
          menuColor: (() => {
            if (theme === 'dark') {
              return 'dark';
            }
            return theme === 'default' ? 'gray' : 'white';
          })(),
        }),
      );
    }
    isLoaded = true;
  }, [theme, dispatch]);

  // listen & set primaryColor
  useEffect(() => {
    const styleElement = document.querySelector('#primaryColor');
    if (styleElement) {
      styleElement.remove();
    }
    const body = document.querySelector('body');
    const styleEl = document.createElement('style');
    const css = document.createTextNode(`:root { --vb-color-primary: ${primaryColor};}`);
    styleEl.setAttribute('id', 'primaryColor');
    styleEl.appendChild(css);
    body?.appendChild(styleEl);
  }, [primaryColor]);

  return fontsLoaded ? children : <LoadingScreen />;
}
