import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Category } from '../types';
import style from './MenuLeftFlyout.module.scss';

type Props = {
  item: Category;
  activeItem: string | null;
};

const MenuItem: React.FC<Props> = ({ item, activeItem }: Props) => {
  return (
    <li
      className={classNames(style.air__menuLeft__item, {
        [style.air__menuLeft__item__active]: activeItem === item.id,
      })}
      key={item.id}
    >
      {item.url && (
        <Link to={item.url} className={style.air__menuLeft__link}>
          {item.icon && <i className={classNames('fe', item.icon, style.air__menuLeft__icon)} />}
          <span>{item.title}</span>
        </Link>
      )}
      {!item.url && (
        <a href="#" onClick={(e) => e.preventDefault()} className={style.air__menuLeft__link}>
          {item.icon && <i className={classNames('fe', item.icon, style.air__menuLeft__icon)} />}
          <span>{item.title}</span>
        </a>
      )}
    </li>
  );
};

export default MenuItem;
