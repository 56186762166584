import './polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import StylesLoader from './components/StylesLoader/StylesLoader';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import userManager from './user-manager';

if (window.location.pathname === '/authentication/silent-callback') {
  userManager.signinSilentCallback();
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={null}>
        <StylesLoader>
          <App />
        </StylesLoader>
      </Suspense>
    </Provider>,
    document.querySelector('#root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
