import getMultiSelectColumn from './getMultiSelectColumn';
import getDateColumn from './getDateColumn';
import getStatusColumn from './getStatusColumn';
import getSearchColumn from './getSearchColumn';
import getChartColumn from './getChartColumn';
import getActionsColumn from './getActionsColumn';
import getLinksColumn from './getLinksColumn';
import getFileIconsColumn from './getFileIconsColumn';
import getNotFilteredColumn from './getNotFilteredColumn';
import getStatusColumnText from './getStatusColumnText';
import { Column } from '../types';

export default function getProcessedColumns<T extends Record<string, unknown>>(data: T[], columns: Column<T>[]): Column<T>[] {
  return columns.map((column: Column<T>) => {
    return {
      ...column,
      ...(!column.dataType && column.dataIndex ? getMultiSelectColumn(data, column.dataIndex) : {}),
      ...(column.dataType === 'date' && column.dataIndex ? getDateColumn(column.dataIndex) : {}),
      ...(column.dataType === 'status' && column.dataIndex ? getStatusColumn(data, column.dataIndex) : {}),
      ...(column.dataType === 'statusText' && column.dataIndex && column.checkIndex ? getStatusColumnText(data, column.dataIndex, column.checkIndex) : {}),
      ...((column.dataType === 'string' || column.dataType === 'number') && column.dataIndex ? getSearchColumn(column.dataType, column.dataIndex) : {}),
      ...(column.dataType === 'chart' ? getChartColumn() : {}),
      ...(column.dataType === 'actions' ? getActionsColumn(column.actions) : {}),
      ...(column.dataType === 'links' && column.dataIndex ? getLinksColumn(column.dataIndex) : {}),
      ...(column.dataType === 'file' && column.dataIndex ? getFileIconsColumn(data, column.dataIndex) : {}),
      ...(column.dataType === 'not filtered' && column.dataIndex ? getNotFilteredColumn(column.dataIndex) : {}),
      ...(column.dataType === 'not filtered & sorted' && {}),
    };
  }) as Column<T>[];
}
