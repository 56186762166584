import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import pie from 'highcharts/modules/variable-pie';

pie(Highcharts);

function getRandomValue() {
  const min = 1;
  const max = 70;
  return (Math.random() * (max - min) + min).toFixed(1);
}

type Props = {
  selectedData: string;
  selectedRow: string;
};

const BreakdownChart: React.FC<Props> = ({ selectedData, selectedRow }: Props): JSX.Element => {
  const options = useMemo(() => {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      colors: ['#439B22', '#F28264', '#ECEEE3', '#5E5E5F'],
      exporting: {
        enabled: false,
      },
      title: {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        y: 10,
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Source Sans Pro',
        },
      },
      legend: {
        y: -30,
        floating: true,
        margin: 0,
        verticalAlign: 'bottom',
        padding: 20,
        itemMarginTop: -50,
        itemMarginBottom: 5,
        itemStyle: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Source Sans Pro',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14px',
              fontWeight: 'normal',
              fontFamily: 'Source Sans Pro',
            },
          },
          showInLegend: true,
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '100%',
        },
      },
      series: [
        {
          animation: false,
          type: 'pie',
          name: 'Browser share',
          innerSize: '50%',
          data: [
            ['Mowing', +getRandomValue()],
            ['Irrigation', +getRandomValue()],
            ['Pitch Repairs', +getRandomValue()],
            ['Other', +getRandomValue()],
          ],
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, selectedRow, getRandomValue]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BreakdownChart;
