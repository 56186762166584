import React from 'react';
import { Avatar, Badge } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { noop } from 'lodash';
import ColorHash from 'color-hash';
import style from './SelectAvatar.module.scss';

const colorHash = new ColorHash();

const getShortedName = (name = ''): string => {
  if (!name) {
    return '';
  }
  const splitName = name.split(' ');
  const firstLetter = splitName[0].substr(0, 1);
  const secondLetter = splitName.length > 1 ? splitName[1].substr(0, 1) : '';
  return firstLetter + secondLetter;
};

type Props = {
  label: string;
  closable?: boolean;
  onClose?: () => void;
};

const SelectAvatar: React.FC<Props> = ({ label = '', closable = false, onClose = noop, ...props }: Props) => {
  const name = getShortedName(label);
  const bg = colorHash.hex(label);
  const badge = closable ? (
    <button
      type="button"
      aria-label="close"
      className={style.closeAvatar}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <CloseOutlined />
    </button>
  ) : (
    0
  );
  return (
    <Badge className={style.selectBadge} count={badge} offset={[-5, 5]}>
      <Avatar className={style.selectAvatar} size={28} style={{ backgroundColor: bg, marginRight: 3, fontSize: 12 }} {...props}>
        {name}
      </Avatar>
    </Badge>
  );
};

export default SelectAvatar;
