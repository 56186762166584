import React, { ReactNode } from 'react';
import { Tabs } from 'antd';
import classnames from 'classnames';
import CardHeader from '../CardHeader/CardHeader';
import styles from './TabCard.module.scss';

type Props = {
  icon?: string;
  title: string;
  content: { title: string; content: ReactNode | JSX.Element }[];
  headerContent?: JSX.Element;
  height?: number;
  onChange?: (tab: string) => void;
};

const TabCard: React.FC<Props> = ({ icon, title, content, headerContent, height, onChange }: Props) => {
  return (
    <div className={classnames('card', styles.card)} {...(height ? { style: { height } } : {})}>
      <div className="card-header">
        <CardHeader title={title} icon={icon} extraContent={headerContent} />
      </div>
      <div className="card-body">
        <Tabs defaultActiveKey="0" animated={false} className={styles.tabs} onChange={onChange}>
          {content.map((item) => (
            <Tabs.TabPane tab={item.title} key={item.title}>
              {item.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default TabCard;
