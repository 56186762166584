import React from 'react';
import ImportantDates from './ImportantDates';
import { useSelector } from '../../store';
import { selectImportantDatesAll } from '../../modules/tournamentDashboard/slice';

export default function ImportantDatesIndex(): JSX.Element {
  const importantDates = useSelector(selectImportantDatesAll);

  return <ImportantDates data={importantDates} />;
}
