import React from 'react';
import { useTranslation } from 'react-i18next';
import HeatMap from './HeatMap';
import CardShadowWithButtons from '../../../CardShadow';

const stops = [
  [0.0, '#f5222d'], // RED
  [0.6, '#f5222d'], // RED

  [0.6, '#fa8c16'], // ORANGE
  [0.7, '#fa8c16'], // ORANGE

  [0.7, '#52c41a'], // GREEN
  [0.9, '#52c41a'], // GREEN

  [0.9, '#fa8c16'], // ORANGE
  [1.0, '#fa8c16'], // ORANGE

  [1.01, '#f5222d'], // RED
];

type Props = {
  setPopup: (props: { isOpen: boolean; type: string | null; location: string | null }) => void;
  selectedData: string;
};

const FirmnessHeatMaCard: React.FC<Props> = ({ selectedData, setPopup }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');

  return (
    <CardShadowWithButtons title={t('Firmness')} icon="fe fe-sliders" noButtons>
      <HeatMap selectedData={selectedData} setPopup={setPopup} type="Firmness" stops={stops} min={40} max={120} />
    </CardShadowWithButtons>
  );
};

export default FirmnessHeatMaCard;
