import React, { useEffect } from 'react';
import TaskPlanner from './TaskPlanner';
import { useDispatch, useSelector } from '../../store';
import { loadEventsTasks } from '../../modules/taskPlanner/slice';
import settings from '../../settings';
import { getActiveClubId } from '../../modules/core/selectors';

const { tasksStartDate, tasksEndDate } = settings;

export default function TaskPlannerIndex(): JSX.Element {
  const dispatch = useDispatch();
  const activeClubId = useSelector(getActiveClubId);

  useEffect(() => {
    dispatch(loadEventsTasks({ dateFrom: tasksStartDate, dateTo: tasksEndDate, clubId: activeClubId, searchContext: 'All' }));
  }, [dispatch, activeClubId]);

  return <TaskPlanner />;
}
