import React, { useEffect, useState } from 'react';
import { getDurationAfterDate } from '../../utilities/getRelativeDate';

type Props = {
  time: string;
};

const ChatTimeCount: React.FC<Props> = ({ time }: Props) => {
  const [messageTime, setMessageTime] = useState(getDurationAfterDate(time));

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageTime(getDurationAfterDate(time));
    }, 60000);
    return () => clearInterval(interval);
  }, [messageTime, time]);

  return <span>{messageTime}</span>;
};

export default ChatTimeCount;
