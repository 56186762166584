import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { times, random, orderBy, identity } from 'lodash';

function getRandomValue() {
  return Number(random(0, 1, true).toFixed(1));
}

function fillDatesRange(length: number) {
  let date = DateTime.local().plus({ days: 1 });
  const randomValues = orderBy(
    times(length, (index) => index + 2 + random(1, true)),
    identity,
    'desc',
  );
  return times(length, (index) => {
    date = date.minus({ days: 1 });
    return {
      x: date.toJSDate(),
      y: +randomValues[index].toFixed(1),
      custom: getRandomValue(),
    };
  });
}

const CumulativeUsageChart: React.FC<{ selectedData: string; selectedRow: string }> = ({
  selectedData,
  selectedRow,
}: {
  selectedData: string;
  selectedRow: string;
}) => {
  const chartData = useMemo(() => {
    if (selectedData === '7') {
      return {
        maxValue: 6,
        dataMatch: fillDatesRange(7),
        dataTraining: fillDatesRange(7),
        dataExtraTime: fillDatesRange(7),
        dataPenalties: fillDatesRange(7),
        dataOther: fillDatesRange(7),
      };
    }
    if (selectedData === '60') {
      return {
        maxValue: 60,
        dataMatch: fillDatesRange(60),
        dataTraining: fillDatesRange(60),
        dataExtraTime: fillDatesRange(60),
        dataPenalties: fillDatesRange(60),
        dataOther: fillDatesRange(60),
      };
    }
    return {
      maxValue: 25,
      dataMatch: fillDatesRange(30),
      dataTraining: fillDatesRange(30),
      dataExtraTime: fillDatesRange(30),
      dataPenalties: fillDatesRange(30),
      dataOther: fillDatesRange(30),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, selectedRow]);

  const options = useMemo(() => {
    return {
      chart: {
        type: 'areaspline',
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Source Sans Pro',
        },
      },
      subtitle: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      colors: ['#439b22', '#3f424f', '#5e5e5f', '#94949f', '#eceee3'],
      xAxis: {
        // categories: ['1750', '1800', '1850', '1900', '1950', '1999', '2050'],
        tickmarkPlacement: 'on',
        type: 'datetime',
        title: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Source Sans Pro',
          },
        },
      },
      yAxis: {
        plotLines: [
          {
            color: '#f00',
            width: 3,
            value: chartData.maxValue,
            label: {
              text: 'Max Usage',
              align: 'center',
              textAlign: 'center',
              style: {
                fontSize: '14px',
                fontWeight: 'normal',
                fontFamily: 'Source Sans Pro',
              },
            },
            zIndex: 4,
          },
        ],
        title: {
          text: 'Hours of usage',
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Source Sans Pro',
          },
        },
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Source Sans Pro',
          },
        },
      },
      tooltip: {
        enabled: true,
        split: false,
        useHTML: true,
        pointFormat: '{series.name}: <b>{point.custom}</b> hours. (Cumulative: {point.y} hours.)',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Source Sans Pro',
        },
      },
      plotOptions: {
        series: {
          marker: {
            symbol: 'circle',
            enabled: false,
          },
        },
        areaspline: {
          stacking: 'normal',
          // lineColor: '#666666',
          // lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666',
          },
        },
      },
      animation: false,
      series: [
        {
          name: 'Match',
          data: chartData.dataMatch,
          animation: false,
        },
        {
          name: 'Training',
          data: chartData.dataTraining,
          animation: false,
        },
        {
          name: 'Extra Time',
          data: chartData.dataExtraTime,
          animation: false,
        },
        {
          name: 'Penalties',
          data: chartData.dataPenalties,
          animation: false,
        },
        {
          name: 'Other',
          data: chartData.dataOther,
          animation: false,
        },
      ],
    };
  }, [chartData]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CumulativeUsageChart;
