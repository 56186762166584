import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Category } from '../types';
import { useSelector } from '../../../store';
import { themeSettingsSelector } from '../../../modules/core/selectors';
import style from './MenuLeftFlyout.module.scss';

type Props = {
  items: Category[];
  id: string;
  itemDimensions: DOMRect;
  activeItem?: string;
  handleFlyoutContainerOver: (key: string) => void;
  handleFlyoutOut: (key: string) => void;
};

const FlyOutMenu: React.FC<Props> = ({ items, id, itemDimensions, activeItem, handleFlyoutContainerOver, handleFlyoutOut }: Props) => {
  const left = `${itemDimensions.left + itemDimensions.width - 10}px`;
  const top = `${itemDimensions.top}px`;
  const settings = useSelector(themeSettingsSelector);

  return (
    <div
      style={{ left, top }}
      className={classNames(style.air__menuFlyout, {
        [style.air__menuFlyoutLeft]: settings.menuLayoutType === 'left',
        [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
        [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
        [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
      })}
      key={id}
    >
      <ul className={style.air__menuLeft__list} onMouseEnter={() => handleFlyoutContainerOver(id)} onMouseLeave={() => handleFlyoutOut(id)}>
        {items.map((item) => {
          return (
            <li
              className={classNames(style.air__menuLeft__item, {
                [style.air__menuLeft__item__active]: activeItem === item.id,
              })}
              key={item.id}
            >
              {item.url ? (
                <Link to={item.url} className={style.air__menuLeft__link}>
                  {item.icon && <i className={`${item.icon} ${style.air__menuLeft__icon}`} />}
                  <span>{item.title}</span>
                </Link>
              ) : (
                <>{item.icon && <i className={classNames('fe', item.icon, style.air__menuLeft__icon)} />}</>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FlyOutMenu;
