import React from 'react';
import { Button, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import style from '../Table.module.scss';
import { CustomColumnProps, TableActions } from '../types';
import i18n from '../../../i18n';

function getActionsColumn<T extends Record<string, unknown>>(actions?: TableActions): Pick<ColumnProps<T>, CustomColumnProps> {
  return {
    render: (value: Record<string, unknown>, record: T) => (
      <Space>
        <Button
          size="small"
          icon={<i className="fe fe-edit" />}
          onClick={() => actions?.handleView({ taskId: record.id as number, taskType: record.title as string })}
          className={style.table__button_action}
        >
          {i18n.t('components:View', { nsSeparator: ':' })}
        </Button>
        <Button
          size="small"
          icon={<i className="fe fe-trash" />}
          onClick={() => actions?.handleRemove({ taskId: record.id as number, taskType: record.title as string })}
          className={style.table__button_action}
        >
          {i18n.t('components:Remove', { nsSeparator: ':' })}
        </Button>
      </Space>
    ),
  };
}

export default getActionsColumn;
