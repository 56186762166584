import { combineReducers, AnyAction } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import * as reactRedux from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import coreReducer from './modules/core/slice';
import dashboardReducer from './modules/dashboard/slice';
import userReducer from './modules/user/slice';
import taskPlannerReducer from './modules/taskPlanner/slice';
import tournamentDashboardReducer from './modules/tournamentDashboard/slice';
import tournamentDashboardKPIsReducer from './modules/tournamentDashboardKPIs/slice';
import profileReducer from './modules/profile/slice';
import tasksReducer from './modules/tasks/slice';
import translateReducer from './modules/translate/slice';

// import { reducer as oidc } from '@axa-fr/react-oidc-redux'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['core'],
};

const corePersistConfig = {
  key: 'core',
  storage,
  whitelist: ['theme', 'menu', 'language', 'lastUser'],
};

const reducer = combineReducers({
  // oidc,
  user: userReducer,
  core: persistReducer(corePersistConfig, coreReducer),
  taskPlanner: taskPlannerReducer,
  dashboard: dashboardReducer,
  tournamentDashboard: tournamentDashboardReducer,
  tournamentDashboardKPIs: tournamentDashboardKPIsReducer,
  profile: profileReducer,
  tasks: tasksReducer,
  translate: translateReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, reducer),
  middleware: [thunk],
  devTools: { name: 'GrasPro' },
});
export const persistor = persistStore(store);
export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const {
  useSelector,
  useDispatch,
}: {
  useSelector: <TSelected>(selector: (state: State) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean) => TSelected;
  useDispatch: () => ThunkDispatch<State, Record<string, unknown>, AnyAction>;
} = reactRedux;

export default store;
