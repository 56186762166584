import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './RemoveModal.module.scss';

type Props = {
  title?: string;
  open: boolean;
  confirmLoading?: boolean;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  children: ReactNode;
};

const RemoveModal: React.FC<Props> = ({ title, children, open, confirmLoading, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation('components');
  return (
    <Modal title={title} visible={open} onOk={onConfirm} confirmLoading={confirmLoading} onCancel={onCancel} okType="danger" okText={t('Remove')}>
      <div className={styles.holder}>
        <div>
          <ExclamationCircleOutlined className={styles.icon} />
        </div>
        <div>{children}</div>
      </div>
    </Modal>
  );
};

export default RemoveModal;
