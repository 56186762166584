import axiosRequest from '../../utilities/axiosRequest';
import { MaintenanceTableData, TestTableData, UsageTableData } from './types';

export async function loadTestTable(): Promise<TestTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/TestTable`,
    caller: 'loadTestTable',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadMaintenanceTable(): Promise<MaintenanceTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/MaintenanceTable`,
    caller: 'loadMaintenanceTable',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadUsageTable(): Promise<UsageTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/UsageTable`,
    caller: 'loadUsageTable',
    targetAPI: 'MOCKUP_API',
  });
}

export default {
  loadTestTable,
  loadMaintenanceTable,
  loadUsageTable,
};
