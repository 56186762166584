import { createSelector } from 'reselect';
import { State } from 'store';
import { Club, Tournament, User } from './types';

const user = (state: State) => state.user.user;

export const userNameSelector = createSelector(user, (user) => `${user?.profile.given_name} ${user?.profile.family_name}`);
export const userSelector = (state: State): User | null => state.user.user;
export const clubsSelector = (state: State): Club[] | null => state.user.user?.profile?.clubs || null;
export const tournamentsSelector = (state: State): Tournament[] | null => state.user.user?.profile?.tournaments || null;
