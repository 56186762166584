import axiosRequest from '../../utilities/axiosRequest';
import { ChatMessage, ImportantDatesData, MediaTableData, VenuesTableData } from './types';

export async function loadVenuesTable(): Promise<VenuesTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/VenuesTable`,
    caller: 'loadVenuesTable',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadImportantDates(): Promise<ImportantDatesData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/ImportantDates`,
    caller: 'loadImportantDates',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadMediaTable(): Promise<MediaTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/MediaTable`,
    caller: 'loadMediaTable',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadChatMessages(): Promise<ChatMessage[]> {
  return axiosRequest({
    method: 'GET',
    url: `/ChatMessages`,
    caller: 'loadChatMessages',
    targetAPI: 'MOCKUP_API',
  });
}

export async function addChatMessage(data: { parentId: string; message: string; name: string; club: string }): Promise<ChatMessage> {
  return axiosRequest({
    method: 'POST',
    url: `/ChatMessages`,
    data,
    caller: 'addChatMessage',
    targetAPI: 'MOCKUP_API',
  });
}

export default {
  loadVenuesTable,
  loadImportantDates,
  loadMediaTable,
  loadChatMessages,
  addChatMessage,
};
