import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { UserNotification } from '../../../modules/core/types';
import style from './style.module.scss';

type Props = {
  notifications: UserNotification[];
};

const NotificationTabPane = ({ notifications }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="text-gray-6">
      <ul className="list-unstyled">
        {isEmpty(notifications) ? (
          <div className="text-center mb-3 py-4 bg-light rounded">{t('No Events')}</div>
        ) : (
          notifications.map((notification) => (
            <li key={notification.id}>
              <div className={style.head}>
                <p className={style.title}>
                  {notification.title}:{' '}
                  <strong className={classNames({ 'text-black': notification.type === 'regular', 'text-danger': notification.type !== 'regular' })}>
                    {notification.status}
                  </strong>
                </p>
                <time className={style.time}>{notification.time}</time>
              </div>
              <p>{notification.content}</p>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default NotificationTabPane;
