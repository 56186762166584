import React from 'react';

export type PageLayoutProps = {
  children: React.ReactNode;
  title?: string;
  menuLeftType?: MenuLeftType;
  className?: string;
};

export enum MenuLeftType {
  DEFAULT = 'default',
  TOURNAMENT = 'tournament',
}

export enum MenuLeftOrder {
  'main' = 0,
  'Soil Analysis' = 1,
  'Equipment Management' = 2,
}
