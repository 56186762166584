import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import MultiSelectComponent from 'react-multi-select-component';
import { noop } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import CustomItemRenderer from './ItemRenderer';
import customValueRenderer from './customValueRenderer';
import styles from './MultiSelect.module.scss';

type SelectOptionProp = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  value: string[];
  options: SelectOptionProp[];
  avatarMode?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  onDeselect?: (fieldId: string | number) => void;
  onSelect?: (fieldId: string | number) => void;
  loading?: boolean;
  isNumber?: boolean;
  errors?: FieldError;
};

const MultiSelect: React.FC<Props> = ({
  options,
  name,
  value,
  loading,
  disabled,
  avatarMode,
  onChange = noop,
  onDeselect = noop,
  onSelect = noop,
  isNumber = false,
  errors,
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<SelectOptionProp[]>([]);
  const { t } = useTranslation('components.multiSelect');

  useEffect(() => {
    setSelectedItems(() => {
      const values: SelectOptionProp[] = [];
      const valueProp = () => {
        if (Array.isArray(value)) {
          return value;
        }
        return value ? [value] : [];
      };

      valueProp().forEach((val) => {
        const valueOption = options.find((option) => option.value === (isNumber ? val.toString() : val));
        if (valueOption) {
          values.push(valueOption);
        }
      });
      return values;
    });
  }, [value, setSelectedItems, options, isNumber]);

  const handleChange = useCallback(
    (items: SelectOptionProp[]) => {
      setSelectedItems(items);

      const result = items.map((item) => (isNumber ? Number(item.value) : item.value));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(result);
    },
    [setSelectedItems, onChange, isNumber],
  );

  const handleDeselect = useCallback((item: SelectOptionProp) => onDeselect(isNumber ? Number(item.value) : item.value), [onDeselect, isNumber]);
  const handleSelect = useCallback((item: SelectOptionProp) => onSelect(isNumber ? Number(item.value) : item.value), [onSelect, isNumber]);

  const overrideStrings = useMemo(
    () => ({
      allItemsAreSelected: t('All items are selected'),
      clearSearch: t('Clear Search'),
      noOptions: t('No Options'),
      search: t('Search'),
      selectAll: t('Select All'),
      selectSomeItems: `${t('Select')}...`,
    }),
    [t],
  );

  return (
    <MultiSelectComponent
      className={classNames(
        styles.multiSelect,
        'ant-select ant-select-lg ant-select-multiple',
        errors ? 'ant-form-item-has-error ant-form-item-with-help' : '',
      )}
      options={options}
      labelledBy={name}
      hasSelectAll={false}
      value={selectedItems}
      isLoading={loading}
      onChange={handleChange}
      disabled={disabled}
      ClearSelectedIcon=" " // to hide the clearAll button
      ArrowRenderer={() => <DownOutlined className={styles.icon} />}
      ItemRenderer={CustomItemRenderer({ handleDeselect, handleSelect })}
      valueRenderer={customValueRenderer(handleDeselect, handleChange, avatarMode)}
      overrideStrings={overrideStrings}
    />
  );
};

export default MultiSelect;
