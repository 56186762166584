import { createSelector } from 'reselect';
import { State } from '../../store';
import { selectMowingByClubId } from './slice';
import { getActiveClubId } from '../core/selectors';

const fetchingSelector = (state: State) => state.tasks.fetching;
const sendingSelector = (state: State) => state.tasks.sending;

export const getMowingByClubId = createSelector([getActiveClubId, (state) => state], (clubId: string, state) => selectMowingByClubId(state, clubId));

const getMowingFetchingSelector = createSelector(
  [fetchingSelector, (state: State, targetKey: string) => targetKey],
  (fetching, targetKey) => fetching[targetKey],
);

export const getMowingFetching = (state: State) => (targetKey: string): boolean | undefined => getMowingFetchingSelector(state, targetKey);

const getMowingSendingSelector = createSelector(
  [sendingSelector, (state: State, targetKey: string) => targetKey],
  (fetching, targetKey) => fetching[targetKey],
);

export const getMowingSending = (state: State) => (targetKey: string): boolean | undefined => getMowingSendingSelector(state, targetKey);
