import React from 'react';
import { useTranslation } from 'react-i18next';
import CumulativeUsageChart from './CumulativeUsage';
import CardShadowWithButtons from '../../CardShadow';

type Props = {
  selectedData: string;
  selectedRow: string;
};

const CumulativeUsageChartCard: React.FC<Props> = ({ selectedData, selectedRow }: { selectedData: string; selectedRow: string }) => {
  const { t } = useTranslation('tournamentDashboardKPI');

  return (
    <CardShadowWithButtons title={t('Cumulative usage')} icon="fe fe-sliders" noButtons>
      <CumulativeUsageChart selectedData={selectedData} selectedRow={selectedRow} />
    </CardShadowWithButtons>
  );
};

export default CumulativeUsageChartCard;
