import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { PitchUsageData } from 'modules/dashboard/types';
import Highcharts, { Chart } from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
import { withSize } from 'react-sizeme';
import style from './PitchUsageChart.module.scss';

const withSizeHOC = withSize();

type Props = {
  data?: PitchUsageData[];
  size: {
    width: string;
    height: string;
  };
};

// TODO remove disable
const easeOutBounce = (pos: number) => {
  if (pos < 1 / 2.75) {
    return 7.5625 * pos * pos;
  }
  if (pos < 2 / 2.75) {
    // eslint-disable-next-line no-return-assign
    return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
  }
  if (pos < 2.5 / 2.75) {
    // eslint-disable-next-line no-return-assign
    return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
  }
  // eslint-disable-next-line no-return-assign
  return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Math.easeOutBounce = easeOutBounce;

Highcharts.setOptions({
  colors: ['#439B22', '#F28264', '#3F424F', '#94949F', '#ECEEE3', '#5E5E5F', '#FF9655', '#96C382', '#8E9097', '#C0C0C5', '#F3F5EE', '#A0A0A1', '#FAB5A4'],
});

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Tauri', sans-serif",
    },
    animation: false,
  },
});

Highcharts.setOptions({
  title: { text: '' },
  subtitle: { text: '' },
});

Highcharts.setOptions({
  credits: { enabled: false },
});

Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: {
        duration: 5000,
      },
    },
  },
});

drilldown(Highcharts);
const PitchUsageChart: React.FC<Props> = ({
  size,
  data, // eslint-disable-line
}: Props) => {
  // const { t } = useTranslation('components.pitchUsageChart');
  const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement> }>(null);

  // TODO add translations with real data
  const options = useMemo(
    () => ({
      chart: {
        type: 'column',
        height: 310,
      },
      xAxis: {
        type: 'category',
        categories: ['Week 21', 'Week 22', 'Week 23', 'Week 24', 'Week 25'],
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          borderRadius: 3,
        },
      },
      yAxis: {
        labels: {
          format: '{value} h',
        },
        title: {
          text: '',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
          },
        },
      },

      series: [
        {
          data: [
            { drilldown: 'training-w21', y: 30, name: 'Week 21' },
            { drilldown: 'training-w22', y: 50, name: 'Week 22' },
            { drilldown: 'training-w23', y: 60, name: 'Week 23' },
            { drilldown: 'training-w24', y: 30, name: 'Week 24' },
            { drilldown: 'training-w25', y: 40, name: 'Week 25' },
          ],
          animation: {
            duration: 1000,
            // Uses Math.easeOutBounce
            easing: 'easeOutBounce',
          },
          opacity: 0.9,
          name: 'Training',
          // borderRadiusTopLeft: '10px',
          // borderRadiusTopRight: '10px'
        },
        {
          data: [
            { drilldown: 'matches-w21', y: 18, name: 'Week 21' },
            { drilldown: 'matches-w22', y: 27, name: 'Week 22' },
            { drilldown: 'matches-w23', y: 35, name: 'Week 23' },
            { drilldown: 'matches-w24', y: 90, name: 'Week 24' },
            { drilldown: 'matches-w25', y: 75, name: 'Week 25' },
          ],
          animation: {
            duration: 1000,
            // Uses Math.easeOutBounce
            easing: 'easeOutBounce',
          },
          opacity: 0.9,
          name: 'Matches',
          borderRadiusTopLeft: '0px',
          borderRadiusTopRight: '0px',
        },
        {
          data: [
            { drilldown: 'rehearsals-w21', y: 45, name: 'Week 21' },
            { drilldown: 'rehearsals-w22', y: 75, name: 'Week 22' },
            { drilldown: 'rehearsals-w23', y: 120, name: 'Week 23' },
            { drilldown: 'rehearsals-w24', y: 30, name: 'Week 24' },
            { drilldown: 'rehearsals-w25', y: 70, name: 'Week 25' },
          ],
          animation: {
            duration: 1000,
            // Uses simple function
            easing: easeOutBounce,
          },
          opacity: 0.9,
          name: 'Rehearsals',
          borderRadiusTopLeft: '0px',
          borderRadiusTopRight: '0px',
        },
      ],
      drilldown: {
        // allowPointDrilldown: false,
        series: [
          {
            id: 'training-w21',
            name: 'Training Week 21',
            type: 'column',
            // borderRadiusTopLeft: '10px',
            // borderRadiusTopRight: '10px',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'training-w22',
            name: 'Training Week 22',
            type: 'column',
            // borderRadiusTopLeft: '10px',
            // borderRadiusTopRight: '10px',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'training-w23',
            name: 'Training Week 23',
            type: 'column',
            // borderRadiusTopLeft: '10px',
            // borderRadiusTopRight: '10px',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'training-w24',
            name: 'Training Week 24',
            type: 'column',
            // borderRadiusTopLeft: '10px',
            // borderRadiusTopRight: '10px',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'training-w25',
            name: 'Training Week 25',
            type: 'column',
            // borderRadiusTopLeft: '10px',
            // borderRadiusTopRight: '10px',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'matches-w21',
            name: 'Matches Week 21',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'matches-w22',
            name: 'Matches Week 22',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'matches-w23',
            name: 'Matches Week 23',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'matches-w24',
            name: 'Matches Week 24',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'matches-w25',
            name: 'Matches Week 25',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'rehearsals-w21',
            name: 'Rehearsals Week 21',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'rehearsals-w22',
            name: 'Rehearsals Week 22',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'rehearsals-w23',
            name: 'Rehearsals Week 23',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'rehearsals-w24',
            name: 'Rehearsals Week 24',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
          {
            id: 'rehearsals-w25',
            name: 'Rehearsals Week 25',
            type: 'column',
            animation: {
              duration: 700,
            },
            // categories: ['Pitch 1', 'Pitch 2', 'Pitch 3'],
            data: [
              ['Pitch 1', 10],
              ['Pitch 2', 20],
              ['Pitch 3', 30],
            ],
          },
        ],
      },
    }),
    [],
  );

  useEffect(() => {
    chartRef?.current?.chart.reflow();
  }, [size]);

  return (
    <div className={style.chart}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
};

export default withSizeHOC(PitchUsageChart);
