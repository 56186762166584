import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { TasksBoardCards } from '../../modules/taskPlanner/types';
import DatePickerHeader from './DatePickerHeader';
import TasksBoard from './TasksBoard';
import CardShadowWithButtons from '../CardShadow';

type Props = {
  cards: TasksBoardCards;
};

const CardTasksBoard: React.FC<Props> = ({ cards }: Props) => {
  const today = DateTime.local().toISODate();
  const [day, setDay] = useState(today);
  const header = <DatePickerHeader initialDay={today} day={day} setDay={setDay} />;

  return (
    <CardShadowWithButtons customHeader={header}>
      <TasksBoard cards={cards} day={day} />
    </CardShadowWithButtons>
  );
};

export default CardTasksBoard;
