import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './LoadingScreen.module.scss';

type Props = {
  message?: string;
};
const LoadingScreen: React.FC<Props> = ({ message }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.holder}>
      <div className={styles.fade}>
        <div className={styles.logo} />
        <div className={classnames('card bg-transparent', styles.card)}>
          <div className="card-body pt-3 pb-3">
            <div className="text-center">
              <div className={styles.loading}>{message || t('Loading')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
