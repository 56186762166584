import React from 'react';
import classnames from 'classnames';
import { Col, Row } from 'antd';
import styles from './CardHeader.module.scss';

type Props = {
  title?: string;
  icon?: string;
  extraContent?: JSX.Element;
};
export default function CardHeader({ title, icon, extraContent }: Props): JSX.Element {
  return (
    <Row justify="space-between">
      <Col>
        <h5 className={classnames('mb-0', styles.header__holder)}>
          {icon && <i className={classnames(icon, 'xs-mr-8')} />}
          {title && <strong>{title}</strong>}
        </h5>
      </Col>
      {extraContent && <Col>{extraContent}</Col>}
    </Row>
  );
}
