import React, { useMemo } from 'react';
import { Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { TestTableData } from '../../../../modules/tournamentDashboardKPIs/types';
import getProcessedColumns from '../../../Table/columns/getProcessedColumns';
import style from './TestTable.module.scss';
import { Column } from '../../../Table/types';

function renderFirmnessTag(value: number) {
  let color = 'red';
  if (value >= 65 && value <= 100) {
    color = 'green';
  }
  if ((value >= 60 && value <= 64.9) || (value >= 101.1 && value <= 105)) {
    color = 'orange';
  }
  return <Tag color={color}>{value}</Tag>;
}

function renderMoistureTag(value: number) {
  let color = 'red';
  if (value >= 20 && value <= 25) {
    color = 'green';
  }
  if ((value >= 15 && value <= 19.9) || (value >= 25.1 && value <= 30)) {
    color = 'orange';
  }
  return <Tag color={color}>{value}</Tag>;
}

function renderTractionTag(value: number) {
  let color = 'red';
  if (value >= 20 && value < 27) {
    color = 'orange';
  }
  if (value >= 27) {
    color = 'green';
  }
  return <Tag color={color}>{value}</Tag>;
}

type Props = {
  data: TestTableData[];
  selectedData: string;
  setSelectedData: (data: string) => void;
};

const TestTable: React.FC<Props> = ({ data, selectedData, setSelectedData }: Props) => {
  const { t } = useTranslation('table.testTable');
  const columns = useMemo(
    () =>
      [
        {
          title: t('Pitch'),
          dataIndex: 'pitch',
          dataType: 'string',
          width: 270,
        },
        {
          title: t('City'),
          dataIndex: 'city',
        },
        {
          title: t('Usage'),
          dataIndex: 'usage',
        },
        {
          title: t('Surface'),
          dataIndex: 'surface',
        },
        {
          title: t('Firmness'),
          dataIndex: 'firmness',
          dataType: 'not filtered & sorted',
          children: [
            {
              title: t('Date'),
              dataIndex: 'firmnessDate',
              dataType: 'not filtered & sorted',
              align: 'center',
            },
            {
              title: t('Low'),
              dataIndex: 'firmnessLow',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderFirmnessTag(value),
              align: 'center',
            },
            {
              title: t('Avg'),
              dataIndex: 'firmnessAvg',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderFirmnessTag(value),
              align: 'center',
            },
            {
              title: t('High'),
              dataIndex: 'firmnessHigh',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderFirmnessTag(value),
              align: 'center',
            },
          ],
        },
        {
          title: t('Moisture'),
          dataIndex: 'moisture',
          dataType: 'not filtered & sorted',
          children: [
            {
              title: t('Date'),
              dataIndex: 'moistureDate',
              dataType: 'not filtered & sorted',
              align: 'center',
            },
            {
              title: t('Low'),
              dataIndex: 'moistureLow',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderMoistureTag(value),
              align: 'center',
            },
            {
              title: t('Avg'),
              dataIndex: 'moistureAvg',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderMoistureTag(value),
              align: 'center',
            },
            {
              title: t('High'),
              dataIndex: 'moistureHigh',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderMoistureTag(value),
              align: 'center',
            },
          ],
        },
        {
          title: t('Traction'),
          dataIndex: 'traction',
          dataType: 'not filtered & sorted',
          children: [
            {
              title: t('Date'),
              dataIndex: 'tractionDate',
              dataType: 'not filtered & sorted',
              align: 'center',
            },
            {
              title: t('Low'),
              dataIndex: 'tractionLow',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderTractionTag(value),
              align: 'center',
            },
            {
              title: t('Avg'),
              dataIndex: 'tractionAvg',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderTractionTag(value),
              align: 'center',
            },
            {
              title: t('High'),
              dataIndex: 'tractionHigh',
              dataType: 'not filtered & sorted',
              render: (value: number) => renderTractionTag(value),
              align: 'center',
            },
          ],
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );

  const processedColumns = useMemo(() => {
    return getProcessedColumns(data, columns);
  }, [data, columns]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        className={style.table_test}
        rowClassName={(record) => (record.id === selectedData ? style.row_selected : '')}
        columns={processedColumns}
        dataSource={data}
        bordered
        scroll={{ y: 256 }}
        pagination={false}
        size="middle"
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedData(record.id as string);
            },
          };
        }}
      />
    </div>
  );
};

export default TestTable;
