import React from 'react';
import { useTranslation } from 'react-i18next';
import HeatMap from './HeatMap';
import CardShadowWithButtons from '../../../CardShadow';

const stops = [
  [0.0, '#f5222d'], // RED
  [0.18, '#f5222d'], // RED

  [0.18, '#fa8c16'], // ORANGE
  [0.2, '#fa8c16'], // ORANGE

  [0.2, '#52c41a'], // GREEN
  [0.3, '#52c41a'], // GREEN

  [0.3, '#fa8c16'], // ORANGE
  [0.32, '#fa8c16'], // ORANGE

  [0.32, '#f5222d'], // RED
  [1.0, '#f5222d'], // RED
];

type Props = {
  setPopup: (props: { isOpen: boolean; type: string | null; location: string | null }) => void;
  selectedData: string;
};

const MoistureHeatMapCard: React.FC<Props> = ({ selectedData, setPopup }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');
  return (
    <CardShadowWithButtons title={t('Moisture')} icon="fe fe-sliders" noButtons>
      <HeatMap selectedData={selectedData} setPopup={setPopup} type="Moisture" stops={stops} min={10} max={40} />
    </CardShadowWithButtons>
  );
};

export default MoistureHeatMapCard;
