import React, { useMemo } from 'react';
import { Layout } from 'antd';
import MenuTop from 'components/MenuTop';
import MenuLeftFlyout from 'components/MenuLeft/MenuLeftFlyout';
import MenuLeftClassic from 'components/MenuLeft/MenuLeftClassic/MenuLeftClassic';
import Footer from 'components/Footer';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import routes from '../../routes/routes';
import { RoutesGroups, RouteType } from '../../routes/interface';
import { MenuLeftType, PageLayoutProps } from './types';
import { Category, MenuData } from '../MenuLeft/types';
import { useSelector } from '../../store';
import { themeSettingsSelector } from '../../modules/core/selectors';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const PageLayout = ({ children, className, menuLeftType = MenuLeftType.DEFAULT }: PageLayoutProps): JSX.Element => {
  const { t: tRoutes } = useTranslation('routes');
  const {
    isTopbarFixed,
    isGrayTopbar,
    isTopbarSeparated,
    isGrayBackground,
    isContentMaxWidth,
    isAppMaxWidth,
    isSquaredBorders,
    isCardShadow,
    isBorderless,
    layoutMenu,
  } = useSelector(themeSettingsSelector);

  const menuData = useMemo(() => {
    const prepareRoutes = (routes: RouteType[]): Category[] =>
      routes.map((route) => ({
        id: `${route.name}_${route.href || route.children?.length || 'no_href'}`,
        title: tRoutes(route.name),
        ...(route.href ? { url: route.href } : {}),
        ...(route.icon ? { icon: route.icon } : {}),
        ...(route.children ? { children: prepareRoutes(route.children) } : {}),
      }));
    const menuGroupBy = groupBy(
      routes.filter((route) => route.section === menuLeftType),
      'group',
    );

    return Object.keys(menuGroupBy).reduce((partMenu, group) => {
      const title = RoutesGroups[group as keyof typeof RoutesGroups];
      return [
        ...partMenu,
        {
          title: title ? tRoutes(title) : '',
          items: prepareRoutes(menuGroupBy[group]),
        },
      ];
    }, [] as MenuData[]);
  }, [menuLeftType, tRoutes]);

  return (
    <div
      className={classNames(
        {
          vb__layout__grayBackground: isGrayBackground,
        },
        className,
      )}
    >
      <Layout
        className={classNames('vb__layout', {
          vb__layout__contentMaxWidth: isContentMaxWidth,
          vb__layout__appMaxWidth: isAppMaxWidth,
          vb__layout__squaredBorders: isSquaredBorders,
          vb__layout__cardsShadow: isCardShadow,
          vb__layout__borderless: isBorderless,
        })}
      >
        {layoutMenu === 'classic' && <MenuLeftClassic menuData={menuData} />}
        {layoutMenu === 'flyout' && <MenuLeftFlyout menuData={menuData} />}

        <Layout>
          <Layout.Header
            className={classNames('vb__layout__header', {
              vb__layout__fixedHeader: isTopbarFixed,
              vb__layout__headerGray: isGrayTopbar,
              vb__layout__separatedHeader: isTopbarSeparated,
            })}
          >
            <MenuTop layoutMenu={layoutMenu} menuLeftType={menuLeftType} />
          </Layout.Header>

          <Breadcrumbs menuData={menuData} />
          <Layout.Content className="vb__layout__content">
            <ErrorBoundary>{children}</ErrorBoundary>
          </Layout.Content>

          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default PageLayout;
