import React, { useMemo, useState } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonDropdown from '../ButtonDropdown';
import MowingModal from '../MowingModal';
import IrrigationModal from '../IrrigationModal';
import { TaskActionType } from './types';

const TasksMenu: React.FC = () => {
  const { t } = useTranslation('components.tasksMenu');
  const [modalVisible, setModalVisible] = useState({
    mowing: false,
    irrigation: false,
  });

  const setModal = (modal: string, show: boolean) => setModalVisible((modals) => ({ ...modals, [modal]: show }));
  const maintenance = useMemo(() => {
    return [
      {
        title: t('Mowing'),
        onClick: () => setModal('mowing', true),
      },
      {
        title: t('Irrigation'),
        onClick: () => setModal('irrigation', true),
      },
      t('Pitch repair'),
      t('Aeration'),
      t('Fertilization'),
    ];
  }, [t]);
  const usage = useMemo(() => {
    return [t('Match'), t('Training'), t('Extra time'), t('Penalties'), t('Rehearsal')];
  }, [t]);
  const test = useMemo(() => {
    return [t('Firmness'), t('Moisture'), t('traction')];
  }, [t]);

  return (
    <>
      <Space size={16} className="xs-mt--8 xs-mb--8">
        <ButtonDropdown title={`+ ${t('Maintenance')}`} content={maintenance} />
        <ButtonDropdown title={`+ ${t('Usage')}`} content={usage} />
        <ButtonDropdown title={`+ ${t('Test')}`} content={test} />
      </Space>
      <MowingModal isOpen={modalVisible.mowing} onClose={() => setModal('mowing', false)} type={TaskActionType.CREATE} />
      <IrrigationModal isOpen={modalVisible.irrigation} onClose={() => setModal('irrigation', false)} type={TaskActionType.CREATE} />
    </>
  );
};

export default TasksMenu;
