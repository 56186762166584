import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DashboardModuleState, ImagesData, PitchTestsTableData, PitchUsageData, WorkLogTableData } from './types';
import dashboard from './api';
import { State } from '../../store';

const pitchUsageAdapter = createEntityAdapter({
  selectId: (pitchUsage: PitchUsageData) => pitchUsage.id,
});

const imagesAdapter = createEntityAdapter({
  selectId: (image: ImagesData) => image.src,
});

const workLogTableAdapter = createEntityAdapter({
  selectId: (row: WorkLogTableData) => row.id,
});

const pitchTestsTableAdapter = createEntityAdapter({
  selectId: (row: PitchTestsTableData) => row.id,
});

/* TODO use for common errors handler
export const loadPitchUsage = createAsyncThunk('dashboard/getPitchUsage', async (payload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(removeError({ id: 'getPitchUsage' }));
    return await dashboard.loadPitchUsage();
  } catch (error) {
    dispatch(addError({ id: 'getPitchUsage', error }));
    return rejectWithValue(error);
  }
});
 */

export const loadPitchUsage = createAsyncThunk('dashboard/loadPitchUsage', async () => dashboard.loadPitchUsage());
export const loadImages = createAsyncThunk('dashboard/loadImages', async () => dashboard.loadImages());
export const loadWorkLogTable = createAsyncThunk('dashboard/loadWorkLogTable', async () => dashboard.loadWorkLogTable());
export const loadPitchTestsTable = createAsyncThunk('dashboard/loadPitchTestsTable', async () => dashboard.loadPitchTestsTable());
export const loadSearchImages = createAsyncThunk('dashboard/loadSearchImages', async (payload: { search: string }) => dashboard.loadSearchImages(payload));
export const loadSearchDocuments = createAsyncThunk('dashboard/loadSearchDocuments', async (payload: { search: string }) =>
  dashboard.loadSearchDocuments(payload),
);

const initialState: DashboardModuleState = {
  fetching: {},
  pitchUsage: pitchUsageAdapter.getInitialState(),
  images: imagesAdapter.getInitialState(),
  workLogTable: workLogTableAdapter.getInitialState(),
  pitchTestsTable: pitchTestsTableAdapter.getInitialState(),
  searchImages: [],
  searchDocuments: [],
};

export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPitchUsage.pending, (state) => {
      state.fetching.pitchUsage = true;
    });
    builder.addCase(loadPitchUsage.fulfilled, (state, action) => {
      state.fetching.pitchUsage = false;
      state.pitchUsage = pitchUsageAdapter.setAll(state.pitchUsage, action.payload);
    });
    builder.addCase(loadPitchUsage.rejected, (state) => {
      state.fetching.pitchUsage = false;
    });

    builder.addCase(loadImages.pending, (state) => {
      state.fetching.images = true;
    });
    builder.addCase(loadImages.fulfilled, (state, action) => {
      state.fetching.images = false;
      state.images = imagesAdapter.setAll(state.images, action.payload);
    });
    builder.addCase(loadImages.rejected, (state) => {
      state.fetching.images = false;
    });
    builder.addCase(loadWorkLogTable.pending, (state) => {
      state.fetching.workLogTable = true;
    });
    builder.addCase(loadWorkLogTable.fulfilled, (state, action) => {
      state.fetching.workLogTable = false;
      state.workLogTable = workLogTableAdapter.setAll(state.workLogTable, action.payload);
    });
    builder.addCase(loadWorkLogTable.rejected, (state) => {
      state.fetching.workLogTable = false;
    });

    builder.addCase(loadPitchTestsTable.pending, (state) => {
      state.fetching.pitchTestsTable = true;
    });
    builder.addCase(loadPitchTestsTable.fulfilled, (state, action) => {
      state.fetching.pitchTestsTable = false;
      state.pitchTestsTable = pitchTestsTableAdapter.setAll(state.pitchTestsTable, action.payload);
    });
    builder.addCase(loadPitchTestsTable.rejected, (state) => {
      state.fetching.pitchTestsTable = false;
    });
    builder.addCase(loadSearchImages.pending, (state) => {
      state.fetching.searchImages = true;
    });
    builder.addCase(loadSearchImages.fulfilled, (state, action) => {
      state.fetching.searchImages = false;
      state.searchImages = action.payload;
    });
    builder.addCase(loadSearchImages.rejected, (state) => {
      state.fetching.searchImages = false;
    });
    builder.addCase(loadSearchDocuments.pending, (state) => {
      state.fetching.searchDocuments = true;
    });
    builder.addCase(loadSearchDocuments.fulfilled, (state, action) => {
      state.fetching.searchDocuments = false;
      state.searchDocuments = action.payload;
    });
    builder.addCase(loadSearchDocuments.rejected, (state) => {
      state.fetching.searchDocuments = false;
    });
  },
});

export const { selectAll: pitchUsageAll } = pitchUsageAdapter.getSelectors((state: State) => state.dashboard.pitchUsage);
export const { selectAll: imagesAll } = imagesAdapter.getSelectors((state: State) => state.dashboard.images);
export const { selectAll: workLogTableAll } = workLogTableAdapter.getSelectors((state: State) => state.dashboard.workLogTable);
export const { selectAll: pitchTestsTableAll } = pitchTestsTableAdapter.getSelectors((state: State) => state.dashboard.pitchTestsTable);

export const selectFetchingSearch = createSelector(
  [(state: State) => state.dashboard.fetching.searchImages, (state: State) => state.dashboard.fetching.searchDocuments],
  (searchImages, searchDocuments) => searchImages || searchDocuments,
);
export const { actions } = slice;

export default slice.reducer;
