import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import getProcessedColumns from '../../../Table/columns/getProcessedColumns';
import { UsageTableData } from '../../../../modules/tournamentDashboardKPIs/types';
import style from '../../MaintenanceTournamentKPIs/MaintenanceTournamentKPIs.module.scss';
import { Column } from '../../../Table/types';

type Props = {
  data: UsageTableData[];
  selectedData: string;
  setSelectedData: (data: string) => void;
};

const UsageTable: React.FC<Props> = ({ data, selectedData, setSelectedData }: Props) => {
  const { t } = useTranslation('table.usageTable');
  const columns = useMemo(
    () =>
      [
        {
          title: t('Pitch'),
          dataIndex: 'pitch',
          dataType: 'string',
          width: 270,
        },
        {
          title: t('City'),
          dataIndex: 'city',
        },
        {
          title: t('Usage'),
          dataIndex: 'usage',
        },
        {
          title: t('Surface'),
          dataIndex: 'surface',
        },
        {
          title: t('Participants'),
          dataIndex: 'participants',
          dataType: 'not filtered number',
          align: 'center',
        },
        {
          title: t('Training Hours'),
          dataIndex: 'trainingHours',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Training Sessions'),
          dataIndex: 'trainingSessions',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Penalties'),
          dataIndex: 'penalties',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('VAR testing'),
          dataIndex: 'varTesting',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Match Hours'),
          dataIndex: 'matchHours',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Extra Time'),
          dataIndex: 'extraTime',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Matches'),
          dataIndex: 'matches',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Total Hours'),
          dataIndex: 'totalHours',
          dataType: 'not filtered',
          align: 'center',
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );

  const processedColumns = useMemo(() => {
    return getProcessedColumns(data, columns);
  }, [data, columns]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        rowClassName={(record) => (record.id === selectedData ? style.row_selected : '')}
        className={style.table_usage}
        columns={processedColumns}
        dataSource={data}
        bordered
        scroll={{ y: 256 }}
        pagination={false}
        size="middle"
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedData(record.id as string);
            },
          };
        }}
        summary={(pageData) => {
          let totalParticipants = 0;
          let totalTrainingSessions = 0;
          let totalMatches = 0;

          pageData.forEach((props) => {
            const numbers = props as Record<string, number>;
            totalParticipants += numbers.participants;
            totalTrainingSessions += numbers.trainingSessions;
            totalMatches += numbers.matches;
          });

          return (
            <>
              <Table.Summary.Row className={style.row_sum}>
                <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={3} />
                <Table.Summary.Cell index={2}>{totalParticipants}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>365:34</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{totalTrainingSessions}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>156:37</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>256:43</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>234:29</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>175:03</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>{totalMatches}</Table.Summary.Cell>
                <Table.Summary.Cell index={10}>175:03</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default UsageTable;
