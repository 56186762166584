import React, { useEffect } from 'react';

import TournamentDashboard from './TournamentDashboard';
import { useDispatch } from '../../store';
import { loadChatMessages, loadImportantDates, loadMediaTable, loadVenuesTable } from '../../modules/tournamentDashboard/slice';

export default function TournamentDashboardIndex(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadVenuesTable());
    dispatch(loadImportantDates());
    dispatch(loadMediaTable());
    dispatch(loadChatMessages());
  }, [dispatch]);

  return <TournamentDashboard />;
}
