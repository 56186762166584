import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import { SelectAvatar } from 'components/SelectWithHiddenOptions';
import styles from './MultiSelect.module.scss';

const AvatarRender = ({ label, closable, onClose }: Omit<CustomTagProps, 'value' | 'disabled'>) => (
  <SelectAvatar label={label?.toString() || ''} closable={closable} onClose={onClose} />
);

type Props = {
  avatarMode: boolean | undefined;
  selected: Option[];
  onDeselect: (item: Option) => void;
  handleChange: (items: Option[]) => void;
};

const ValueRender: React.FC<Props> = ({ avatarMode = false, selected, handleChange, onDeselect }: Props) => {
  const { t } = useTranslation('components.multiSelect');
  const handleClick = useCallback(
    (item: Option) => () =>
      //    event?: React.MouseEvent<HTMLElement, MouseEvent>
      {
        handleChange(selected.filter(({ value }) => value !== item.value));
        onDeselect(item);
      },
    [selected, handleChange, onDeselect],
  );

  return (
    <>
      {selected.length > 0 &&
        avatarMode &&
        selected.map(({ label, value }) => <AvatarRender key={value} label={label} onClose={handleClick({ value, label })} closable />)}
      {selected.length > 0 && !avatarMode && <span className={styles.valuesWrapper}>{selected.map(({ label }) => label).join(', ')}</span>}
      {!selected.length && `${t('Select')}...`}
    </>
  );
};

const customValueRenderer = (onDeselect: (item: Option) => void, handleChange: (items: Option[]) => void, avatarMode?: boolean) => (
  selected: Option[],
  //  _options: any,
): JSX.Element => {
  return <ValueRender onDeselect={onDeselect} handleChange={handleChange} avatarMode={avatarMode} selected={selected} />;
};

export default customValueRenderer;
