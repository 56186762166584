import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { ChatMessage, NewChatMessage } from '../../modules/tournamentDashboard/types';
import ChatDrawerMessage from './ChatDrawerMessage';
import ChatDrawerFooter from './ChatDrawerFooter';

type Props = {
  thread: ChatMessage;
  messages: ChatMessage[];
  visible: boolean;
  onClose: () => void;
  addMessage: (message: NewChatMessage) => void;
  userName: string;
};

const ChatDrawer: React.FC<Props> = ({ thread, messages, visible, onClose, addMessage, userName }: Props): JSX.Element => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref) {
      ref.scrollIntoView();
    }
  }, [messages, ref]);

  return (
    <Drawer
      width={350}
      title={thread.message}
      footer={<ChatDrawerFooter onSend={addMessage} threadId={thread.id} userName={userName} />}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {messages.length > 0 ? messages.map((item) => <ChatDrawerMessage key={item.id} message={item} user={userName} />) : 'Nobody has replied yet...'}
      <div ref={(e) => setRef(e)} />
    </Drawer>
  );
};

export default ChatDrawer;
