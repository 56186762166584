import React, { useState } from 'react';
import MaintenanceTableCard from './MaintenanceTableCard';
import WorkLogTableCard from './WorkLogTableCard';
import BreakdownChartCard from './BreakdownChartCard';
import ImageGalleryCard from './ImageGalleryCard';

const MaintenanceTournamentKPIs: React.FC = () => {
  const [selectedData, setSelectedData] = useState('1');
  const [selectedRow, setSelectedRow] = useState('1');
  return (
    <div>
      <MaintenanceTableCard selectedData={selectedData} setSelectedData={setSelectedData} setSelectedRow={setSelectedRow} selectedRow={selectedRow} />
      <div className="row">
        <div className="c-l-xs-12 col-xl-4">
          <WorkLogTableCard selectedData={selectedData} selectedRow={selectedRow} />
        </div>
        <div className="c-l-xs-12 col-xl-4">
          <BreakdownChartCard selectedData={selectedData} selectedRow={selectedRow} />
        </div>
        <div className="c-l-xs-12 col-xl-4">
          <ImageGalleryCard selectedData={selectedData} selectedRow={selectedRow} />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceTournamentKPIs;
