import axiosRequest from '../utilities/axiosRequest';
import { TaskEvent, TaskEventsPayload } from '../modules/taskPlanner/types';

export async function loadEventsTasks(payload: TaskEventsPayload): Promise<TaskEvent[]> {
  return axiosRequest({
    method: 'POST',
    url: `/calendar/search`,
    data: { ...payload, clubIds: [payload.clubId] },
    caller: 'getTasks',
    targetAPI: 'SYSTEM_API',
  });
}

export default {
  loadEventsTasks,
};
