import axiosRequest from '../utilities/axiosRequest';

export async function removeEventsTasks({ clubId, taskId }: { clubId: string; taskId: number; taskType: string }): Promise<unknown> {
  return axiosRequest({
    method: 'DELETE',
    url: `/Mowing/${clubId}/${taskId}`,
    caller: 'removeMovingTask',
    targetAPI: 'SYSTEM_API',
  });
}

export default {
  removeEventsTasks,
};
