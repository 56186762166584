import React, { useEffect } from 'react';
import PageLayout from 'components/PageLayout';
// import { useHistory } from 'react-router';
import classnames from 'classnames';
import WeatherCard from './WeatherCard';
import ChartCard from './ChartCard';
import ImagesGalleryCard from './ImagesGalleryCard';
import PitchTestsTableCard from './PitchTestsTableCard';
import TableCard from './TableCard';
// import userManager from '../../user-manager';
import styles from './Dashboard.module.scss';
import { useDispatch, useSelector } from '../../store';
import { getActiveClubId } from '../../modules/core/selectors';
import { loadImages, loadPitchTestsTable, loadPitchUsage } from '../../modules/dashboard/slice';
import { loadEventsTasks } from '../../modules/taskPlanner/slice';
import settings from '../../settings';

const { tasksStartDate, tasksEndDate } = settings;

export function getQueryVariable(variable: string): string {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  return vars.reduce((value, item) => {
    const pair = item.split('=');
    return decodeURIComponent(pair[0]) === variable ? decodeURIComponent(pair[1]) : value;
  }, '');
}

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const activeClubId = useSelector(getActiveClubId);
  useEffect(() => {
    dispatch(loadPitchUsage());
    dispatch(loadImages());
    dispatch(loadPitchTestsTable());
    // dispatch(loadSearchImages());
    // dispatch(loadSearchDocuments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadEventsTasks({ dateFrom: tasksStartDate, dateTo: tasksEndDate, clubId: activeClubId, searchContext: 'All' }));
  }, [dispatch, activeClubId]);

  /* TODO what is it for?
    const history = useHistory();
  

   useEffect(() => {
      if (getQueryVariable('code')) {
        userManager
          .signinRedirectCallback()
          .then((user) => {
            console.log(user); // eslint-disable-line
            history.push('/');
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }, [history]);
    */

  return (
    <PageLayout>
      <div className={classnames('row', styles.first__row)}>
        <div className="col-lg-12 col-xl-8">
          <TableCard />
        </div>
        <div className={classnames('col-lg-12 col-xl-4', styles.right__column)}>
          <div className="row">
            <div className="col-lg-6 col-xl-12">
              <WeatherCard />
            </div>
            <div className="col-lg-6 col-xl-12">
              <ChartCard />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-8">
          <PitchTestsTableCard />
        </div>
        <div className="col-lg-12 col-xl-4">
          <ImagesGalleryCard />
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
