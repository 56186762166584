import React from 'react';
import NotificationItem from './NotificationItem';
import { useSelector } from '../../store';
import { selectToastNotificationsIds } from '../../modules/core/slice';

type Props = { className?: string };

const Notifications: React.FC<Props> = ({ className = '' }: Props) => {
  const notifications = useSelector(selectToastNotificationsIds);
  return (
    <div className={className}>
      {notifications.map((item) => (
        <NotificationItem key={item} id={item as string} />
      ))}
    </div>
  );
};

export default Notifications;
