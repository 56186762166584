import React from 'react';
import logo from '../../images/qatar-logo.png';
import CardShadowWithButtons from '../CardShadow';
import style from './TournamentDashboard.module.scss';

const TournamentLogoCard: React.FC = () => {
  return (
    <CardShadowWithButtons noHeader>
      <img src={logo} alt="Qatar 2020" className={style.logo} />
    </CardShadowWithButtons>
  );
};

export default TournamentLogoCard;
