import { isObject } from 'lodash';

type NameSpaceItems = {
  [key: string]: boolean | NameSpaceItems;
};

const nameSpaces = {
  common: {
    errors: true,
    validationErrors: true,
  },
  components: {
    footer: true,
    menuTop: true,
    pitchUsageChart: true,
    tasksMenu: true,
    multiSelect: true,
  },
  dashboard: true,
  routes: true,
  calendar: true,
  reports: true,
  media: true,
  settings: true,
  underSoilSensors: true,
  reportsOther: true,
  beacons: true,
  fleetManagement: true,
  assetTracking: true,
  taskPlanner: true,
  tournamentDashboardKPI: true,
  tournamentDashboard: true,
  playersPerception: true,
  tournamentReports: true,
  translations: true,
  blank: true,
  table: {
    upcomingTable: true,
    workLogTable: true,
    testTable: true,
    maintenanceTable: true,
    usageTable: true,
    venuesTable: true,
    mediaTable: true,
  },
  task: {
    status: true,
  },
} as NameSpaceItems;

export default (() => {
  const prepareNames = (branch: NameSpaceItems, parent?: string): string[] =>
    Object.keys(branch).reduce((partNames, key) => {
      const name = parent ? `${parent}.${key}` : key;
      const withName = [...partNames, name];

      return isObject(branch[key]) ? [...withName, ...prepareNames(branch[key] as NameSpaceItems, key)] : withName;
    }, [] as string[]);

  return prepareNames(nameSpaces);
})();
