import React, { ErrorInfo, ReactNode } from 'react';

type Props = { children?: ReactNode };
type State = { hasError: boolean; error: string | null; errorInfo: ErrorInfo | null };

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error: error.toString(), errorInfo });
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>{error}</p>
          <p>{errorInfo?.componentStack}</p>
        </>
      );
    }

    return children;
  }
}
export default ErrorBoundary;
