import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Layout } from 'antd';
import classNames from 'classnames';
import logo from '../../../images/graspro_logo_horizontal_rgb.svg';
import MenuItem from './MenuItem';
import { useDispatch, useSelector } from '../../../store';
import { themeSettingsSelector } from '../../../modules/core/selectors';
import { ActiveMenusProps, Category, MenuLeftProps } from '../types';
import style from './MenuLeftFlyout.module.scss';
import { setThemeProps } from '../../../modules/core/slice';
import FlyOutMenu from './FlyOutMenu';

const flyoutTimers = {} as { [key: string]: ReturnType<typeof setTimeout> };
let flyoutItems = {} as { [key: string]: JSX.Element };

const MenuLeftFlyout: React.FC<MenuLeftProps> = ({ menuData = [] }: MenuLeftProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(themeSettingsSelector);
  const [activeMenus, setActiveMenus] = useState<ActiveMenusProps>({ menuId: null, submenuId: null });

  const [renderedFlyoutItems, setRenderedFlyoutItems] = useState<{ [key: string]: JSX.Element }>({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (isEmpty(menuData)) {
      return;
    }
    const checkItems = (items: Category[], active: ActiveMenusProps, parent?: Category): ActiveMenusProps => {
      return items.reduce((activeIn, item) => {
        if (item.url === pathname) {
          return parent ? { submenuId: parent.id, menuId: item.id } : { ...activeIn, menuId: item.id };
        }
        if (item.children) {
          return checkItems(item.children, activeIn, item);
        }
        return activeIn;
      }, active);
    };

    setActiveMenus(
      menuData.reduce(
        (active, group) => {
          return checkItems(group.items, active);
        },
        { menuId: null, submenuId: null } as ActiveMenusProps,
      ),
    );
  }, [pathname, menuData]);

  const menuWidthStyle = useMemo(
    () => ({
      flex: `0 0 ${settings.leftMenuWidth}px`,
      maxWidth: `${settings.leftMenuWidth}px`,
      minWidth: `${settings.leftMenuWidth}px`,
      width: `${settings.leftMenuWidth}px`,
    }),
    [settings],
  );

  const flyoutActive = useMemo(
    () => (settings.flyoutMenuType === 'flyout' || settings.flyoutMenuType === 'compact' || settings.isMenuCollapsed) && !settings.isMobileView,
    [settings],
  );

  const toggleMobileMenu = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        setThemeProps({
          isMobileMenuOpen: !settings.isMobileMenuOpen,
        }),
      );
    },
    [dispatch, settings],
  );

  const toggleMenu = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(
        setThemeProps({
          isMenuCollapsed: !settings.isMenuCollapsed,
        }),
      );
    },
    [dispatch, settings],
  );

  const handleFlyoutContainerOver = (key: string) => {
    clearInterval(flyoutTimers[key]);
  };

  const handleFlyoutOut = (key: string) => {
    if (flyoutActive) {
      flyoutTimers[key] = setTimeout(() => {
        delete flyoutItems[key];
        setRenderedFlyoutItems({
          ...flyoutItems,
        });
      }, 100);
    }
  };

  const handleSubmenuClick = (event: React.MouseEvent<HTMLElement>, key: string) => {
    event.preventDefault();
    if (flyoutActive) {
      return;
    }
    setActiveMenus((activeMenus) => ({ ...activeMenus, submenuId: key }));
  };

  const handleFlyoutOver = (event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLAnchorElement>, key: string, items?: Category[]) => {
    if (flyoutActive && items) {
      clearInterval(flyoutTimers[key]);
      const item = event.currentTarget;
      const itemDimensions = item.getBoundingClientRect();

      const element = (
        <FlyOutMenu
          items={items}
          id={key}
          itemDimensions={itemDimensions}
          handleFlyoutContainerOver={handleFlyoutContainerOver}
          handleFlyoutOut={handleFlyoutOut}
        />
      );
      setRenderedFlyoutItems({
        ...renderedFlyoutItems,
        [key]: element,
      });
      flyoutItems = {
        ...renderedFlyoutItems,
        [key]: element,
      };
    }
  };

  return (
    <Layout.Sider width="auto">
      <TransitionGroup>
        {Object.keys(renderedFlyoutItems).map((item) => {
          return (
            <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
              {renderedFlyoutItems[item]}
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <div
        className={classNames(style.air__menuLeft, {
          [style.air__menuLeft__mobileToggled]: settings.isMobileMenuOpen,
          [style.air__menuLeft__toggled]: settings.isMenuCollapsed,
          [style.air__menuLeft__unfixed]: settings.isMenuUnfixed,
          [style.air__menuLeft__shadow]: settings.isMenuShadow,
          [style.air__menuLeft__border]: settings.isMenuBorder,
          [style.air__menuLeft__flyout]: settings.flyoutMenuType === 'flyout',
          [style.air__menuLeft__compact]: settings.flyoutMenuType === 'compact',
          [style.air__menuLeft__white]: settings.menuColor === 'white',
          [style.air__menuLeft__gray]: settings.menuColor === 'gray',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark' && settings.flyoutMenuType !== 'default',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white' && settings.flyoutMenuType !== 'default',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray' && settings.flyoutMenuType !== 'default',
        })}
        style={menuWidthStyle}
      >
        <div className={style.air__menuLeft__outer} style={menuWidthStyle}>
          <a href="#" className={style.air__menuLeft__mobileToggleButton} onClick={toggleMobileMenu}>
            <span />
          </a>
          <a href="#" className={style.air__menuLeft__toggleButton} onClick={toggleMenu}>
            <span />
            <span />
          </a>
          <div className={style.air__menuLeft__logo}>
            <div className={style.air__menuLeft__logo__letter}>
              <Link to="/">
                <img src={logo} alt="GrasPro" />
              </Link>
            </div>
          </div>
          <PerfectScrollbar>
            <div id="menu-left-container" className={style.air__menuLeft__container}>
              <ul className={style.air__menuLeft__list}>
                {menuData.map((item) => {
                  return (
                    <>
                      {item.title && (
                        <li className={style.air__menuLeft__category} key={item.title}>
                          <span>{item.title}</span>
                        </li>
                      )}
                      {item.items.map((item) =>
                        item.children ? (
                          <li
                            className={classNames(style.air__menuLeft__item, style.air__menuLeft__submenu, {
                              [style.air__menuLeft__submenu__active]: activeMenus.submenuId === item.id,
                            })}
                            key={item.id}
                          >
                            <a
                              href="#"
                              className={style.air__menuLeft__link}
                              onClick={(event) => handleSubmenuClick(event, item.id)}
                              onMouseEnter={(event) => handleFlyoutOver(event, item.id, item.children)}
                              onFocus={(event) => handleFlyoutOver(event, item.id, item.children)}
                              onMouseLeave={() => handleFlyoutOut(item.id)}
                              onBlur={() => handleFlyoutOut(item.id)}
                            >
                              <i className={classNames('fe', item.icon, style.air__menuLeft__icon)} />
                              <span>{item.title}</span>
                              {item.count && <span className="badge text-white bg-blue-light float-right mt-1 px-2">{item.count}</span>}
                            </a>
                            <ul className={style.air__menuLeft__list}>
                              {item.children.map((sub) => (
                                <MenuItem item={sub} activeItem={activeMenus.menuId} />
                              ))}
                            </ul>
                          </li>
                        ) : (
                          <MenuItem item={item} activeItem={activeMenus.menuId} />
                        ),
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
      <a href="#" className={style.air__menuLeft__backdrop} onClick={toggleMobileMenu} />
    </Layout.Sider>
  );
};

export default MenuLeftFlyout;
