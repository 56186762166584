import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserNotification } from '../../../modules/core/types';
import style from './style.module.scss';
import NotificationTabPane from './NotificationTabPane';

const { TabPane } = Tabs;

type Props = {
  userNotifications: UserNotification[];
  systemNotifications: UserNotification[];
};

const NotificationsTabs = ({ userNotifications, systemNotifications }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs className={`${style.tabs} vb-tabs-bordered`} defaultActiveKey="1">
        <TabPane tab={t('System')} key="1">
          <NotificationTabPane notifications={systemNotifications} />
        </TabPane>
        <TabPane tab={t('User')} key="2">
          <NotificationTabPane notifications={userNotifications} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default NotificationsTabs;
