import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import getProcessedColumns from '../../../Table/columns/getProcessedColumns';
import style from '../MaintenanceTournamentKPIs.module.scss';
import { TaskEvent } from '../../../../modules/taskPlanner/types';
import { Column } from '../../../Table/types';

type Props = {
  data: TaskEvent[];
};

const WorkLogTable: React.FC<Props> = ({ data }: Props) => {
  const { t } = useTranslation('table.workLogTable');
  const columns = useMemo(
    () =>
      [
        {
          title: t('Date'),
          dataIndex: 'date',
          dataType: 'date',
        },
        {
          title: t('Work item'),
          dataIndex: 'workItem',
        },
        {
          title: t('Description'),
          dataIndex: 'description',
          dataType: 'string',
        },
        {
          title: t('Area'),
          dataIndex: 'area',
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );

  const processedColumns = useMemo(() => {
    return getProcessedColumns(data, columns);
  }, [data, columns]);
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table className={style.table_widget} columns={processedColumns} dataSource={data} pagination={false} scroll={{ y: 330 }} size="middle" rowKey="id" />
    </div>
  );
};

export default WorkLogTable;
