import React from 'react';

import Calendar from './Calendar';
import CardShadowWithButtons from '../CardShadow/CardShadowWithButtons';

const CardCalendar: React.FC = () => {
  return (
    <CardShadowWithButtons noHeader>
      <Calendar />
    </CardShadowWithButtons>
  );
};

export default CardCalendar;
