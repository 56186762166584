import React, { useMemo } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { noop } from 'lodash';
import styles from './ButtonDropdown.module.scss';

type Props = {
  title: string;
  content: (string | { title: string; onClick: () => void })[];
  className?: string;
};

const ButtonDropdown: React.FC<Props> = ({ title, content, className = styles.button }: Props): JSX.Element => {
  const menuContent = useMemo(() => {
    return content.map((item) => (typeof item === 'string' ? { title: item, onClick: noop } : item));
  }, [content]);

  const menu = (
    <Menu selectable={false} className={styles.menu}>
      {menuContent.map((item) => (
        <Menu.Item key={item.title} onClick={() => item.onClick()}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button className={className}>{title}</Button>
    </Dropdown>
  );
};

export default ButtonDropdown;
