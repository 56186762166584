import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Calendar: React.FC = () => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      events={[
        { title: 'Test event 1', date: '2020-06-20' },
        { title: 'Test event 2', date: '2020-06-25' },
        { title: 'Test event 2', date: '2020-06-01' },
        { title: 'Test event 2', date: '2020-06-10' },
      ]}
      headerToolbar={{
        start: 'prev,next today',
        center: 'title',
        end: 'dayGridMonth,dayGridWeek,dayGridDay',
      }}
    />
  );
};

export default Calendar;
