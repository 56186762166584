import React, { useCallback } from 'react';
import { Alert } from 'antd';
import cx from 'classnames';
import style from './Notifications.module.scss';
import { useDispatch, useSelector } from '../../store';
import { removeNotification } from '../../modules/core/slice';
import { getToastNotification } from '../../modules/core/selectors';

type Props = {
  id: string;
};

const NotificationItem: React.FC<Props> = ({ id }: Props) => {
  const dispatch = useDispatch();
  const notification = useSelector(getToastNotification)(id);
  const handleRemoveNotification = useCallback(() => {
    dispatch(removeNotification(id));
  }, [dispatch, id]);

  return notification ? (
    <div className={style.alert}>
      <Alert message={notification.text} type={notification.type} showIcon />
      <button type="button" data-id={id} className={style.alert__icon_close} onClick={handleRemoveNotification}>
        <span className={cx('fe', 'fe-x')} />
      </button>
    </div>
  ) : null;
};

export default NotificationItem;
