import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EventsTasksTable from '../EventsTasksTable/EventsTasksTable';
import TabCard from '../TabCard';
import TasksMenu from '../TasksMenu/TasksMenu';

const TableCard: React.FC = () => {
  const { t } = useTranslation('dashboard');

  const content = useMemo(() => {
    return [
      {
        title: t('To do'),
        content: <EventsTasksTable />,
      },
      {
        title: t('Completed'),
        content: <EventsTasksTable taskStatus={[3]} />,
      },
    ];
  }, [t]);

  return <TabCard icon="fe fe-book-open" title={t('Tasks')} content={content} headerContent={<TasksMenu />} />;
};

export default TableCard;
