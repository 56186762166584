import React, { useMemo } from 'react';
import { shuffle } from 'lodash';
import MaintenanceTable from './MaintenanceTable';
import { useSelector } from '../../../../store';
import { selectMaintenanceTableAll } from '../../../../modules/tournamentDashboardKPIs/slice';

type Props = {
  selectedData: string;
  selectedRow: string;
  setSelectedRow: (data: string) => void;
};

export default function MaintenanceTableIndex({ selectedData, setSelectedRow, selectedRow }: Props): JSX.Element {
  const data = useSelector(selectMaintenanceTableAll);
  const shuffledData = useMemo(() => {
    return shuffle(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, data]);
  return <MaintenanceTable data={shuffledData} setSelectedRow={setSelectedRow} selectedRow={selectedRow} />;
}
