import React, { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FlipCountdown from '@rumess/react-flip-countdown';
import { DateTime } from 'luxon';

const FlipCountDown: React.FC = () => {
  const event = '2021-07-01T00:00:00';
  const today = DateTime.local();

  const difference = useMemo(() => {
    return DateTime.fromISO(event).diff(today, ['years', 'months']).toObject();
  }, [event, today]);

  if (difference.years && difference.years >= 1) {
    return <FlipCountdown hideMinute hideSecond endAt={event} />;
  }

  if (difference.months && difference.months >= 1) {
    return <FlipCountdown hideYear hideSecond endAt={event} />;
  }

  return <FlipCountdown hideYear hideMonth endAt={event} />;
};

export default FlipCountDown;
