import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import { ImagesData } from 'modules/dashboard/types';
import 'react-image-lightbox/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import style from './ImagesGallery.module.scss';

type Props = {
  data: ImagesData[];
};

const ImagesGallery: React.FC<Props> = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  return (
    <>
      <div className={style.gallery}>
        <Carousel infiniteLoop showStatus={false} showIndicators={false} showThumbs={false}>
          {data.map((image, index) => (
            <div key={image.src} className={style.gallery__image}>
              <img src={process.env.PUBLIC_URL + image.src} alt={image.alt} />
              <button
                type="button"
                aria-label="image"
                className={style.gallery__button}
                onClick={() => {
                  setCurrentImage(index);
                  setIsOpen(true);
                }}
              />
              {image.tags && <div className={style.gallery__description}>{`#${image.tags.join(' #')}`}</div>}
            </div>
          ))}
        </Carousel>
      </div>
      {isOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={data[currentImage].src}
          nextSrc={data[(currentImage + 1) % data.length].src}
          prevSrc={data[(currentImage + data.length - 1) % data.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setCurrentImage((currentImage + data.length - 1) % data.length)}
          onMoveNextRequest={() => setCurrentImage((currentImage + 1) % data.length)}
          imageCaption={data[currentImage].tags ? `#${(data[currentImage].tags as string[]).join(' #')}` : null}
        />
      )}
    </>
  );
};

export default ImagesGallery;
