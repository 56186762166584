import React, { useCallback } from 'react';
import { useSetUserLanguage } from 'modules/core/hooks';
import PageLayout from 'components/PageLayout';
import Form from 'components/Form';
import { setThemeProps } from '../../modules/core/slice';
import { useDispatch } from '../../store';
import CardShadowWithButtons from '../CardShadow/CardShadowWithButtons';

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const setUserLanguage = useSetUserLanguage();

  const handleUserColorScheme = useCallback(
    (theme) => {
      dispatch(setThemeProps({ theme }));
    },
    [dispatch],
  );

  return (
    <PageLayout>
      <CardShadowWithButtons noButtons>
        <div>
          Change language
          <button type="button" onClick={() => setUserLanguage('en')}>
            EN
          </button>
          <button type="button" onClick={() => setUserLanguage('is')}>
            IS
          </button>
          <button type="button" onClick={() => setUserLanguage('ru')}>
            RU
          </button>
        </div>
        <div>
          Change theme
          <button type="button" onClick={() => handleUserColorScheme('default')}>
            Default
          </button>
          <button type="button" onClick={() => handleUserColorScheme('dark')}>
            Dark
          </button>
          {/*
            <button type="button" onClick={() => handleUserColorScheme('light')}>
            Light
          </button>
            */}
        </div>
        <Form />
      </CardShadowWithButtons>
    </PageLayout>
  );
};

export default Settings;
