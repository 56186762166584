import 'moment/locale/is';
import 'moment/locale/ru';
import is from 'antd/lib/locale/is_IS';
import ru from 'antd/lib/locale/ru_RU';
import en from 'antd/lib/locale/en_US';
import moment from 'moment';

export type Languages = {
  [key: string]: { title: string; lng: string; alias: string; default?: boolean };
};

export default {
  API: {
    SYSTEM_API: process.env.REACT_APP_SYSTEM_API || '',
    MOCKUP_API: process.env.REACT_APP_MOCKUP_API || '',
  },
  supportedLanguages: {
    en: {
      title: 'English',
      lng: 'en-US',
      alias: 'en',
      default: true,
      locale: en,
    },
    is: {
      title: 'Íslensku',
      alias: 'is',
      lng: 'is-IS',
      locale: is,
    },
    ru: {
      title: 'Русский',
      alias: 'ru',
      lng: 'ru-RU',
      locale: ru,
    },
  },
  tasksStartDate: moment('2021').startOf('year').toISOString(),
  tasksEndDate: moment('2021').endOf('year').toISOString(),
};
