import React, { useEffect, useMemo } from 'react';
import { Badge, Dropdown } from 'antd';

import { useDispatch, useSelector } from '../../store';
import { systemNotificationsSelector, userNotificationsSelector } from '../../modules/core/selectors';
import { loadUserNotifications } from '../../modules/core/slice';
import styles from './UserNotifications.module.scss';
import NotificationsTabs from './NotificationsTabs/NotificationsTabs';

export default function UserNotifications(): JSX.Element {
  const dispatch = useDispatch();

  const userNotifications = useSelector(userNotificationsSelector);
  const systemNotifications = useSelector(systemNotificationsSelector);

  useEffect(() => {
    dispatch(loadUserNotifications());
  }, [dispatch]);
  const notificationsQty = useMemo(() => {
    return userNotifications.length + systemNotifications.length;
  }, [systemNotifications.length, userNotifications.length]);

  return (
    <Dropdown
      overlay={
        <React.Fragment>
          <div className="card vb__utils__shadow width-350 border-0">
            <div className="card-body p-0">
              <NotificationsTabs userNotifications={userNotifications} systemNotifications={systemNotifications} />
            </div>
          </div>
        </React.Fragment>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <div className={styles.dropdown}>
        <Badge count={notificationsQty} className={styles.badge}>
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </div>
    </Dropdown>
  );
}
