import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { ClubEvent } from '../../UserMenu/DropdownMenu';
import styles from './ClubsOrTournaments.module.scss';

type Props = {
  title: string;
  data?: ClubEvent[];
  onClick: (event: MouseEvent<HTMLElement>) => void;
};
const ClubsOrTournaments = ({ title, data, onClick }: Props): JSX.Element => {
  const menu = (
    <Menu selectable={false} className={styles.dropdownMenu}>
      {data?.map((item) => (
        <Menu.ItemGroup key={item.country} title={item.country}>
          {item.cities.map((city) => (
            <Menu.SubMenu key={city.city} title={city.city} popupClassName={styles.popupMenu}>
              {city.items.map((item) => (
                <Menu.Item key={item.label}>
                  <Link to="" onClick={onClick} data-id={item.value}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
      <div className={styles.dropdown}>
        <i className={`${styles.icon} fa fa-futbol-o`} />
        <span className="d-none d-xl-inline">{title}</span>
      </div>
    </Dropdown>
  );
};

export default ClubsOrTournaments;
