import axiosRequest from '../../utilities/axiosRequest';
import { Irrigation, IrrigationCreateTask, Mowing, MowingCreateTask } from './types';

export async function loadMowingTask({ clubId, mowingId }: { clubId: string; mowingId: number }): Promise<Mowing> {
  return axiosRequest({
    method: 'GET',
    url: `/Mowing/${clubId}/${mowingId}`,
    caller: 'loadMowingTask',
    targetAPI: 'SYSTEM_API',
  });
}

export async function createMowingTask({ clubId, data }: { clubId: string; data: MowingCreateTask }): Promise<Mowing> {
  return axiosRequest({
    method: 'POST',
    url: `/Mowing/${clubId}`,
    data,
    caller: 'createMowingTask',
    targetAPI: 'SYSTEM_API',
  });
}

export async function loadIrrigationTask({ clubId, irrigationId }: { clubId: string; irrigationId: number }): Promise<Irrigation> {
  return axiosRequest({
    method: 'GET',
    url: `/Irrigation/${clubId}/${irrigationId}`,
    caller: 'loadIrrigationTask',
    targetAPI: 'SYSTEM_API',
  });
}
export async function createIrrigationTask({ clubId, data }: { clubId: string; data: IrrigationCreateTask }): Promise<Mowing> {
  return axiosRequest({
    method: 'POST',
    url: `/Irrigation/${clubId}`,
    data,
    caller: 'createIrrigationTask',
    targetAPI: 'SYSTEM_API',
  });
}

export default {
  loadMowingTask,
  createMowingTask,
  loadIrrigationTask,
  createIrrigationTask,
};
