import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ChatMessage, ImportantDatesData, MediaTableData, TournamentDashboardModuleState, VenuesTableData } from './types';
import { State } from '../../store';
import api from './api';

const venuesTableAdapter = createEntityAdapter({
  selectId: (row: VenuesTableData) => row.id,
});

const importantDatesAdapter = createEntityAdapter({
  selectId: (date: ImportantDatesData) => date.id,
});

const mediaTableAdapter = createEntityAdapter({
  selectId: (row: MediaTableData) => row.id,
});

const chatMessagesAdapter = createEntityAdapter({
  selectId: (message: ChatMessage) => message.id,
});

export const loadVenuesTable = createAsyncThunk('tournamentDashboard/loadVenuesTable', async () => api.loadVenuesTable());
export const loadImportantDates = createAsyncThunk('tournamentDashboard/loadImportantDates', async () => api.loadImportantDates());
export const loadMediaTable = createAsyncThunk('tournamentDashboard/loadMediaTable', async () => api.loadMediaTable());
export const loadChatMessages = createAsyncThunk('tournamentDashboard/loadChatMessages', async () => api.loadChatMessages());
export const addChatMessage = createAsyncThunk(
  'tournamentDashboard/addChatMessage',
  async (payload: { parentId: string; message: string; name: string; club: string }) => api.addChatMessage(payload),
);

const initialState: TournamentDashboardModuleState = {
  fetching: {},
  sending: {},
  venuesTable: venuesTableAdapter.getInitialState(),
  importantDates: importantDatesAdapter.getInitialState(),
  mediaTable: mediaTableAdapter.getInitialState(),
  chatMessages: chatMessagesAdapter.getInitialState(),
};

export const slice = createSlice({
  name: 'tournamentDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadVenuesTable.pending, (state) => {
      state.fetching.venuesTable = true;
    });
    builder.addCase(loadVenuesTable.fulfilled, (state, action) => {
      state.fetching.venuesTable = false;
      state.venuesTable = venuesTableAdapter.setAll(state.venuesTable, action.payload);
    });
    builder.addCase(loadVenuesTable.rejected, (state) => {
      state.fetching.venuesTable = false;
    });

    builder.addCase(loadImportantDates.pending, (state) => {
      state.fetching.importantDates = true;
    });
    builder.addCase(loadImportantDates.fulfilled, (state, action) => {
      state.fetching.importantDates = false;
      state.importantDates = importantDatesAdapter.setAll(state.importantDates, action.payload);
    });
    builder.addCase(loadImportantDates.rejected, (state) => {
      state.fetching.importantDates = false;
    });

    builder.addCase(loadMediaTable.pending, (state) => {
      state.fetching.mediaTable = true;
    });
    builder.addCase(loadMediaTable.fulfilled, (state, action) => {
      state.fetching.mediaTable = false;
      state.mediaTable = mediaTableAdapter.setAll(state.mediaTable, action.payload);
    });
    builder.addCase(loadMediaTable.rejected, (state) => {
      state.fetching.mediaTable = false;
    });

    builder.addCase(loadChatMessages.pending, (state) => {
      state.fetching.chatMessages = true;
    });
    builder.addCase(loadChatMessages.fulfilled, (state, action) => {
      state.fetching.chatMessages = false;
      state.chatMessages = chatMessagesAdapter.setAll(state.chatMessages, action.payload);
    });
    builder.addCase(loadChatMessages.rejected, (state) => {
      state.fetching.chatMessages = false;
    });

    builder.addCase(addChatMessage.pending, (state) => {
      state.sending.chatMessage = true;
    });
    builder.addCase(addChatMessage.fulfilled, (state, action) => {
      state.sending.chatMessage = false;
      state.chatMessages = chatMessagesAdapter.upsertOne(state.chatMessages, action.payload);
    });
    builder.addCase(addChatMessage.rejected, (state) => {
      state.sending.chatMessage = false;
    });
  },
});

export const { selectAll: selectVenuesTableAll } = venuesTableAdapter.getSelectors((state: State) => state.tournamentDashboard.venuesTable);
export const { selectAll: selectImportantDatesAll } = importantDatesAdapter.getSelectors((state: State) => state.tournamentDashboard.importantDates);
export const { selectAll: selectMediaTableAll } = mediaTableAdapter.getSelectors((state: State) => state.tournamentDashboard.mediaTable);
export const { selectAll: selectChatMessagesAll } = chatMessagesAdapter.getSelectors((state: State) => state.tournamentDashboard.chatMessages);

export const { actions } = slice;

export default slice.reducer;
