import React from 'react';
import CardShadowWithButtons from 'components/CardShadow/CardShadowWithButtons';
import TeamChart from './TeamChart';

const CardTeamChart: React.FC = () => {
  return (
    <CardShadowWithButtons>
      <TeamChart />
    </CardShadowWithButtons>
  );
};

export default CardTeamChart;
