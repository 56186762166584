import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { CustomColumnProps } from '../types';
import FilterDropdown from '../FilterDropdown';
import style from '../Table.module.scss';

function getSearchColumn<T extends Record<string, unknown>>(dataType: string, dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  return {
    filterDropdown: (...props) => FilterDropdown(dataIndex, ...props),
    filterIcon: () => <SearchOutlined className={style.searchIcon} />,
    onFilter: (value: string | number | boolean, record: T) =>
      ((record[dataIndex] as unknown) as string).toLowerCase().includes((value as string).toLowerCase()),
    sorter: (a, b) => ((a[dataIndex] as unknown) as string).localeCompare((b[dataIndex] as unknown) as string),
    sortDirections: ['descend', 'ascend'],
    align: dataType === 'number' ? 'center' : 'left',
  };
}

export default getSearchColumn;
