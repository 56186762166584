import React, { useCallback, useState } from 'react';
import { NewChatMessage } from '../../modules/tournamentDashboard/types';

type Props = {
  onSend: (message: NewChatMessage) => void;
  userName: string;
  threadId: string;
};
const ChatDrawerFooter: React.FC<Props> = ({ onSend, userName, threadId }: Props): JSX.Element => {
  const [value, setValue] = useState('');

  const onChange = useCallback((e) => setValue(e.target.value), [setValue]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSend({ message: value, name: userName, club: 'Manchester United', parentId: threadId });
      setValue('');
    },
    [onSend, value, threadId, userName],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="input-group mb-3 d-flex flex-wrap">
        <input type="text" className="form-control" placeholder="Send message..." value={value} onChange={onChange} />
        <div className="input-group-append">
          <button className="btn btn-primary" type="submit">
            <i className="fe fe-send align-middle" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChatDrawerFooter;
