import React from 'react';
import CardShadowWithButtons from '../CardShadow';
import style from './TournamentDashboard.module.scss';
import FlipCountDown from './FlipCountDown';

const FlipCountDownCard: React.FC = () => {
  return (
    <CardShadowWithButtons noHeader>
      <div className={style.flipDown}>
        <FlipCountDown />
      </div>
    </CardShadowWithButtons>
  );
};

export default FlipCountDownCard;
