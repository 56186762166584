import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { TasksBoardCard as TasksBoardCardProps } from '../../modules/taskPlanner/types';
import style from './TasksBoard.module.scss';

type Props = {
  title: string;
  content: TasksBoardCardProps[];
};

const TasksBoardCard: React.FC<Props> = ({
  title,
  content, // eslint-disable-line
}: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setIsCollapsed]);

  return (
    <div className={style.card}>
      <div className={style.card__header}>
        <h5 className="text-dark mb-0">{title}</h5>
        <button type="button" onClick={onClick} className={cx('btn btn-light', style.button_toggle)}>
          <i className={cx('fe', style.button_toggle__icon, isCollapsed ? 'fe-chevron-up' : 'fe-chevron-down')} />
        </button>
      </div>

      {!isCollapsed && (
        <div className={style.card__body}>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat
          </div>
        </div>
      )}
    </div>
  );
};

export default TasksBoardCard;
