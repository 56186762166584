import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { User, UserModuleState } from './types';

const initialState: UserModuleState = {
  user: null,
  fetching: {},
  error: null,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: (state: Draft<UserModuleState>) => {
      state.fetching.user = true;
    },
    loadUserSuccess: (state: Draft<UserModuleState>, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.fetching.user = false;
    },
    loadUserError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.fetching.user = false;
    },
  },
});

export const {
  actions,
  actions: { loadUser, loadUserSuccess, loadUserError },
} = slice;

export default slice.reducer;
