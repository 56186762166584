import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classnames from 'classnames';
import avatar from '../../images/avatars/1.jpg';
import { ChatMessage } from '../../modules/tournamentDashboard/types';
import { getDurationAfterDate } from '../../utilities/getRelativeDate';
import ChatTimeCount from './ChatTimeCount';
import style from './Chat.module.scss';

type Props = {
  data: { parentMessage: ChatMessage; messages: ChatMessage[] };
  onDrawerOpen: () => void;
  setSelectedThread: (id: number) => void;
  index: number;
};

const ChatThread: React.FC<Props> = ({ data, onDrawerOpen, setSelectedThread, index }: Props) => {
  const { t: tCommon } = useTranslation('common');
  const onClick = useCallback(() => {
    onDrawerOpen();
    setSelectedThread(index);
  }, [onDrawerOpen, index, setSelectedThread]);

  const lastReply = useMemo(() => {
    if (data.messages.length > 0) {
      const lastMessage = data.messages[data.messages.length - 1];
      const lastMessageTime = getDurationAfterDate(lastMessage.creatingDate);
      return (lastMessageTime as string).includes('day') ? (
        <span>
          {lastMessage.name} replied {lastMessageTime}
        </span>
      ) : (
        <span>
          {lastMessage.name} replied <ChatTimeCount time={lastMessage.creatingDate} />
        </span>
      );
    }
    return <span>Nobody has replied yet...</span>;
  }, [data]);

  return (
    <li className={style.item}>
      <div className={style.itemPic}>
        <img src={avatar} alt="avatar" />
      </div>
      <div className={classnames('mr-3', style.nameHolder)}>
        <div>{data.parentMessage.name}</div>
        <div className="text-muted">{data.parentMessage.club}</div>
      </div>
      <div className="mr-3">
        <div>
          {data.parentMessage.message} ({data.messages.length})
        </div>
        <div className="text-muted">{lastReply}</div>
      </div>
      <div className={style.itemAction}>
        <Button size="small" onClick={onClick}>
          {tCommon('View')}
        </Button>
      </div>
    </li>
  );
};

export default ChatThread;
