import React from 'react';
import { Checkbox } from 'antd';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';

type ItemRendererProps = {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: () => void;
};
type ItemRendererCallback = {
  handleDeselect: (o: Option) => void;
  handleSelect: (o: Option) => void;
};

const CustomItemRenderer = ({ handleDeselect, handleSelect }: ItemRendererCallback): React.FC<ItemRendererProps> => ({
  checked,
  option,
  onClick,
  disabled,
}: ItemRendererProps) => {
  const handleClick = () => {
    if (checked) {
      handleDeselect(option);
    } else {
      handleSelect(option);
    }
    onClick();
  };

  return (
    <div>
      <div aria-selected="false" className="ant-select-item ant-select-item-option" title={option.label}>
        <Checkbox type="checkbox" onChange={handleClick} checked={checked} tabIndex={-1} disabled={disabled} className="ant-select-item-option-content">
          {option.label}
        </Checkbox>
      </div>
    </div>
  );
};

export default CustomItemRenderer;
