import React, { useState } from 'react';
import TestTableCard from './TestTableCard';
import style from '../TournamentDashboardKPIs.module.scss';
import FirmnessHeatMapCard from './HeatMap/FirmnessHeatMapCard';
import TractionHeatMapCard from './HeatMap/TractionHeatMapCard';
import MoistureHeatMapCard from './HeatMap/MoistureHeatMapCard';
import PopupChart from './HeatMap/PopupChart';

type Popup = { isOpen: boolean; type: null | string; location: null | string };
const TestTournamentKPIs: React.FC = () => {
  const [selectedData, setSelectedData] = useState('1');
  const [popup, setPopup] = useState<Popup>({ isOpen: false, type: null, location: null });

  return (
    <div className={style.container}>
      <TestTableCard selectedData={selectedData} setSelectedData={setSelectedData} />
      {popup.isOpen && <PopupChart popup={popup} setPopup={setPopup} />}
      <div className="row">
        <div className="c-l-xs-12 col-xl-4">
          <FirmnessHeatMapCard selectedData={selectedData} setPopup={setPopup} />
        </div>
        <div className="c-l-xs-12 col-xl-4">
          <TractionHeatMapCard selectedData={selectedData} setPopup={setPopup} />
        </div>
        <div className="c-l-xs-12 col-xl-4">
          <MoistureHeatMapCard selectedData={selectedData} setPopup={setPopup} />
        </div>
      </div>
    </div>
  );
};

export default TestTournamentKPIs;
