import { EntityState } from '@reduxjs/toolkit';

export type ColorScheme = 'light' | 'dark' | 'default';
export type NotificationType = 'warning' | 'success';
export type Notification = {
  id: string;
  text: string;
  type: NotificationType;
};

export type Error = {
  id: string;
  error: Record<string, unknown>;
};

export type Message = {
  id: string;
  message: string;
  type: 'error' | 'success' | 'warning' | 'info' | 'loading';
};

export enum MessageDuration {
  'error' = 5,
  'success' = 2,
  'warning' = 2,
  'info' = 2,
  'loading' = 2,
}

export type UserNotification = {
  id: string;
  system: boolean;
  title: string;
  status: string;
  type: string;
  time: string;
  content: string;
};

export type CoreModuleState = {
  fetching: { [key: string]: boolean };
  isAuthorized: boolean;
  userNotifications: EntityState<UserNotification>;
  notifications: EntityState<Notification>;
  errors: EntityState<Error>;
  messages: EntityState<Message>;
  language: string;
  lastUser: string;
  activeClub: string;
  activeTournament: string;
  theme: {
    logo: string;
    version: string;
    theme: ColorScheme;
    locale: string;
    isSidebarOpen: boolean;
    isSupportChatOpen: boolean;
    isMobileView: boolean;
    isMobileMenuOpen: boolean;
    isMenuCollapsed: boolean;
    isPreselectedOpen: boolean;
    preselectedVariant: string;
    menuLayoutType: string;
    routerAnimation: string;
    menuColor: string;
    authPagesColor: string;
    isAuthTopbar: boolean;
    primaryColor: string;
    leftMenuWidth: number;
    isMenuUnfixed: boolean;
    isMenuShadow: boolean;
    isMenuBorder: boolean;
    isTopbarFixed: boolean;
    isTopbarSeparated: boolean;
    isGrayTopbar: boolean;
    isContentMaxWidth: boolean;
    isAppMaxWidth: boolean;
    isGrayBackground: boolean;
    isCardShadow: boolean;
    isSquaredBorders: boolean;
    isBorderless: boolean;
    layoutMenu: string;
    layoutTopbar: string;
    layoutBreadcrumbs: string;
    layoutFooter: string;
    flyoutMenuType: string;
    flyoutMenuColor: string;
  };
  menu: {
    selectedKeys: string[];
    openedKeys: string[];
  };
};
