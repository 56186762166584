import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import { noop } from 'lodash';
import { SelectAvatar } from './index';

type SelectOptionProp = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  value: string[];
  options: SelectOptionProp[];
  avatarMode?: boolean;
  multipleMode?: boolean;
  onChange?: (value: string | string[]) => void;
  onDeselect?: (fieldId: string) => void;
  onSelect?: (fieldId: string) => void;
  disabled?: boolean;
  loading?: boolean;
};

type ExtraProps = {
  [prop: string]: any; // eslint-disable-line
};
const avatarRender = ({ label, closable, onClose }: CustomTagProps) => <SelectAvatar label={label?.toString() || ''} closable={closable} onClose={onClose} />;

const SelectWithHiddenOptions: React.FC<Props & ExtraProps> = ({
  name = '',
  value,
  options = [],
  avatarMode = false,
  multipleMode = false,
  onChange = noop,
  onDeselect = noop,
  onSelect = noop,
  disabled = false,
  loading = false,
  ...props
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<SelectOptionProp | SelectOptionProp[]>([]);
  useEffect(() => {
    setSelectedItems(() => {
      const values: SelectOptionProp[] = [];
      const valueProp = (() => {
        if (multipleMode && Array.isArray(value)) {
          return value;
        }
        return value && !Array.isArray(value) ? [value] : [];
      })();
      valueProp.forEach((val) => {
        const valueOption = options.find((option) => option.value === val);
        if (valueOption) {
          values.push(valueOption);
        }
      });
      return values;
    });
  }, [value, setSelectedItems, options, multipleMode]);

  const handleChange = useCallback(
    (items: SelectOptionProp | SelectOptionProp[]) => {
      setSelectedItems(items);
      const result = Array.isArray(items) ? items.map((item) => item.value) : items?.value;
      onChange(result);
    },
    [setSelectedItems, onChange],
  );
  const handleDeselect = useCallback((item: SelectOptionProp) => onDeselect(item?.value), [onDeselect]);
  const handleSelect = useCallback((item: SelectOptionProp) => onSelect(item?.value), [onSelect]);

  const filteredOptions = useMemo(() => {
    if (options.length < 1) {
      return [];
    }
    return options.filter((option) => (multipleMode && Array.isArray(selectedItems) ? !selectedItems.some((item) => item.value === option.value) : true));
  }, [options, selectedItems, multipleMode]);

  const selectExtraOption: {
    tagRender?: (props: CustomTagProps) => React.ReactElement;
    mode?: 'multiple';
  } = {};
  if (avatarMode) {
    selectExtraOption.tagRender = avatarRender;
  }
  if (multipleMode) {
    selectExtraOption.mode = 'multiple';
  }

  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);
  useEffect(() => setIsDisabled(disabled), [disabled]);
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  useEffect(() => {
    setIsLoading(loading);
    setIsDisabled(loading ? true : disabled);
  }, [loading, disabled]);
  return (
    <>
      <Select
        labelInValue
        maxTagCount={avatarMode ? 4 : 2}
        id={name}
        value={selectedItems}
        virtual={false}
        filterOption={(input, option) => (option ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)}
        onChange={handleChange}
        onDeselect={handleDeselect}
        onSelect={handleSelect}
        disabled={isDisabled}
        loading={isLoading}
        {...selectExtraOption}
        {...props}
      >
        {filteredOptions.length &&
          filteredOptions.map((option) => (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default SelectWithHiddenOptions;
