import { createSelector } from 'reselect';
import { State } from 'store';
import { selectToastNotificationById, selectUserNotificationsAll } from './slice';
import { Notification } from './types';
import { clubsSelector, tournamentsSelector } from '../user/selectors';

const colorScheme = (state: State) => state.core.theme.theme;
const theme = (state: State) => state.core.theme;
const menu = (state: State) => state.core.menu;
export const getActiveClubId = (state: State): string => state.core.activeClub;
export const getActiveTournamentId = (state: State): string => state.core.activeTournament;

export const userNotificationsSelector = createSelector(selectUserNotificationsAll, (notifications) => notifications.filter((item) => !item.system));
export const systemNotificationsSelector = createSelector(selectUserNotificationsAll, (notifications) => notifications.filter((item) => item.system));
export const colorSchemeSelector = createSelector(colorScheme, (colorScheme) => colorScheme);
export const themeSettingsSelector = createSelector(theme, (theme) => theme);
export const getToastNotificationSelector = createSelector(
  [(state: State, id: string) => selectToastNotificationById(state, id)],
  (notification) => notification,
);
export const getToastNotification = (state: State) => (id: string): Notification | undefined => getToastNotificationSelector(state, id);

export const getMenuSelectedKeys = createSelector(menu, (menu) => menu.selectedKeys);
export const getMenuOpenedKeys = createSelector(menu, (menu) => menu.openedKeys);
export const getCurrentLanguage = (state: State): string => state.core.language;
export const getActiveClub = createSelector([getActiveClubId, clubsSelector], (activeClub, clubs) =>
  clubs ? clubs.find((club) => club.clubId === activeClub) : null,
);

export const getActiveTournament = createSelector([getActiveTournamentId, tournamentsSelector], (activeTournament, tournaments) =>
  tournaments ? tournaments.find((tournament) => tournament.tournamentId === activeTournament) : null,
);
