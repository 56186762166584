import React from 'react';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import style from './AgileBoard.module.scss';

const BoardDropdown: React.FC = () => {
  const menu = (
    <Menu>
      <Menu.Item>Older than 30 days</Menu.Item>
      <Menu.Item>Older than 7 days</Menu.Item>
      <Menu.Item className={style.dropdown__item_bordered}>All completed tasks</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <button type="button" className={cx('ant-btn', style.dropdown__button)}>
        Archive
      </button>
    </Dropdown>
  );
};

export default BoardDropdown;
