import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UsageTable from './UsageTable';
import TabCard from '../../TabCard';

type Props = {
  setSelectedData: (data: string) => void;
  setSelectedRow: (data: string) => void;
  selectedRow: string;
  selectedData: string;
};
const UsageTableCard: React.FC<Props> = ({ setSelectedData, selectedRow, setSelectedRow, selectedData }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');
  const onChange = useCallback(
    (tabPosition: string) => {
      setSelectedData(tabPosition);
    },
    [setSelectedData],
  );

  const content = useMemo(
    () => [
      {
        title: t('7 Days'),
        content: <UsageTable selectedData={selectedData} selectedRow={selectedRow} setSelectedData={setSelectedRow} />,
      },
      {
        title: t('30 Days'),
        content: <UsageTable selectedData={selectedData} selectedRow={selectedRow} setSelectedData={setSelectedRow} />,
      },
      {
        title: t('60 Days'),
        content: <UsageTable selectedData={selectedData} selectedRow={selectedRow} setSelectedData={setSelectedRow} />,
      },
    ],
    [t, selectedData, selectedRow, setSelectedRow],
  );

  return <TabCard icon="fe fe-sliders" title={t('Usage')} content={content} onChange={onChange} />;
};

export default UsageTableCard;
