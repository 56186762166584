import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import cx from 'classnames';
import style from './AgileBoard.module.scss';
import { AgileCard, Status } from '../../modules/taskPlanner/types';
import avatar from '../../images/avatars/1.jpg';

type Props = {
  card: AgileCard;
  index: number;
  onDrop: (card: AgileCard, index: number, status: Status) => void;
};

type DragObject = {
  index: number;
  card: {
    id: string;
  };
};

const BoardCard: React.FC<Props> = ({ card, index, onDrop }: Props) => {
  const selfRef = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item: DragObject, monitor: DropTargetMonitor) {
      const { card: itemCard } = item;
      if (!selfRef.current || card.id === itemCard.id) {
        return null;
      }

      const hoverBoundingRect = selfRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (hoverClientY <= hoverMiddleY) {
        item.index = index;
        return null;
      }

      if (hoverClientY > hoverMiddleY) {
        item.index = index + 1;
        return null;
      }

      return null;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: {
      card,
      index,
    },
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        onDrop(card, card.priority, card.status);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drop}>
      <div ref={selfRef} className={style.card__holder}>
        <div className={cx(style.card, isDragging && style.card_dragging)} ref={drag}>
          <div className={style.card__content}>
            <div className={cx(style.card__flag, card.type)} />
            <div className="d-flex flex-wrap-reverse align-items-center">
              <h5 className="text-dark font-size-18 mt-2 mr-auto">{card.title}</h5>
            </div>
            <div className="text-gray-5 mb-2">{card.deadline}</div>
            <div className="d-flex align-items-center flex-wrap pb-2">
              <div className={style.card__avatar}>
                <img src={avatar} alt="User" />
              </div>
              <div className="text-gray-5">
                <del>AWS-200</del>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCard;
