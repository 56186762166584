import { RouteType } from './interface';
import { MenuLeftType } from '../components/PageLayout/types';

const routes: RouteType[] = [
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Dashboard',
    href: '/',
    path: ['/'],
    module: 'Dashboard',
    exact: true,
    icon: 'fe-pie-chart',
    menu: true,
    private: true,
    //    namespace: 'dashboard',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Task Planner',
    href: '/task-planner',
    path: ['/task-planner', '/task-planner/:tabId'],
    module: 'TaskPlanner',
    exact: true,
    icon: 'fe-book-open',
    menu: true,
    private: true,
    //    namespace: 'taskPlanner',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Calendar',
    href: '/calendar',
    path: ['/calendar'],
    module: 'Blank',
    exact: true,
    icon: 'fe-calendar',
    menu: true,
    private: true,
    //    namespace: 'calendar',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Weather',
    href: '/weather',
    icon: 'fe-cloud-drizzle',
    menu: true,
    private: true,
    children: [
      {
        name: 'Observations',
        href: '/weather/observations',
        path: ['/weather/observations'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Forecast',
        href: '/weather/forecast',
        path: ['/weather/forecast'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Comparison',
        href: '/weather/comparison',
        path: ['/weather/comparison'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
    ],
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Reports',
    href: '/reports',
    icon: 'fe-file-text',
    menu: true,
    private: true,
    //    namespace: 'reports',
    children: [
      {
        name: 'Maintenance',
        href: '/reports/maintenance',
        path: ['/reports/maintenance'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Tests',
        href: '/reports/tests',
        path: ['/reports/tests'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Usage',
        href: '/reports/usage',
        path: ['/reports/usage'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
    ],
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Media',
    href: '/media',
    path: ['/media'],
    module: 'Blank',
    exact: true,
    icon: 'fe-image',
    menu: true,
    private: true,
    //    namespace: 'media',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'main',
    name: 'Settings',
    href: '/settings',
    icon: 'fe-settings',
    menu: true,
    private: true,
    //    namespace: 'settings',
    children: [
      {
        name: 'General',
        href: '/settings/general',
        path: ['/settings/general'],
        module: 'Settings',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Categories',
        href: '/settings/categories',
        path: ['/settings/categories'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Employees',
        href: '/settings/employees',
        path: ['/settings/employees'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Fertilizers',
        href: '/settings/fertilizers',
        path: ['/settings/fertilizers'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Seeds',
        href: '/settings/seeds',
        path: ['/settings/seeds'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
      {
        name: 'Machines',
        href: '/settings/machines',
        path: ['/settings/machines'],
        module: 'Blank',
        exact: true,
        menu: true,
        private: true,
      },
    ],
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'Soil Analysis',
    name: 'Under Soil Sensors',
    href: '/under-soil-sensors',
    path: ['/under-soil-sensors'],
    module: 'Blank',
    exact: true,
    icon: 'fe-activity',
    menu: true,
    private: true,
    //    namespace: 'underSoilSensors',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'Soil Analysis',
    name: 'Reports',
    href: '/reports-other',
    path: ['/reports-other'],
    module: 'Blank',
    exact: true,
    icon: 'fe-file-text',
    menu: true,
    private: true,
    //    namespace: 'reportsOther',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'Equipment Management',
    name: 'Beacons',
    href: '/beacons',
    path: ['/beacons'],
    module: 'Blank',
    exact: true,
    icon: 'fe-thermometer',
    menu: true,
    private: true,
    //    namespace: 'beacons',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'Equipment Management',
    name: 'Fleet Management',
    href: '/fleet-management',
    path: ['/fleet-management'],
    module: 'Blank',
    exact: true,
    icon: 'fe-truck',
    menu: true,
    private: true,
    //    namespace: 'fleetManagement',
  },
  {
    section: MenuLeftType.DEFAULT,
    group: 'Equipment Management',
    name: 'Asset Tracking',
    href: '/asset-tracking',
    path: ['/asset-tracking'],
    module: 'Blank',
    exact: true,
    icon: 'fe-list',
    menu: true,
    private: true,
    //    namespace: 'assetTracking',
  },
  {
    section: MenuLeftType.TOURNAMENT,
    group: 'main',
    name: 'Dashboards',
    href: '/tournament-dashboard-kpi',
    path: ['/tournament-dashboard-kpi', '/tournament-dashboard-kpi/:tabId'],
    module: 'TournamentDashboardKPIs',
    exact: true,
    icon: 'fe-pie-chart',
    menu: true,
    private: true,
    namespace: 'tournamentDashboardKPI',
  },
  {
    section: MenuLeftType.TOURNAMENT,
    group: 'main',
    name: 'Community',
    href: '/tournament-dashboard', // TODO name and route is different
    path: ['/tournament-dashboard'],
    module: 'TournamentDashboard',
    exact: true,
    icon: 'fe-users',
    menu: true,
    private: true,
    //    namespace: 'tournamentDashboard',
  },
  {
    section: MenuLeftType.TOURNAMENT,
    group: 'main',
    name: 'Players Perception',
    href: '/players-perception',
    path: ['/players-perception'],
    module: 'Blank',
    exact: true,
    icon: 'fe-smile',
    menu: true,
    private: true,
    //    namespace: 'playersPerception',
  },
  {
    section: MenuLeftType.TOURNAMENT,
    group: 'main',
    name: 'Reports',
    href: '/tournament-reports',
    path: ['/tournament-reports'],
    module: 'Blank',
    exact: true,
    icon: 'fe-settings',
    menu: true,
    private: true,
    //    namespace: 'tournamentReports',
  },
  {
    name: '404',
    href: '',
    path: ['*'],
    module: 'NotFound',
    exact: false,
    menu: false,
    private: false,
  },
];

export default routes;
