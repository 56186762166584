import { createAsyncThunk, createEntityAdapter, createSlice, Draft } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { AgileCard, Filter, TaskEvent, TaskEventsPayload, TaskPlannerState, TaskStatusEnum } from './types';
import { State } from '../../store';
import api from './api';
import calendar from '../../api/calendar';

import aeration from '../../api/aeration';
import fertilization from '../../api/fertilization';
import irrigation from '../../api/irrigation';
import mowing from '../../api/mowing';
import pitchrepair from '../../api/pitchRepair';
import { getActiveClubId } from '../core/selectors';
import i18n from '../../i18n';
import { checkFilters } from '../../utilities/helpers';

const taskApi = {
  aeration,
  fertilization,
  irrigation,
  mowing,
  pitchrepair,
};
const taskEventsAdapter = createEntityAdapter({
  selectId: (task: TaskEvent) => task.id,
});

const agileBoardCardsAdapter = createEntityAdapter({
  selectId: (card: AgileCard) => card.id,
});

export const loadAgileBoardCards = createAsyncThunk('taskPlanner/loadAgileBoardCards', async () => api.loadAgileBoardCards());
export const loadEventsTasks = createAsyncThunk('taskPlanner/loadEventsTasks', async (payload: TaskEventsPayload) => calendar.loadEventsTasks(payload));
export const removeEventsTasks = createAsyncThunk('taskPlanner/removeEventsTasks', async (payload: { taskId: number; taskType: string; clubId: string }) => {
  return taskApi[payload.taskType.toLowerCase() as keyof typeof taskApi]?.removeEventsTasks(payload);
});

const initialState: TaskPlannerState = {
  fetching: {},
  sending: {},
  taskEvents: taskEventsAdapter.getInitialState(),
  agileBoardCards: agileBoardCardsAdapter.getInitialState(),
  tasksBoardCards: [
    { type: 'mowing', cards: [{ date: '', content: '' }] },
    { type: 'irrigation', cards: [{ date: '', content: '' }] },
    { type: 'fertilization', cards: [{ date: '', content: '' }] },
    { type: 'pitch repairs', cards: [{ date: '', content: '' }] },
    { type: 'line marking', cards: [{ date: '', content: '' }] },
    { type: 'comments', cards: [{ date: '', content: '' }] },
    { type: 'images', cards: [{ date: '', content: '' }] },
  ],
};

export const slice = createSlice({
  name: 'taskPlanner',
  initialState,
  reducers: {
    addAgileCard: () => {
      // TODO
    },
    moveAgileCard: (state: Draft<TaskPlannerState>, action) => {
      const { selectAll } = agileBoardCardsAdapter.getSelectors();
      const cardToUpdate = { ...action.payload.card, status: action.payload.status };
      const tasks = orderBy(
        selectAll(state.agileBoardCards).filter((card) => card.status === action.payload.status && card.id !== action.payload.card.id),
        'priority',
      );
      if (action.payload.priority >= 0) {
        tasks.splice(action.payload.priority, 0, cardToUpdate);
      } else {
        tasks.push(cardToUpdate);
      }
      const tasksToUpdate = tasks.reduce((partTasks, task, index) => [...partTasks, { ...task, priority: index }], [] as AgileCard[]);
      state.agileBoardCards = agileBoardCardsAdapter.upsertMany(state.agileBoardCards, tasksToUpdate);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAgileBoardCards.pending, (state) => {
      state.fetching.agileBoardCards = true;
    });
    builder.addCase(loadAgileBoardCards.fulfilled, (state, action) => {
      state.fetching.agileBoardCards = false;
      state.agileBoardCards = agileBoardCardsAdapter.setAll(state.agileBoardCards, action.payload);
    });
    builder.addCase(loadAgileBoardCards.rejected, (state) => {
      state.fetching.agileBoardCards = false;
    });
    builder.addCase(loadEventsTasks.pending, (state) => {
      state.fetching.taskEvents = true;
    });
    builder.addCase(loadEventsTasks.fulfilled, (state, action) => {
      state.fetching.taskEvents = false;
      state.taskEvents = taskEventsAdapter.upsertMany(
        state.taskEvents,
        action.payload.map((task) => ({ ...task, clubId: action.meta.arg.clubId })),
      );
    });
    builder.addCase(loadEventsTasks.rejected, (state) => {
      state.fetching.taskEvents = false;
    });
    builder.addCase(removeEventsTasks.pending, (state) => {
      state.sending.taskEvents = true;
    });
    builder.addCase(removeEventsTasks.fulfilled, (state, action) => {
      state.sending.taskEvents = false;
      state.taskEvents = taskEventsAdapter.removeOne(state.taskEvents, action.meta.arg.taskId);
    });
    builder.addCase(removeEventsTasks.rejected, (state) => {
      state.sending.taskEvents = false;
    });
  },
});

export const { selectAll: selectAgileBoardCardsAll } = agileBoardCardsAdapter.getSelectors((state: State) => state.taskPlanner.agileBoardCards);
export const { selectAll: selectTaskEventsAll } = taskEventsAdapter.getSelectors((state: State) => state.taskPlanner.taskEvents);

export const selectTaskEventsByClub = createSelector([selectTaskEventsAll, getActiveClubId], (tasks, clubId) => tasks.filter((task) => task.clubId === clubId));

export const selectTaskEventsByClubMapped = createSelector([selectTaskEventsByClub], (tasks) =>
  tasks.map((task) => ({
    ...task,
    titleMapped: i18n.t(`task:${task.title}`),
    eventTypeMapped: i18n.t(`task:${task.eventType}`),
    taskStatusMapped: TaskStatusEnum[task.taskStatus] ? i18n.t(`task:${TaskStatusEnum[task.taskStatus]}`) : task.taskStatus,
  })),
);

export const selectTaskEventsByClubMappedFilteredSelector = createSelector(
  [selectTaskEventsByClubMapped, (state: State, filter: Filter) => filter],
  (tasks, filter) => {
    return tasks.filter((task) => checkFilters(task, filter));
  },
);

export const fetchingLoadEventsTasks = (state: State): boolean => state.taskPlanner.fetching.taskEvents !== false;
export const sendingEventsTasks = (state: State): boolean => !!state.taskPlanner.sending.taskEvents;

export const selectTaskEventsByClubMappedFiltered = (state: State) => (filter: Filter): TaskEvent[] =>
  selectTaskEventsByClubMappedFilteredSelector(state, filter);
//
export const {
  actions,
  actions: { addAgileCard, moveAgileCard },
} = slice;

export default slice.reducer;
