import React from 'react';
import { useTranslation } from 'react-i18next';
import TestTable from './TestTable';
import CardShadowWithButtons from '../../CardShadow';

type Props = {
  className?: string;
  selectedData: string;
  setSelectedData: (data: string) => void;
};
const TestTableCard: React.FC<Props> = ({ className, ...props }: Props) => {
  const { t } = useTranslation('tournamentDashboardKPI');

  return (
    <CardShadowWithButtons title={t('Test')} icon="fe fe-sliders" noButtons>
      <TestTable {...props} />
    </CardShadowWithButtons>
  );
};

export default TestTableCard;
