import React from 'react';
import cx from 'classnames';
import { ChatMessage } from '../../modules/tournamentDashboard/types';
import style from './ChatDrawerMessage.module.scss';
import avatar from '../../images/avatars/1.jpg';
import ChatTimeCount from './ChatTimeCount';

type Props = {
  message: ChatMessage;
  user: string;
};

const ChatDrawerMessage: React.FC<Props> = ({ message, user }: Props): JSX.Element => {
  const isUserMessage = user === message.name;

  return (
    <div className={cx(style.message, isUserMessage && style.message_currentUser)}>
      <div className={style.message__content}>
        <div className={style.message__title}>
          {isUserMessage ? 'you' : message.name}, <ChatTimeCount time={message.creatingDate} />
        </div>
        <div>{message.message}</div>
      </div>
      <div className={style.message__avatar}>
        <img src={avatar} alt="user_avatar" />
      </div>
    </div>
  );
};

export default ChatDrawerMessage;
