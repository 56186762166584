import axiosRequest from '../../utilities/axiosRequest';
import { ImagesData, PitchTestsTableData, PitchUsageData, SearchDocumentsData, SearchImagesData, WorkLogTableData } from './types';

export async function loadPitchUsage(): Promise<PitchUsageData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/PitchUsage`,
    caller: 'loadPitchUsage',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadImages(): Promise<ImagesData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/Images`,
    caller: 'loadImages',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadWorkLogTable(): Promise<WorkLogTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/WorkLogTable`,
    caller: 'loadWorkLogTable',
    targetAPI: 'MOCKUP_API',
  });
}
export async function loadPitchTestsTable(): Promise<PitchTestsTableData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/PitchTestsTable`,
    caller: 'loadPitchTestsTable',
    targetAPI: 'MOCKUP_API',
  });
}

export async function loadSearchImages(payload: { search: string }): Promise<SearchImagesData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/SearchImages`,
    params: payload,
    caller: 'loadSearchImages',
    targetAPI: 'MOCKUP_API',
  });
}
export async function loadSearchDocuments(payload: { search: string }): Promise<SearchDocumentsData[]> {
  return axiosRequest({
    method: 'GET',
    url: `/SearchDocuments`,
    params: payload,
    caller: 'loadSearchDocuments',
    targetAPI: 'MOCKUP_API',
  });
}

export default {
  loadPitchUsage,
  loadImages,
  loadWorkLogTable,
  loadPitchTestsTable,
  loadSearchImages,
  loadSearchDocuments,
};
