import React, { useMemo } from 'react';
import { shuffle } from 'lodash';
import { useTranslation } from 'react-i18next';
import ImagesGallery from '../../ImagesGallery/ImagesGallery';
import settings from '../../../settings';

import CardShadowWithButtons from '../../CardShadow';

const {
  API: { MOCKUP_API },
} = settings;

type Props = {
  selectedData: string;
  selectedRow: string;
};

const data = [
  { src: `${MOCKUP_API}/images/img5.jpg`, alt: 'grass' },
  { src: `${MOCKUP_API}/images/img6.jpg`, alt: 'grass' },
  { src: `${MOCKUP_API}/images/img7.jpg`, alt: 'work' },
  { src: `${MOCKUP_API}/images/img8.jpg`, alt: 'work' },
  { src: `${MOCKUP_API}/images/img9.jpg`, alt: 'work' },
];

const ImageGalleryCard: React.FC<Props> = ({ selectedData, selectedRow }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');

  const shuffledData = useMemo(() => {
    return shuffle(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, selectedRow]);

  return (
    <CardShadowWithButtons title={t('Images')} icon="fe fe-sliders" noButtons>
      <ImagesGallery data={shuffledData} />
    </CardShadowWithButtons>
  );
};

export default ImageGalleryCard;
