import React, { ReactNode, useEffect } from 'react';
import { useInitUserState } from 'modules/user/hooks';
import { isEmpty } from 'lodash';
import LoadingScreen from '../LoadingScreen';
import userManager from '../../user-manager';
import { useDispatch, useSelector } from '../../store';
import { userSelector } from '../../modules/user/selectors';
import { getActiveClubId, getActiveTournamentId } from '../../modules/core/selectors';
import { setActiveClub, setActiveTournament } from '../../modules/core/slice';

type Props = {
  children: ReactNode;
};

const getClubOrTournament = (data: { clubId?: string; tournamentId?: string }[]) =>
  Array.isArray(data)
    ? data.reduce((partClubs: string[], row: { clubId?: string; tournamentId?: string }) => {
        return [...partClubs, row.clubId || row.tournamentId || ''];
      }, [] as string[])
    : [];

const ProtectedRoutes: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  useInitUserState(user);
  const activeClub = useSelector(getActiveClubId);
  const activeTournament = useSelector(getActiveTournamentId);

  useEffect(() => {
    if (!user) {
      userManager.getUser().then(async (user) => {
        if (!user) {
          await userManager.signinRedirect();
        } else {
          const clubs = getClubOrTournament(user.profile.clubs);
          const tournaments = getClubOrTournament(user.profile.tournaments);

          if (!isEmpty(clubs) && clubs.indexOf(activeClub) < 0) {
            dispatch(setActiveClub(clubs[0]));
          }
          if (!isEmpty(tournaments) && tournaments.indexOf(activeTournament) < 0) {
            dispatch(setActiveTournament(tournaments[0]));
          }
        }
      });
    }
  }, [dispatch, user, activeClub, activeTournament]);

  return <>{user && activeClub ? children : <LoadingScreen />}</>;
};

export default ProtectedRoutes;
