import React from 'react';
import MediaTable from './MediaTable';
import { useSelector } from '../../store';
import { selectMediaTableAll } from '../../modules/tournamentDashboard/slice';

export default function MediaTableIndex(): JSX.Element {
  const data = useSelector(selectMediaTableAll);

  return <MediaTable data={data} />;
}
