import React, { ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { capitalize } from 'lodash';

type Props = {
  content: { title: string; content: ReactNode; key: string }[];
  url: string;
  className?: string;
};

const NavTabs: React.FC<Props> = ({ content, url, className = '' }: Props) => {
  const history = useHistory();
  const { tabId } = useParams<{ tabId: string }>();

  const handleChange = useCallback((key) => history.push(`/${url}/${key}`), [url, history]);

  return (
    <Tabs className={className} activeKey={tabId} onChange={handleChange}>
      {content.map((item) => (
        <Tabs.TabPane tab={capitalize(item.title)} key={item.key}>
          {item.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default NavTabs;
