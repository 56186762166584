import { createSelector } from 'reselect';
import { groupBy, orderBy } from 'lodash';

import { selectAgileBoardCardsAll } from './slice';
import { State } from '../../store';

const tasksBoardCardsSelector = (state: State) => state.taskPlanner.tasksBoardCards;

export const selectAgileBoardCardsGrouped = createSelector(selectAgileBoardCardsAll, (agileBoardCards) => {
  const groupedBy = groupBy(agileBoardCards, 'status');
  return Object.keys(groupedBy).reduce((partGroups, group) => {
    return { ...partGroups, [group]: orderBy(groupedBy[group], 'priority') };
  }, {});
});

export const selectTasksBoardCards = createSelector(tasksBoardCardsSelector, (cards) => cards);
