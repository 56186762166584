import React from 'react';
import { useTranslation } from 'react-i18next';
import UsageCategoriesChart from './UsageCategories';
import CardShadowWithButtons from '../../CardShadow';

type Props = {
  selectedData: string;
  selectedRow: string;
};

const UsageCategoriesChartCard: React.FC<Props> = ({ selectedData, selectedRow }: { selectedData: string; selectedRow: string }) => {
  const { t } = useTranslation('tournamentDashboardKPI');
  return (
    <CardShadowWithButtons title={t('Usage categories')} icon="fe fe-sliders" noButtons>
      <UsageCategoriesChart selectedData={selectedData} selectedRow={selectedRow} />
    </CardShadowWithButtons>
  );
};

export default UsageCategoriesChartCard;
