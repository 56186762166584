import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { ColProps } from 'antd/lib/col';
import { Col, Input, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import styles from './FormField.module.scss';

type Props = {
  label?: string;
  name: string;
  size?: 'small' | 'middle' | 'large';
  className?: string;
  labelCol?: ColProps;
  wrappedCol?: ColProps;
  labelAddonAfter?: ReactElement;
  loading?: boolean;
  children: ReactNode;
};

const LoadingInput: React.FC<{ size?: 'small' | 'middle' | 'large'; className?: string }> = (props) => (
  <Input disabled suffix={<LoadingOutlined />} {...props} />
);

const FormField: React.FC<Props> = ({
  label,
  name = '',
  size = 'middle',
  className = '',
  labelAddonAfter,
  labelCol,
  wrappedCol,
  children,
  loading = false,
  ...props
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldErrors = get(errors, name) as FieldErrors;

  const childrenClassName = (className = '') => cx(styles.controlBlock, className, fieldErrors ? 'ant-form-item-has-error ant-form-item-with-help' : '');
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...props,
        id: name,
        className: childrenClassName(child.props.className),
        errors: fieldErrors,
        size,
      });
    }
    return child;
  });
  const labelClass = (() => {
    if (size === 'large') {
      return styles.controlLabel_large;
    }
    return size === 'small' ? styles.controlLabel_small : '';
  })();

  return (
    <Row className={cx('ant-form-item', className, fieldErrors ? 'ant-form-item-has-error ant-form-item-with-help' : '')}>
      {label && (
        <Col className={cx('ant-form-item-label', labelClass)} {...labelCol}>
          <label className={cx(styles.controlLabelLabel)} htmlFor={name}>
            {label}
          </label>
          {labelAddonAfter && labelAddonAfter}
        </Col>
      )}
      <Col className="ant-form-item-control" {...wrappedCol}>
        <div className="ant-form-item-control-input">
          <div className="ant-form-item-control-input-content">
            {loading ? <LoadingInput size={size} className={childrenClassName()} /> : childrenWithProps}
          </div>
        </div>
        {fieldErrors && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div className={styles.errorText} role="alert">
              {fieldErrors.message}
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default FormField;
