import React, { useCallback } from 'react';
import { Menu, Dropdown } from 'antd';
import FlagEn from '../../images/flags/en.svg';
import FlagRu from '../../images/flags/ru.svg';
import FlagIs from '../../images/flags/is.svg';
import styles from './LanguageSwitcher.module.scss';
import settings, { Languages } from '../../settings';
import { useDispatch, useSelector } from '../../store';
import { setLanguage } from '../../modules/core/slice';
import { getCurrentLanguage } from '../../modules/core/selectors';

type Flags = {
  [key: string]: string;
};

const { supportedLanguages }: { supportedLanguages: Languages } = settings;

const flags = {
  en: FlagEn,
  is: FlagIs,
  ru: FlagRu,
} as Flags;

export default function LanguageSwitcher(): JSX.Element {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(getCurrentLanguage);

  const changeLanguage = useCallback(
    ({ key }) => {
      dispatch(setLanguage(key));
    },
    [dispatch],
  );

  const menu = (
    <Menu selectedKeys={[selectedLanguage]} onClick={changeLanguage}>
      {Object.keys(supportedLanguages).map((language) => (
        <Menu.Item key={language}>
          <span className={styles.menuIcon}>{flags[language] && <img src={flags[language]} alt={supportedLanguages[language].title} />}</span>
          {supportedLanguages[language].title}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <div className={styles.flag}>
          {flags[selectedLanguage] && <img src={flags[selectedLanguage]} alt={supportedLanguages[selectedLanguage]?.title} />}
          <span>{selectedLanguage.toUpperCase()}</span>
        </div>
      </div>
    </Dropdown>
  );
}
