import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import DatePicker from '../DatePicker/DatePicker';
import { getFullDateWithWeekday } from '../../utilities/getRelativeDate';
import style from './TasksBoard.module.scss';

type Props = {
  initialDay: string;
  day: string;
  setDay: (date: string) => void;
};

const DatePickerHeader: React.FC<Props> = ({ initialDay, day, setDay }: Props) => {
  const { t: tCommon } = useTranslation('common');
  const onDateChange = useCallback(
    (date) => {
      const formattedDay = DateTime.fromISO(date.toISOString()).toISODate();
      setDay(formattedDay);
    },
    [setDay],
  );

  const onPreviousDayClick = useCallback(() => {
    setDay(DateTime.fromISO(day).minus({ day: 1 }).toISODate());
  }, [day, setDay]);

  const onNextDayClick = useCallback(() => {
    setDay(DateTime.fromISO(day).plus({ day: 1 }).toISODate());
  }, [day, setDay]);

  const onTodayClick = useCallback(() => {
    setDay(initialDay);
  }, [initialDay, setDay]);

  return (
    <div className={style.header}>
      <div className={style.header__buttons}>
        <div className={cx('btn-group', style.header__buttonGroup)}>
          <button type="button" className="btn btn-mainDark" onClick={onPreviousDayClick}>
            <i className="fe fe-chevron-left" />
          </button>
          <button type="button" className="btn btn-mainDark" onClick={onNextDayClick}>
            <i className="fe fe-chevron-right" />
          </button>
        </div>
        <button type="button" className={cx('btn btn-mainDark', style.header__button_sm)} onClick={onTodayClick} disabled={initialDay === day}>
          {tCommon('Today')}
        </button>
      </div>
      <div className={style.header__title}>{getFullDateWithWeekday(day)}</div>
      <DatePicker onChange={onDateChange} value={day} allowClear={false} />
    </div>
  );
};

export default DatePickerHeader;
