import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Category, MenuData } from '../MenuLeft/types';
import styles from './Breadcrumbs.module.scss';

type Props = {
  menuData: MenuData[];
};

const Breadcrumbs = ({ menuData }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation('routes');

  const breadcrumbs = useMemo(() => {
    const checkItems = (items: Category[], active: Category, pathname: string): Category => {
      return items.reduce((activeIn, item) => {
        if (item.url === pathname) {
          return item;
        }
        if (item.children) {
          return checkItems(item.children, activeIn, pathname);
        }
        return activeIn;
      }, active);
    };

    const getTargetMenuItem = (pathname: string) =>
      menuData.reduce((active, group) => {
        return checkItems(group.items, active, pathname);
      }, {} as Category);

    const pathUrl = pathname.split('/');

    return pathUrl.reduce((partItems, item, index) => {
      if (index === 0) {
        return partItems;
      }
      const path = pathUrl.slice(0, index + 1).join('/');
      const targetMenuItem = getTargetMenuItem(path);

      return !isEmpty(targetMenuItem) ? [...partItems, targetMenuItem.title] : partItems;
    }, [] as string[]);
  }, [pathname, menuData]);

  return (
    breadcrumbs && (
      <div className={styles.breadcrumbs}>
        <Helmet>
          <title>
            {breadcrumbs.reverse()[0]}: GRASPRO — {t('Pitch Management System')}
          </title>
          <meta name="description" content={t('Pitch Management System')} />
        </Helmet>
        <div className={styles.path}>
          <Link to="/">{t('Home')}</Link>
          {breadcrumbs.map((item, index) => (
            <span key={item}>
              <span className={styles.arrow} />
              {index === breadcrumbs.length - 1 ? <strong className={styles.current}>{item}</strong> : item}
            </span>
          ))}
        </div>
      </div>
    )
  );
};

export default Breadcrumbs;
