import React, { useMemo, MouseEvent, useCallback } from 'react';
import Search from 'components/Search';
import classnames from 'classnames';
import LanguageSwitcher from 'components/LanguageSwitcher';
import UserNotifications from 'components/UserNotifications';
import UserMenu from 'components/UserMenu/UserMenu';
import Notifications from 'components/Notifications';
import style from './MenuTop.module.scss';
import ClubsOrTournaments from './ClubsOrTournaments/ClubsOrTournaments';
import { useDispatch, useSelector } from '../../store';
import { userSelector } from '../../modules/user/selectors';
import { createUserMenuTree } from '../../utilities/helpers';
import { MenuLeftType } from '../PageLayout/types';
import { getActiveClub, getActiveTournament } from '../../modules/core/selectors';
import { setActiveClub, setActiveTournament } from '../../modules/core/slice';

type Props = {
  layoutMenu: string;
  menuLeftType: string;
};

const MenuTop: React.FC<Props> = ({ layoutMenu, menuLeftType }: Props) => {
  const dispatch = useDispatch();
  const data = useSelector(userSelector);
  const tournaments = useMemo(() => (data ? createUserMenuTree(data.profile.tournaments) : []), [data]);
  const clubs = useMemo(() => (data ? createUserMenuTree(data.profile.clubs) : []), [data]);
  const activeClub = useSelector(getActiveClub);
  const activeTournament = useSelector(getActiveTournament);
  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const { dataset } = event.target as HTMLElement;
      if (dataset.id) {
        dispatch((menuLeftType === MenuLeftType.DEFAULT ? setActiveClub : setActiveTournament)(dataset.id));
      }
    },
    [dispatch, menuLeftType],
  );

  return (
    <div className={classnames(style.topbar, style[layoutMenu])}>
      <div className="mr-auto mr-md-1">
        <Search />
      </div>
      <div className="mr-4 d-none d-md-block">
        {activeClub && menuLeftType === MenuLeftType.DEFAULT && <ClubsOrTournaments data={clubs} title={activeClub.clubName} onClick={onClick} />}
        {activeTournament && menuLeftType !== MenuLeftType.DEFAULT && (
          <ClubsOrTournaments data={tournaments} title={activeTournament.tournamentName} onClick={onClick} />
        )}
      </div>

      <div className="mr-3 d-none d-sm-block ml-auto">
        <UserNotifications />
      </div>
      <div className="mr-3 d-none d-sm-block">
        <LanguageSwitcher />
      </div>
      <div>
        <UserMenu />
      </div>
      <Notifications />
    </div>
  );
  /*
 return (
    <div className={style.menuTop}>
      <Search />
      {/!* <MenuDashboards className={style.menuTop__dashboards} /> *!/}
      <div className={style.menuTop__toastNotification}>
        <Notifications className={style.menuTop__toastNotificationsList} />
      </div>
      <LanguageSwitcher className={style.menuTop__language} />
      <UserNotifications className={style.menuTop__notifications} />
      <UserMenu className={style.menuTop__userMenu} />
    </div>
  );
  */
};

export default MenuTop;
