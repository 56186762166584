import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import classNames from 'classnames';
import { RouteProps } from '../../routes/interface';
import PageLayout from '../PageLayout';
import style from './Blank.module.scss';

export default function Blank({ route }: RouteProps): JSX.Element {
  const { t } = useTranslation('blank');
  const history = useHistory();

  return (
    <PageLayout menuLeftType={route.section} className="vb__content__flex__center">
      <div className={classNames('text-dark font-size-32', style.container)}>
        <div className="font-weight-bold mb-3">{t('Page not found')}</div>
        <div className="text-gray-6 font-size-24">{t('This page is deprecated, deleted, or coming soon')}</div>
        <div className="font-weight-bold font-size-70 mb-1">404 —</div>
        <Button onClick={() => history.goBack()} className="btn btn-outline-primary">
          {t('Go Back')}
        </Button>
      </div>
    </PageLayout>
  );
}
