import axiosRequest from '../../utilities/axiosRequest';
import { AgileCard } from './types';

export async function loadAgileBoardCards(): Promise<AgileCard[]> {
  return axiosRequest({
    method: 'GET',
    url: `/AgileBoardCards`,
    caller: 'loadAgileBoardCards',
    targetAPI: 'MOCKUP_API',
  });
}

export default {
  loadAgileBoardCards,
};
