import React, { ReactNode, useState } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { Tooltip } from 'antd';
import styles from './FieldWithTooltipError.module.scss';

type Props = {
  name: string;
  value: string | number;
  tabActiveKey?: number;
  parentControlTabKey?: number;
  isLoading: boolean;
  children: ReactNode;
};

const FieldWithTooltipError: React.FC<Props> = ({ value, name, parentControlTabKey, tabActiveKey, isLoading, children, ...props }: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  const fieldErrors = get(errors, name) as FieldErrors;
  const isCurrentTabError = !!fieldErrors && tabActiveKey === parentControlTabKey;

  const [isFocus, setIsFocus] = useState(false);
  const classes = cx({
    [styles.inputError]: isCurrentTabError,
  });

  const childrenClassName = (className = '') => cx(classes, className);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...props,
        id: name,
        className: childrenClassName(child.props.className),
        onFocus: () => setIsFocus(true),
        onBlur: () => setIsFocus(false),
        readOnly: isLoading,
      });
    }
    return child;
  });

  return (
    <div className={styles.wrapper}>
      {isCurrentTabError && isFocus && <Tooltip placement="top" title={fieldErrors.message} color="#F28264" visible={isCurrentTabError} />}
      <div className={isCurrentTabError ? 'ant-form-item-has-error ant-form-item-with-help wide' : 'wide'}>{childrenWithProps}</div>
    </div>
  );
};

export default FieldWithTooltipError;
