import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PitchTestsTable from '../PitchTestsTable';
import TabCard from '../TabCard';

const PitchTestsTableCard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const content = useMemo(() => {
    return [
      {
        title: t('Anfield'),
        content: <PitchTestsTable />,
      },
      {
        title: t('Melwood'),
        content: <PitchTestsTable />,
      },
      {
        title: t('Training site'),
        content: <PitchTestsTable />,
      },
    ];
  }, [t]);

  return <TabCard icon="fe fe-sliders" title={t('Tests')} content={content} />;
};

export default PitchTestsTableCard;
