import React from 'react';
import { useTranslation } from 'react-i18next';
import Chat from '../Chat';
import CardShadowWithButtons from '../CardShadow';
import style from './TournamentDashboard.module.scss';

const ChatCard: React.FC = () => {
  const { t } = useTranslation('tournamentDashboard');

  return (
    <CardShadowWithButtons title={t('Chat')} icon="fe fe-message-square" noButtons>
      <Chat className={style.chat} />
    </CardShadowWithButtons>
  );
};

export default ChatCard;
