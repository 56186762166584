import React from 'react';
import { chunk } from 'lodash';
import TasksBoardCard from './TasksBoardCard';
import { TasksBoardCards } from '../../modules/taskPlanner/types';
import style from './TasksBoard.module.scss';

type Props = {
  cards: TasksBoardCards;
  day: string;
};

const TasksBoard: React.FC<Props> = ({ cards, day }: Props): JSX.Element => {
  return (
    <div className={style.body__container}>
      <div className="row flex-shrink-0">
        {chunk(cards, 4).map((item, index) => (
          <div key={index.toString()} className="col-lg-6">
            {item.map((item) => (
              <TasksBoardCard key={item.type} title={item.type} content={item.cards.filter((item) => item.date === day)} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TasksBoard;
