import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table/Table';
import { VenuesTableData } from 'modules/tournamentDashboard/types';
import style from '../Table/Table.module.scss';
import { Column } from '../Table/types';

type Props = {
  data: VenuesTableData[];
};

const VenuesTable: React.FC<Props> = ({ data }: Props) => {
  const { t } = useTranslation('table.venuesTable');

  const columns = useMemo(
    () =>
      [
        {
          title: t('Club'),
          dataIndex: 'club',
          dataType: 'links',
          width: 200,
        },
        {
          title: t('Pitch'),
          dataIndex: 'pitch',
        },
        {
          title: t('Country'),
          dataIndex: 'country',
        },
        {
          title: t('City'),
          dataIndex: 'city',
        },
        {
          title: t('Use'),
          dataIndex: 'typeOfUse',
        },
        {
          title: t('Surface'),
          dataIndex: 'typeOfSurface',
        },
        {
          title: t('Contact'),
          dataIndex: 'contact',
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} data={data} height={317} className={style.table_tournament} size="middle" />
    </div>
  );
};

export default VenuesTable;
