import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ImagesGallery from '../ImagesGallery';
import TabCard from '../TabCard';

const ChartCard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const content = useMemo(() => {
    return [
      {
        title: t('Anfield'),
        content: <ImagesGallery />,
      },
      {
        title: t('Melwood'),
        content: <ImagesGallery />,
      },
      {
        title: t('Training site'),
        content: <ImagesGallery />,
      },
    ];
  }, [t]);

  return <TabCard icon="fe fe-image" title={t('Images')} content={content} height={500} />;
};

export default ChartCard;
