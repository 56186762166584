import axiosRequest from '../../utilities/axiosRequest';

export async function loadTranslation({ clubId, content, targetLanguage }: { clubId: string; content: string; targetLanguage: string }): Promise<string> {
  return axiosRequest({
    method: 'POST',
    url: `/Calendar/${clubId}/translate`,
    data: {
      Content: content,
      TargetLanguage: targetLanguage,
    },
    caller: 'loadTranslation',
    targetAPI: 'SYSTEM_API',
  });
}

export default {
  loadTranslation,
};
