import React from 'react';
import { useTranslation } from 'react-i18next';
import WorkLogTable from './WorkLogTable';
import CardShadowWithButtons from '../../CardShadow';

type Props = {
  selectedData: string;
  selectedRow: string;
};

const WorkLogTableCard: React.FC<Props> = ({ selectedData, selectedRow }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');
  return (
    <CardShadowWithButtons title={t('Work log')} icon="fe fe-sliders" noButtons>
      <WorkLogTable selectedData={selectedData} selectedRow={selectedRow} />
    </CardShadowWithButtons>
  );
};

export default WorkLogTableCard;
