import { useEffect } from 'react';
import { useDispatch } from 'store';
import useIdle from 'react-use/lib/useIdle';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import userManager from '../../user-manager';
import { loadUserSuccess, loadUser, loadUserError } from './slice';
import { User } from './types';

let isIdleGlobal = false;

// eslint-disable-next-line import/prefer-default-export
export function useInitUserState(user: User | null): void {
  const dispatch = useDispatch();
  const isIdle = useIdle(7.2e6, false);

  useEffect(() => {
    isIdleGlobal = isIdle;
  }, [isIdle]);

  useEffectOnce(() => {
    if (user) {
      return;
    }
    dispatch(loadUser());

    userManager.getUser().then((user) => {
      if (user) {
        dispatch(loadUserSuccess((user as unknown) as User));
      }
    });

    userManager.events.addAccessTokenExpiring(() => {
      if (!isIdleGlobal) {
        userManager.signinSilent();
      }
    });

    userManager.events.addSilentRenewError((error) => {
      console.error('silent renewal fails', error); // eslint-disable-line
      dispatch(loadUserError(error));
    });

    /* userManager.events.addAccessTokenExpired(() => {
      console.log('Token is expired')
    }) */

    userManager.events.addAccessTokenExpired(() => {
      userManager.signinRedirect();
    });

    userManager.events.addUserSignedOut(() => {
      userManager.signinRedirect();
    });

    userManager.events.addUserLoaded(() => {
      userManager.getUser().then((user) => {
        if (user) {
          dispatch(loadUserSuccess((user as unknown) as User));
        }
      });
    });
  });
}
