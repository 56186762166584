import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import classNames from 'classnames';
import style from '../Table.module.scss';
import { CustomColumnProps } from '../types';
import i18n from '../../../i18n';
import { TaskStatusEnum, TaskStatus } from '../../../modules/taskPlanner/types';

function getStatusColumn<T extends Record<string, unknown>>(data: T[], dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  const getTranslation = (status: TaskStatus) =>
    (status >= 0 ? i18n.t(`task:${TaskStatusEnum[(status as unknown) as keyof typeof TaskStatusEnum]}`, { nsSeparator: ':' }) : status) as string;
  const columnData = Array.from(new Set(data.map((row) => getTranslation(row[dataIndex] as TaskStatus))));
  return {
    filters: columnData.sort((a, b) => a.localeCompare(b)).map((item: string) => ({ text: item, value: item })),
    filterMultiple: true,
    onFilter: (value: string | number | boolean, record: T) => ((record[dataIndex] as unknown) as string).indexOf(value as string) === 0,
    sorter: (a, b) => getTranslation(a[dataIndex] as TaskStatus).localeCompare(getTranslation(b[dataIndex] as TaskStatus)),
    sortDirections: ['descend', 'ascend'],
    render: (value) => <span className={classNames(style.table__row_status, style[`table__row_${value}`])}>{getTranslation(value)}</span>,
  };
}

export default getStatusColumn;
