import React, { useState, MouseEvent, ChangeEvent } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAddNotification } from 'modules/core/hooks';
import style from './Form.module.scss';
import { addMessage } from '../../modules/core/slice';
import { useDispatch } from '../../store';

const Form: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('components');
  const [radioValue, setRadioValue] = useState('success');
  const [textAreaValue, setTextAreaValue] = useState('');
  const addNotification = useAddNotification();
  function onRadioChange(event: ChangeEvent<HTMLInputElement>) {
    setRadioValue(event.target.value);
  }
  function onButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setRadioValue('success');
    setTextAreaValue('');
    if (textAreaValue) {
      addNotification(textAreaValue, radioValue);
    } else {
      dispatch(addMessage({ id: nanoid(), message: t('Notifications text is required'), type: 'error' }));
    }
  }

  return (
    <form className={style.form}>
      <span>Send notification</span>
      <label htmlFor="type">
        <input type="radio" name="type" value="success" checked={radioValue === 'success'} onChange={onRadioChange} />
        {t('Success')}
      </label>
      <label htmlFor="type">
        <input type="radio" name="type" value="warning" checked={radioValue === 'warning'} onChange={onRadioChange} />
        {t('Warning')}
      </label>
      <textarea
        name="text"
        cols={10}
        rows={5}
        placeholder={t('Write your message here')}
        value={textAreaValue}
        onChange={(event) => setTextAreaValue(event.target.value)}
        required
      />
      <button type="button" onClick={onButtonClick}>
        {t('Send')}
      </button>
    </form>
  );
};

export default Form;
