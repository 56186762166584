import React, { useMemo } from 'react';
import { Table as AntdTable } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cx from 'classnames';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import SimpleBar from 'simplebar-react'; // BEST One
// import 'simplebar-react/dist/simplebar.min.css';
import getProcessedColumns from './columns/getProcessedColumns';
import style from './Table.module.scss';
import { Column, TableProps } from './types';

/*
type TableProps = {
  className: string;
  children: React.ReactChildren;
};

const components = {
  table(props: TableProps) {
    console.log(props);
    const { className, children } = props;
    return (
      <SimpleBar style={{ maxHeight: 300 }}>
        <table style={{ width: 1200 }} className={className}>
          {children}
        </table>
      </SimpleBar>
    );
  },
};
*/

const Table = <T extends Record<string, unknown>>({ size, data, columns, height, className, width, fetching = false }: TableProps<T>): JSX.Element => {
  const processedColumns = useMemo(() => getProcessedColumns(data, columns as Column<T>[]), [data, columns]);
  const scroll = useMemo(() => ({ y: height as string, ...(width ? { x: width } : {}) }), [width, height]);

  return (
    <AntdTable
      className={cx(style.table, className)}
      columns={(fetching ? columns : processedColumns) as ColumnsType<T>}
      dataSource={fetching ? undefined : data}
      scroll={scroll}
      pagination={false}
      size={size}
      rowKey="id"
      // components={components} // TODO check if can be used
      loading={fetching}
    />
  );
};

export default Table;
