import React, { useState } from 'react';
import style from '../TournamentDashboardKPIs.module.scss';
import UsageTableCard from './UsageTableCard';
import CumulativeUsageChartCard from './CumulativeUsageChartCard';
import UsageCategoriesChartCard from './UsageCategoriesChartCard';

const UsageTournamentKPIs: React.FC = () => {
  const [selectedData, setSelectedData] = useState('7');
  const [selectedRow, setSelectedRow] = useState('111');

  return (
    <div className={style.container}>
      <UsageTableCard setSelectedData={setSelectedData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} selectedData={selectedData} />
      <div className="row">
        <div className="c-l-xs-12 col-xl-4">
          <UsageCategoriesChartCard selectedData={selectedData} selectedRow={selectedRow} />
        </div>
        <div className="c-l-xs-12 col-xl-8">
          <CumulativeUsageChartCard selectedData={selectedData} selectedRow={selectedRow} />
        </div>
      </div>
    </div>
  );
};

export default UsageTournamentKPIs;
