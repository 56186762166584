import React, { useCallback, useMemo, useState } from 'react';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from '../../store';
import { fetchingLoadEventsTasks, removeEventsTasks, selectTaskEventsByClubMappedFiltered, sendingEventsTasks } from '../../modules/taskPlanner/slice';
import { Column } from '../Table/types';
import RemoveModal from '../RemoveModal/RemoveModal';
import { getActiveClubId } from '../../modules/core/selectors';
import { addMessage } from '../../modules/core/slice';
import MowingModal from '../MowingModal';
import IrrigationModal from '../IrrigationModal';
import { TaskActionType } from '../TasksMenu/types';

type Props = {
  taskStatus?: number[];
};

const modalByType = {
  mowing: MowingModal,
  irrigation: IrrigationModal,
};
const EventsTasksTable: React.FC<Props> = ({ taskStatus = [0, 1, 2, 4] }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('task');
  const clubId = useSelector(getActiveClubId);
  const data = useSelector(selectTaskEventsByClubMappedFiltered)({ taskStatus: { condition: 'or', values: taskStatus } });
  const fetching = useSelector(fetchingLoadEventsTasks);
  const sending = useSelector(sendingEventsTasks);
  const [targetTask, setTargetTask] = useState<{ taskId: number; taskType: string; target: string } | undefined>();

  const Modal = targetTask ? modalByType[targetTask.taskType.toLowerCase() as keyof typeof modalByType] : null;

  const onCancel = useCallback(() => {
    setTargetTask(undefined);
  }, []);

  const onConfirm = useCallback(async () => {
    if (targetTask) {
      try {
        await dispatch(removeEventsTasks({ ...targetTask, clubId })).then(unwrapResult);
        dispatch(addMessage({ id: nanoid(), message: t('Task was successfully removed'), type: 'success' }));
        setTargetTask(undefined);
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }
  }, [targetTask, dispatch, clubId, t]);

  const handleRemove = useCallback((task: { taskId: number; taskType: string }) => {
    setTargetTask({ ...task, target: 'remove' });
  }, []);

  const handleView = useCallback((task: { taskId: number; taskType: string }) => {
    setTargetTask({ ...task, target: 'view' });
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          title: t('Title'),
          dataIndex: 'titleMapped',
          dataType: 'string',
          fixed: 'left',
        },
        {
          title: t('Due'),
          dataIndex: 'eventEnd',
          dataType: 'date',
        },
        {
          title: t('Status'),
          dataIndex: 'taskStatusMapped',
          checkIndex: 'taskStatus',
          dataType: 'statusText',
        },
        {
          title: t('Type'),
          dataIndex: 'eventTypeMapped',
        },
        {
          title: t('Pitch'),
          dataIndex: 'pitch',
        },
        {
          title: t('Site'),
          dataIndex: 'site',
        },
        /*      {
        title: t('Assignee'),
        dataIndex: 'assignee',
      },
      */
        {
          title: t('Action'),
          key: 'action',
          dataType: 'actions',
          fixed: 'right',
          width: 190,
          actions: {
            handleView,
            handleRemove,
          },
        },
      ] as Column<Record<string, unknown>>[],
    [t, handleView, handleRemove],
  );

  return (
    <>
      <Table columns={columns} data={data} height={690} width={1200} fetching={fetching} />
      <RemoveModal open={targetTask?.target === 'remove'} title={t('Remove Task')} confirmLoading={sending} onCancel={onCancel} onConfirm={onConfirm}>
        <div>{t('Are you sure you want to remove this task?')}</div>
        <div>{t('This action cannot be undone.')}</div>
      </RemoveModal>
      {Modal && <Modal isOpen={targetTask?.target === 'view'} onClose={onCancel} type={TaskActionType.EDIT} taskId={targetTask?.taskId} />}
    </>
  );
};

export default EventsTasksTable;
