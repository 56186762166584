import { DatePicker as AntDatePicker } from 'antd';
import React from 'react';
import moment, { Moment } from 'moment';

type Props = {
  onChange: (date: Moment | null) => void;
  value: string;
  allowClear: boolean;
};

const DatePicker: React.FC<Props> = ({ onChange, value, allowClear }: Props) => {
  return <AntDatePicker onChange={onChange} value={moment(value)} allowClear={allowClear} />;
};

export default DatePicker;
