import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import userManager from '../../user-manager';
import { loadUserSuccess } from '../../modules/user/slice';
import { useDispatch, useSelector } from '../../store';
import { User } from '../../modules/user/types';
import { addError, initCore, saveLastUser, selectLastUser } from '../../modules/core/slice';
import LoadingScreen from '../LoadingScreen';

export function LoginCallback(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastUser = useSelector(selectLastUser);
  const { t } = useTranslation();

  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then(async (user) => {
        dispatch(loadUserSuccess((user as unknown) as User));
        if (user?.profile?.email && lastUser !== user?.profile?.email) {
          await dispatch(initCore());
        }
        await dispatch(saveLastUser(user?.profile?.email || ''));
        history.push('/');
      })
      .catch((error) => {
        dispatch(
          addError({
            id: nanoid(),
            error: error.toString(),
          }),
        );
      });
  }, [history, dispatch]); // eslint-disable-line

  return <LoadingScreen message={t('Redirecting')} />;
}

export default LoginCallback;
