import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { TranslateState, TranslationData } from './types';
import api from './api';

const translationAdapter = createEntityAdapter({
  selectId: (translation: TranslationData) => translation.id,
});

export const loadTranslation = createAsyncThunk('translate/loadTranslation', async (payload: { clubId: string; content: string; targetLanguage: string }) =>
  api.loadTranslation(payload),
);

const initialState: TranslateState = {
  fetching: {},
  translations: translationAdapter.getInitialState(),
};

export const slice = createSlice({
  name: 'translate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTranslation.pending, (state) => {
      state.fetching.translation = true;
    });
    builder.addCase(loadTranslation.fulfilled, (state, action) => {
      state.fetching.loadTranslation = false;
      state.translations = translationAdapter.upsertOne(state.translations, { [action.meta.arg.targetLanguage]: action.payload, id: action.meta.arg.content });
    });
    builder.addCase(loadTranslation.rejected, (state) => {
      state.fetching.loadTranslation = false;
    });
  },
});

export const { actions } = slice;

export default slice.reducer;
