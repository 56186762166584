import React from 'react';
import { useTranslation } from 'react-i18next';
import CardShadowWithButtons from '../CardShadow';
import VenuesTable from '../VenuesTable';

const VenuesTableCard: React.FC = () => {
  const { t } = useTranslation('tournamentDashboard');
  return (
    <CardShadowWithButtons title={t('Venues')} icon="fe fe-message-grid" noButtons>
      <VenuesTable />
    </CardShadowWithButtons>
  );
};

export default VenuesTableCard;
