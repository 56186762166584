import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { ColumnProps } from 'antd/lib/table';
import { CustomColumnProps } from '../types';

function getFileIconsColumn<T extends Record<string, unknown>>(data: T[], dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  const columnData: string[] = [];
  // TODO change loop
  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    const columnItem = (item[dataIndex] as unknown) as string;
    if (columnItem && columnItem !== '-' && !columnData.includes(columnItem)) {
      columnData.push(columnItem);
    }
  }
  return {
    filters: columnData.sort((a, b) => a.localeCompare(b)).map((item: string) => ({ text: item, value: item })),
    filterMultiple: true,
    onFilter: (value: string | number | boolean, record: T) => ((record[dataIndex] as unknown) as string).indexOf(value as string) === 0,
    sorter: (a, b) => ((a[dataIndex] as unknown) as string).localeCompare((b[dataIndex] as unknown) as string),
    sortDirections: ['descend', 'ascend'],

    render: (text) => (
      <div style={{ width: 30, height: 30 }}>
        <FileIcon extension={text} {...defaultStyles[text as keyof typeof defaultStyles]} />
      </div>
    ),
  };
}

export default getFileIconsColumn;
