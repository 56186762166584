import React from 'react';
import PageLayout from 'components/PageLayout';
import NavTabs from 'components/NavTabs';
import TableCard from 'components/Dashboard/TableCard';
import { useTranslation } from 'react-i18next';
import CardAgileBoard from 'components/AgileBoard/CardAgileBoard';
import CardCalendar from 'components/Calendar/CardCalendar';
import CardTasksBoard from 'components/TasksBoard';
import CardTeamChart from '../TeamChart/CardTeamChart';

// TODO move to the APP or index
export const LanguageContext = React.createContext('en');

const TaskPlanner: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('taskPlanner');

  const content = [
    { title: t('List'), content: <TableCard />, key: 'list' },
    { title: t('Board'), content: <CardAgileBoard />, key: 'board' },
    { title: t('Teams'), content: <CardTeamChart />, key: 'teams' },
    { title: t('Calendar'), content: <CardCalendar />, key: 'calendar' },
    {
      title: t('Tasks'),
      content: (
        <LanguageContext.Provider value={language}>
          <CardTasksBoard />
        </LanguageContext.Provider>
      ),
      key: 'tasks',
    },
  ];

  return (
    <PageLayout>
      <NavTabs content={content} url="task-planner" className="vb-tabs-bold" />
    </PageLayout>
  );
};

export default TaskPlanner;
