import { shuffle } from 'lodash';
import React, { useMemo } from 'react';
import UsageTable from './UsageTable';
import { useSelector } from '../../../../store';
import { selectUsageTableAll } from '../../../../modules/tournamentDashboardKPIs/slice';

type Props = {
  selectedData: string;
  selectedRow: string;
  setSelectedData: (data: string) => void;
};

export default function UsageTableIndex({ selectedData, selectedRow, setSelectedData }: Props): JSX.Element {
  const data = useSelector(selectUsageTableAll);
  const shuffledData = useMemo(() => {
    return shuffle(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, data]);
  return <UsageTable data={shuffledData} setSelectedData={setSelectedData} selectedData={selectedRow} />;
}
