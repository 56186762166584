import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../PageLayout';
import NavTabs from '../NavTabs';
import TestTournamentKPIs from './TestTournamentKPIs';
import MaintenanceTournamentKPIs from './MaintenanceTournamentKPIs/MaintenanceTournamentKPIs';
import UsageTournamentKPIs from './UsageTournamentKPIs';
import { MenuLeftType } from '../PageLayout/types';

const TournamentDashboardKPIs: React.FC = () => {
  const { t } = useTranslation('tournamentDashboardKPI');
  // TODO open active tab from URL
  const content = [
    { title: t('Tests'), content: <TestTournamentKPIs />, key: 'tests' },
    { title: t('Maintenance'), content: <MaintenanceTournamentKPIs />, key: 'maintenance' },
    { title: t('Usage'), content: <UsageTournamentKPIs />, key: 'usage' },
  ];

  return (
    <PageLayout menuLeftType={MenuLeftType.TOURNAMENT}>
      <NavTabs content={content} url="tournament-dashboard-kpi" className="vb-tabs-bold" />
    </PageLayout>
  );
};

export default TournamentDashboardKPIs;
