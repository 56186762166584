import { createAsyncThunk, createEntityAdapter, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { CoreModuleState, Error, Message, Notification, UserNotification } from './types';
import { State } from '../../store';
import api from './api';
import settings, { Languages } from '../../settings';

const { supportedLanguages }: { supportedLanguages: Languages } = settings;
const language = Object.keys(supportedLanguages).find((alias) => supportedLanguages[alias].default) || Object.keys(supportedLanguages)[0];

export const errorAdapter = createEntityAdapter({
  selectId: (error: Error) => error.id,
});
const messagesAdapter = createEntityAdapter({
  selectId: (message: Message) => message.id,
});
export const notificationsAdapter = createEntityAdapter({
  selectId: (notification: Notification) => notification.id,
});
export const userNotificationsAdapter = createEntityAdapter({
  selectId: (notification: UserNotification) => notification.id,
});

export const loadUserNotifications = createAsyncThunk('core/loadUserNotifications', async () => api.loadUserNotifications());

const initialState: CoreModuleState = {
  fetching: {},
  isAuthorized: false,
  userNotifications: userNotificationsAdapter.getInitialState(),
  notifications: notificationsAdapter.getInitialState(),
  errors: errorAdapter.getInitialState(),
  messages: messagesAdapter.getInitialState(),
  language,
  lastUser: '',
  activeClub: '',
  activeTournament: '',
  theme: {
    logo: 'GrasPro',
    version: 'fluent',
    theme: 'default',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    isPreselectedOpen: false,
    preselectedVariant: 'default',
    menuLayoutType: 'left',
    routerAnimation: 'slide-fadein-up',
    menuColor: 'gray',
    authPagesColor: 'gray',
    isAuthTopbar: true,
    primaryColor: '#669a32',
    leftMenuWidth: 240,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isMenuBorder: true,
    isTopbarFixed: false,
    isTopbarSeparated: false,
    isGrayTopbar: true,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: true,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
    layoutMenu: 'classic',
    layoutTopbar: 'v1',
    layoutBreadcrumbs: 'v1',
    layoutFooter: 'v1',
    flyoutMenuType: 'default',
    flyoutMenuColor: 'dark',
  },
  menu: {
    selectedKeys: [],
    openedKeys: [],
  },
};

export const slice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    initCore: () => initialState,
    setActiveClub: (state: Draft<CoreModuleState>, action: PayloadAction<string>) => {
      state.activeClub = action.payload;
    },
    setActiveTournament: (state: Draft<CoreModuleState>, action: PayloadAction<string>) => {
      state.activeTournament = action.payload;
    },
    saveLastUser: (state: Draft<CoreModuleState>, action: PayloadAction<string>) => {
      state.lastUser = action.payload;
    },
    setThemeProps: (state: Draft<CoreModuleState>, action) => {
      state.theme = { ...state.theme, ...action.payload };
    },
    setLanguage: (state: Draft<CoreModuleState>, action) => {
      state.language = action.payload;
    },
    setMenuProps: (state: Draft<CoreModuleState>, action) => {
      state.menu = { ...state.menu, ...action.payload };
    },
    setAuthorized: (state: Draft<CoreModuleState>, action) => {
      // TODO
      state.isAuthorized = action.payload;
    },
    addNotification: (state: Draft<CoreModuleState>, action) => {
      state.notifications = notificationsAdapter.addOne(state.notifications, action.payload);
    },
    removeNotification: (state: Draft<CoreModuleState>, action) => {
      state.notifications = notificationsAdapter.removeOne(state.notifications, action.payload);
    },
    addError: (state: Draft<CoreModuleState>, action) => {
      state.errors = errorAdapter.addOne(state.errors, action.payload);
      slice.caseReducers.addMessage(state, { ...action, payload: { id: action.payload.id, message: action.payload.error, type: 'error' } });
    },
    removeError: (state: Draft<CoreModuleState>, action) => {
      state.errors = errorAdapter.removeOne(state.errors, action.payload.id);
    },
    addMessage: (state: Draft<CoreModuleState>, action) => {
      state.messages = messagesAdapter.addOne(state.messages, action.payload);
    },
    removeMessage: (state: Draft<CoreModuleState>, action) => {
      state.messages = messagesAdapter.removeOne(state.messages, action.payload.id);
    },
    removeAllMessages: (state: Draft<CoreModuleState>) => {
      state.messages = messagesAdapter.getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserNotifications.pending, (state) => {
      state.fetching.userNotifications = true;
    });
    builder.addCase(loadUserNotifications.fulfilled, (state, action) => {
      state.fetching.userNotifications = false;
      state.userNotifications = userNotificationsAdapter.setAll(state.userNotifications, action.payload);
    });
    builder.addCase(loadUserNotifications.rejected, (state) => {
      state.fetching.userNotifications = false;
    });
  },
});

export const { selectIds: selectToastNotificationsIds, selectById: selectToastNotificationById } = notificationsAdapter.getSelectors(
  (state: State) => state.core.notifications,
);

export const { selectAll: selectUserNotificationsAll } = userNotificationsAdapter.getSelectors((state: State) => state.core.userNotifications);
export const { selectAll: selectMessagesAll } = messagesAdapter.getSelectors((state: State) => state.core.messages);
export const selectLastUser = (state: State): string => state.core.lastUser;

export const {
  actions,
  actions: {
    setActiveClub,
    setActiveTournament,
    saveLastUser,
    setThemeProps,
    setMenuProps,
    setAuthorized,
    addNotification,
    removeNotification,
    addError,
    removeError,
    addMessage,
    removeMessage,
    removeAllMessages,
    setLanguage,
    initCore,
  },
} = slice;

export default slice.reducer;
