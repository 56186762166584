import React, { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';
import AgileBoard from './AgileBoard';

import { useDispatch, useSelector } from '../../store';
import { loadAgileBoardCards, moveAgileCard } from '../../modules/taskPlanner/slice';
import { selectAgileBoardCardsGrouped } from '../../modules/taskPlanner/selectors';
import { AgileCards } from '../../modules/taskPlanner/types';

export default function AgileBoardIndex(): JSX.Element {
  const dispatch = useDispatch();
  const cards = (useSelector(selectAgileBoardCardsGrouped) as unknown) as AgileCards;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback(
    throttle((card, priority, status) => {
      if (card) {
        dispatch(moveAgileCard({ card, priority, status }));
      }
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadAgileBoardCards());
  }, [dispatch]);

  return <AgileBoard cards={cards} onDrop={onDrop} />;
}
