import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../store';
import settings from '../settings';
import {
  addError,
  // removeError
} from '../modules/core/slice';

const API = settings.API as { [key: string]: string };

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
  'Access-Control-Max-Age': 600,
  'Accept': 'application/json',
  'Expires': '-1',
};

export const getToken = (token: 'access_token' | 'id_token'): string => {
  const state = store.getState();
  return state.user?.user?.[token] || '';
};

interface Request {
  url: string;
  method?: string;
  headers?: { [key: string]: string };
  targetAPI?: string;
  caller: string;
}

interface Rest {
  data?: FormData | Record<string, unknown>;
  params?: unknown;
  responseType?: string;
}

export default async function axiosRequest<T>({
  url,
  method = 'GET',
  headers = {},
  targetAPI = 'SYSTEM_API',
  caller,
  ...rest
}: Request & Partial<Rest>): Promise<T> {
  const axiosConfig = {
    method,
    url: `${API[targetAPI]}${url}`,
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${getToken('access_token')}`,
      ...headers,
    },
    ...rest,
  } as AxiosRequestConfig;

  try {
    /* TODO check for necessary
    store.dispatch(
      removeError({
        id: caller,
      }),
    );
*/
    const response = await axios(axiosConfig);
    if (response?.data !== undefined) {
      return await Promise.resolve(response.data);
    }
    throw new Error('API Error');
  } catch (error) {
    store.dispatch(
      addError({
        id: caller,
        error: error.toString(),
      }),
    );
    return Promise.reject(error);
  }
}
