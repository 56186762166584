import React from 'react';
import classNames from 'classnames';
import { ColumnProps } from 'antd/lib/table';
import { CustomColumnProps } from '../types';
import style from '../Table.module.scss';

function getStatusColumnText<T extends Record<string, unknown>>(data: T[], dataIndex: keyof T, checkIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  const columnData = Array.from(new Set(data.map((row) => row[dataIndex]) as string[]));

  return {
    filters: columnData.sort((a, b) => a.localeCompare(b)).map((item: string) => ({ text: item, value: item })),
    filterMultiple: true,
    onFilter: (value: string | number | boolean, record: T) => ((record[dataIndex] as unknown) as string).indexOf(value as string) === 0,
    sorter: (a, b) => (a[dataIndex] as string).localeCompare(b[dataIndex] as string),
    sortDirections: ['descend', 'ascend'],
    render: (value, record: T) => <span className={classNames(style.table__row_status, style[`table__row_${record[checkIndex]}`])}>{value}</span>,
  };
}

export default getStatusColumnText;
