import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Col, ConfigProvider, DatePicker, Divider, Drawer, Input, Modal, Row, Select, Spin, TimePicker } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { get, noop } from 'lodash';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import ModalTitle from './ModalTitle';
import FormField from '../FormField';
import MultiSelect from '../MultiSelect';
import styles from './MaintenanceTasksModal.module.scss';
import { useDispatch, useSelector } from '../../store';
import { getProfileForForm, isProfileLoadingSelector } from '../../modules/profile/selectors';
import { loadTranslation } from '../../modules/translate/slice';
import { ProfileStatus } from '../../modules/profile/types';
import useLocale from '../../hooks/useLocale';
import { addMessage } from '../../modules/core/slice';
import { getActiveClubId } from '../../modules/core/selectors';

export const timePickerFormat = 'HH:mm';
export type FormElementProps = {
  className: string;
};

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  drawerTitle?: string;
  drawerContent?: FunctionComponent;
  onDeselectFields?: (fieldId: string | number) => void;
  onSelectFields?: (fieldId: string | number) => void;
  onSubmit?: <T>(data: T) => void;
  isSending: boolean;
  children: React.ReactNode;
  context: string;
  okButtonDisabled?: boolean;
};

const MaintenanceTasksModal: React.FC<Props> = ({
  title = 'Modal',
  isOpen = false,
  drawerTitle = '',
  onClose = noop,
  onDeselectFields = noop,
  onSelectFields = noop,
  drawerContent = () => <></>,
  onSubmit = noop,
  children,
  isSending,
  context,
  okButtonDisabled,
}: Props) => {
  const { t } = useTranslation('task');

  const dispatch = useDispatch();
  const { alias: lng } = useLocale();
  const [isLoadingTranslate, setIsLoadingTranslate] = useState(false);
  const clubId = useSelector(getActiveClubId);
  const isProfileLoading = useSelector(isProfileLoadingSelector);
  const isLoading = isProfileLoading || isSending;
  const profile = useSelector(getProfileForForm)(context);
  const [profileOptions, setProfileOptions] = useState(profile);
  useEffect(() => setProfileOptions(profile), [profile, setProfileOptions]);

  const { control, handleSubmit, getValues } = useFormContext();
  const [isModalDrawerShow, setIsModalDrawerShow] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const DrawerContent = drawerContent;

  const onSaveButton = () => {
    formRef?.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
  };

  const handleTranslate = useCallback(async () => {
    const content = get(getValues(), 'description');
    setIsLoadingTranslate(true);
    try {
      const data = await dispatch(loadTranslation({ clubId, content, targetLanguage: lng })).then(unwrapResult);

      if (data.toLowerCase() === 'unable to connect to google translate') {
        dispatch(addMessage({ id: nanoid(), message: t('Unable to connect to google translate'), type: 'error' }));
      }
      if (data.toLowerCase() === 'googletranslateerror') {
        dispatch(addMessage({ id: nanoid(), message: t('Google translate error'), type: 'error' }));
      } else {
        Modal.info({
          content: data,
        });
      }
    } finally {
      setIsLoadingTranslate(false);
    }
  }, [setIsLoadingTranslate, getValues, t, dispatch, lng, clubId]);

  return (
    <>
      <Modal
        title={<ModalTitle title={title} icon iconClick={() => setIsModalDrawerShow(true)} />}
        visible={isOpen}
        width={1100}
        onCancel={onClose}
        cancelButtonProps={{
          className: 'ant-btn-default',
          loading: isSending,
        }}
        okText={t('Save')}
        cancelText={t('Cancel')}
        okButtonProps={{
          onClick: onSaveButton,
          loading: isSending,
          disabled: okButtonDisabled,
        }}
        maskClosable={false}
      >
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Row gutter={16} className="ant-form ant-form-large ant-form-vertical">
            <Col span={9}>
              <Row gutter={16}>
                <Col span={10}>
                  <FormField name="date" label={t('Date')} size="large" className={styles.noMarginField} loading={isLoading}>
                    <Controller
                      control={control}
                      name="date"
                      render={({ field: { value, ...rest } }) => <DatePicker value={moment(value)} {...rest} allowClear={false} />}
                    />
                  </FormField>
                </Col>
                <Col span={14}>
                  <FormField name="time" label={t('Time')} size="large" className={styles.noMarginField} loading={isLoading}>
                    <Controller
                      control={control}
                      name="time"
                      render={({ field: { value, ...rest } }) => (
                        <TimePicker.RangePicker
                          value={value.map((item: string) => moment(item))}
                          {...rest}
                          className={styles.timePicker}
                          format={timePickerFormat}
                          minuteStep={15}
                          allowClear={false}
                          popupClassName={styles.timePickerPopup}
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
            </Col>
            <Col span={15}>
              <Row gutter={16}>
                <Col span={8}>
                  <Controller
                    control={control}
                    name="selectedFields"
                    render={({ field: { value, onChange, name } }) => (
                      <FormField name={name} label={t('Fields')} size="large" className={styles.noMarginField} loading={isLoading}>
                        <MultiSelect
                          value={value}
                          name={name}
                          options={profileOptions.fields}
                          onChange={onChange}
                          onDeselect={onDeselectFields}
                          onSelect={onSelectFields}
                          isNumber
                        />
                      </FormField>
                    )}
                  />
                </Col>
                <Col span={8}>
                  <Controller
                    control={control}
                    name="employees"
                    render={({ field: { value, onChange, name } }) => (
                      <FormField name={name} label={t('Collaborators')} size="large" className={styles.noMarginField} loading={isLoading}>
                        <MultiSelect value={value} name={name} options={profileOptions.employees} onChange={onChange} avatarMode isNumber />
                      </FormField>
                    )}
                  />
                </Col>
                <Col span={8}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { value, name, onChange } }) => (
                      <ConfigProvider renderEmpty={() => <span>{t('No Options')}</span>}>
                        <FormField name={name} label={t('Status')} size="large" className={styles.noMarginField} loading={isLoading}>
                          <Select
                            value={value}
                            onChange={onChange}
                            options={profileOptions.status.map(({ value, label }) => ({
                              label: t(ProfileStatus[label as keyof typeof ProfileStatus]),
                              value,
                            }))}
                            placeholder={`${t('Select')}...`}
                            disabled={isLoading}
                          />
                        </FormField>
                      </ConfigProvider>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />
          {children}

          <div className="ant-form ant-form-large ant-form-vertical">
            <FormField
              name="description"
              label={t('Description')}
              size="large"
              className={cx(styles.descriptionField, styles.noMarginField)}
              labelAddonAfter={
                <>
                  <Spin spinning={isLoadingTranslate} className={styles.translateSpinner} />
                  {!isLoadingTranslate && <button type="button" className={styles.translateButton} onClick={handleTranslate} aria-label="Translate" />}
                </>
              }
            >
              <Controller
                control={control}
                name="description"
                render={({ field }) => <Input.TextArea {...field} disabled={isLoadingTranslate || isLoading} autoSize={{ minRows: 4 }} />}
              />
            </FormField>
          </div>
        </form>
      </Modal>

      <Drawer title={drawerTitle} placement="right" onClose={() => setIsModalDrawerShow(false)} visible={isModalDrawerShow}>
        <DrawerContent />
      </Drawer>
    </>
  );
};

export default MaintenanceTasksModal;
