import React, { useCallback, useMemo } from 'react';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DropdownMenu from './DropdownMenu';
import userManager from '../../user-manager';
import { setAuthorized } from '../../modules/core/slice';
import { useDispatch, useSelector } from '../../store';
import styles from './UserMenu.module.scss';
import { createUserMenuTree } from '../../utilities/helpers';
import { userSelector } from '../../modules/user/selectors';

const UserMenu: React.FC = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const { t } = useTranslation('components.menuTop');
  const onLogout = useCallback(async () => {
    dispatch(setAuthorized(false));
    await userManager.signoutRedirect();
  }, [dispatch]);

  const tournaments = useMemo(() => (user ? createUserMenuTree(user.profile.tournaments) : []), [user]);
  const clubs = useMemo(() => (user ? createUserMenuTree(user.profile.clubs) : []), [user]);

  const menu = (
    <Menu selectable={false}>
      {user && (
        <>
          <Menu.Item>
            <div className={styles.menu__name}>
              <strong>
                {user.profile.given_name} {user.profile.family_name}
              </strong>
            </div>
            <div>{user.profile.email}</div>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {tournaments.length > 0 && (
        <Menu.Item>
          <DropdownMenu title={t('Tournaments')} type="tournaments" data={tournaments} />
        </Menu.Item>
      )}
      {clubs.length > 0 && (
        <Menu.Item>
          <DropdownMenu title={t('Clubs')} type="clubs" data={clubs} />
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <Link to="/q">
          <i className="fe fe-user mr-2" />
          {t('Edit Profile')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Button type="text" className={styles.button__text} onClick={onLogout}>
          <i className="fe fe-log-out mr-2" />
          {t('Logout')}
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default UserMenu;

/*
const menu = (
    <Menu className={style.menu}>
      {data && (
        <Menu.Item>
          <div className={style.menu__name}>
            {data.profile.given_name} {data.profile.family_name}
          </div>
          <div>{data.profile.email}</div>
        </Menu.Item>
      )}
      {tournaments.length > 0 && (
        <Menu.Item className={cx(style.menu__item_dropdown)}>
          <DropdownMenu title={t('common:tournaments')} data={tournaments} selectedEvent={selectedEvent} setSelectedEvent={onSelectTournament} />
        </Menu.Item>
      )}
      {clubs.length > 0 && (
        <Menu.Item className={cx(style.menu__item_dropdown)}>
          <DropdownMenu title={t('common:clubs')} data={clubs} selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
        </Menu.Item>
      )}
      <Menu.Item>
        <Link className={style.menu__link} to="/q">
          <i className={cx(style.menu__icon, 'fe', 'fe-edit')} />
          {t('common:editProfile')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <button type="button" className={style.menu__button} onClick={onLogout}>
          <i className={cx(style.menu__icon, 'fe', 'fe-log-out')} />
          {t('common:logout')}
        </button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={triggerDropdown}>
      <div className={style.dropdown}>
        <div className={cx(style.dropdown__content, className)}>
          <i className={cx(style.dropdown__icon, 'fe', 'fe-user')} />
          {selectedEvent !== '' && <span className={style.dropdown__title}>{selectedEvent}</span>}
        </div>
      </div>
    </Dropdown>
  );
* */
