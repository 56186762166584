import { ColumnProps } from 'antd/lib/table';
import { CustomColumnProps } from '../types';

function getNotFilteredColumn<T extends Record<string, unknown>>(dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  return {
    sorter: (a, b) => {
      if (typeof a[dataIndex] === 'number') {
        return ((a[dataIndex] as unknown) as number) - ((b[dataIndex] as unknown) as number);
      }
      return ((a[dataIndex] as unknown) as string).localeCompare((b[dataIndex] as unknown) as string);
    },
    sortDirections: ['descend', 'ascend'],
  };
}

export default getNotFilteredColumn;
