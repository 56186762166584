import { chain } from 'lodash';
import { ClubEvent } from '../components/UserMenu/DropdownMenu';
import { Club, Tournament } from '../modules/user/types';
import { Filter } from '../modules/taskPlanner/types';

export const valueIsNumber = (value: string): boolean => !(Number.isNaN(Number(value)) && value !== '');

export const createUserMenuTree = (data: Club[] | Tournament[]): ClubEvent[] =>
  chain(data)
    .groupBy('country')
    .mapValues((value, key) => ({
      country: key,
      cities: chain(value)
        .groupBy('city')
        .mapValues((value, key) => ({
          city: key,
          items: value.map((item) => {
            const { clubName, clubId } = item as Club;
            const { tournamentName, tournamentId } = item as Tournament;
            return { label: clubName || tournamentName, value: clubId || tournamentId };
          }),
        }))
        .toArray()
        .value(),
    }))
    .toArray()
    .value();

export const checkFilters = <T>(data: T, filter: Filter): boolean =>
  (Object.keys(filter).reduce((match, key) => {
    if (!match) {
      return match;
    }
    const targetKey = data[key as keyof typeof data];
    if (targetKey === undefined) {
      return false;
    }
    if (filter[key].condition === 'or' && (typeof targetKey === 'string' || typeof targetKey === 'number')) {
      return filter[key].values.includes(targetKey);
    }
    // TODO extend with other conditions
    return false;
  }, true) as unknown) as boolean;
