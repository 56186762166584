import { useMemo } from 'react';
import { Locale } from 'antd/lib/locale-provider';
import settings from '../settings';
import { useSelector } from '../store';
import { getCurrentLanguage } from '../modules/core/selectors';

const { supportedLanguages } = settings;

export default function useLocale(): { title: string; alias: string; lng: string; locale: Locale } {
  const currentLanguage = useSelector(getCurrentLanguage);
  return useMemo(() => {
    const targetLanguage = Object.keys(supportedLanguages).find((language) => language === currentLanguage);
    return targetLanguage ? supportedLanguages[targetLanguage as keyof typeof supportedLanguages] : supportedLanguages.en;
  }, [currentLanguage]);
}
