import React, { useEffect } from 'react';
import TournamentDashboardKPIs from './TournamentDashboardKPIs';

import { useDispatch } from '../../store';
import { loadMaintenanceTable, loadTestTable, loadUsageTable } from '../../modules/tournamentDashboardKPIs/slice';

export default function TournamentDashboardKPIsIndex(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTestTable());
    dispatch(loadMaintenanceTable());
    dispatch(loadUsageTable());
  }, [dispatch]);

  return <TournamentDashboardKPIs />;
}
