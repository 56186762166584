import Blank from '../components/Blank/Blank';
import Dashboard from '../components/Dashboard/Dashboard';
import Settings from '../components/Settings';
import TaskPlanner from '../components/TaskPlanner';
import TournamentDashboard from '../components/TournamentDashboard';
import TournamentDashboardKPIs from '../components/TournamentDashboardKPIs';

import { RoutesModules } from './interface';

const routesModules: RoutesModules = {
  Blank,
  Dashboard,
  Settings,
  TaskPlanner,
  TournamentDashboard,
  TournamentDashboardKPIs,
};

export default routesModules;
