import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { MaintenanceTableData, TestTableData, TournamentDashboardKPIsModuleState, UsageTableData } from './types';
import { State } from '../../store';
import api from './api';

const testTableAdapter = createEntityAdapter({
  selectId: (row: TestTableData) => row.id,
});

const maintenanceTableAdapter = createEntityAdapter({
  selectId: (row: MaintenanceTableData) => row.id,
});

const usageTableAdapter = createEntityAdapter({
  selectId: (row: UsageTableData) => row.id,
});

export const loadTestTable = createAsyncThunk('tournamentDashboardKPIs/loadTestTable', async () => api.loadTestTable());
export const loadMaintenanceTable = createAsyncThunk('tournamentDashboardKPIs/loadMaintenanceTable', async () => api.loadMaintenanceTable());
export const loadUsageTable = createAsyncThunk('tournamentDashboardKPIs/loadUsageTable', async () => api.loadUsageTable());

const initialState: TournamentDashboardKPIsModuleState = {
  fetching: {},
  testTable: testTableAdapter.getInitialState(),
  maintenanceTable: maintenanceTableAdapter.getInitialState(),
  usageTable: usageTableAdapter.getInitialState(),
};

export const slice = createSlice({
  name: 'tournamentDashboardKPIs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTestTable.pending, (state) => {
      state.fetching.testTable = true;
    });
    builder.addCase(loadTestTable.fulfilled, (state, action) => {
      state.fetching.testTable = false;
      state.testTable = testTableAdapter.setAll(state.testTable, action.payload);
    });
    builder.addCase(loadTestTable.rejected, (state) => {
      state.fetching.testTable = false;
    });

    builder.addCase(loadMaintenanceTable.pending, (state) => {
      state.fetching.maintenanceTable = true;
    });
    builder.addCase(loadMaintenanceTable.fulfilled, (state, action) => {
      state.fetching.maintenanceTable = false;
      state.maintenanceTable = maintenanceTableAdapter.setAll(state.maintenanceTable, action.payload);
    });
    builder.addCase(loadMaintenanceTable.rejected, (state) => {
      state.fetching.maintenanceTable = false;
    });

    builder.addCase(loadUsageTable.pending, (state) => {
      state.fetching.usageTable = true;
    });
    builder.addCase(loadUsageTable.fulfilled, (state, action) => {
      state.fetching.usageTable = false;
      state.usageTable = usageTableAdapter.setAll(state.usageTable, action.payload);
    });
    builder.addCase(loadUsageTable.rejected, (state) => {
      state.fetching.usageTable = false;
    });
  },
});

export const { selectAll: selectTestTableAll } = testTableAdapter.getSelectors((state: State) => state.tournamentDashboardKPIs.testTable);
export const { selectAll: selectMaintenanceTableAll } = maintenanceTableAdapter.getSelectors((state: State) => state.tournamentDashboardKPIs.maintenanceTable);
export const { selectAll: selectUsageTableAll } = usageTableAdapter.getSelectors((state: State) => state.tournamentDashboardKPIs.usageTable);

export const { actions } = slice;

export default slice.reducer;
