import React, { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import style from './AgileBoard.module.scss';
import { AgileCard, Status } from '../../modules/taskPlanner/types';
import BoardCard from './BoardCard';

type Props = {
  cards: AgileCard[];
  onDrop: (card: AgileCard, index: number, status: Status) => void;
  extraContent?: ReactNode;
  status: Status;
  title: string;
};

const BoardColumn: React.FC<Props> = ({ onDrop, cards, extraContent, status, title }: Props) => {
  const { t } = useTranslation('taskPlanner');

  const [{ card, isOverCurrent }, drop] = useDrop({
    accept: 'card',
    drop: () => {
      onDrop(card.card, isOverCurrent ? -1 : card.index, status);
    },
    hover: () => {
      onDrop(card.card, isOverCurrent ? -1 : card.index, status);
    },

    collect: (monitor) => ({
      card: monitor.getItem(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <div className="col-lg-3 col-md-6">
      {extraContent}
      <div className={style.column} ref={drop}>
        <span className={style.column__title}>{t(title)}</span>
        <div className={style.column__body}>
          {cards?.map((item, index) => (
            <BoardCard key={item.id} card={item} index={index} onDrop={onDrop} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BoardColumn;
