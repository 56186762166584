import React from 'react';
import { Button, Input, Table } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { Controller, useFormContext } from 'react-hook-form';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import FieldWithTooltipError from 'components/FieldWithTooltipError';
import SelectWithHiddenOptions from '../../SelectWithHiddenOptions';
import styles from './MowingForm.module.scss';

type SelectOptionProp = {
  value: string;
  label: string;
};

type Machine = {
  machine: null | string;
  operator: null | string;
  minutes: number;
  bins: number;
};

type Props = {
  machines: Machine[];
  parentControlName?: string;
  parentControlTabKey: number;
  tabActiveKey: number;
  machinesOptions?: SelectOptionProp[];
  operatorsOptions?: SelectOptionProp[];
  onRowRemove?: (id: number) => void;
  isLoading: boolean;
};

const valueIsNumber = (value: string): boolean => !(Number.isNaN(Number(value)) && value !== '');

const MowingFormMachines: React.FC<Props> = ({
  machines = [],
  parentControlName = '',
  machinesOptions = [],
  operatorsOptions = [],
  onRowRemove = noop,
  isLoading,
  parentControlTabKey,
  tabActiveKey,
}: Props) => {
  const { control } = useFormContext();
  const { t } = useTranslation('task');

  const columns: ColumnsType<Record<string, unknown>> = [
    {
      title: t('Machines'),
      dataIndex: 'machines',
      key: 'machines',
      width: '25%',
      render: (value, record, index) => (
        <Controller
          control={control}
          name={`${parentControlName}machines.${index}.machine`}
          defaultValue={machines[index] ? machines[index].machine : null}
          render={({ field: { value, onChange, name } }) => (
            <FieldWithTooltipError value={value} name={name} tabActiveKey={tabActiveKey} parentControlTabKey={parentControlTabKey} isLoading={isLoading}>
              <SelectWithHiddenOptions
                value={value}
                onChange={onChange}
                name={name}
                options={machinesOptions}
                className="wide"
                showSearch
                //                allowClear
                disabled={isLoading}
              />
            </FieldWithTooltipError>
          )}
        />
      ),
    },
    {
      title: t('Operator'),
      dataIndex: 'operator',
      key: 'operator',

      render: (value, record, index) => (
        <Controller
          control={control}
          name={`${parentControlName}machines.${index}.operator`}
          defaultValue={machines[index] ? machines[index].operator : null}
          render={({ field: { value, onChange, name } }) => (
            <FieldWithTooltipError value={value} name={name} tabActiveKey={tabActiveKey} parentControlTabKey={parentControlTabKey} isLoading={isLoading}>
              <SelectWithHiddenOptions value={value} onChange={onChange} name={name} options={operatorsOptions} className="wide" disabled={isLoading} />
            </FieldWithTooltipError>
          )}
        />
      ),
    },
    {
      title: t('Minutes'),
      dataIndex: 'minutes',
      key: 'minutes',
      width: '15%',
      render: (value, record, index) => (
        <Controller
          control={control}
          name={`${parentControlName}machines.${index}.minutes`}
          defaultValue={machines[index] ? machines[index].minutes : null}
          render={({ field: { value, onChange, name } }) => (
            <FieldWithTooltipError value={value} name={name} tabActiveKey={tabActiveKey} parentControlTabKey={parentControlTabKey} isLoading={isLoading}>
              <Input
                value={value}
                name={name}
                onChange={(e) => {
                  if (valueIsNumber(e.target.value)) {
                    onChange(e);
                  }
                }}
                disabled={isLoading}
              />
            </FieldWithTooltipError>
          )}
        />
      ),
    },
    {
      title: t('Bins'),
      dataIndex: 'bins',
      key: 'bins',
      width: '15%',
      render: (value, record, index) => (
        <Controller
          control={control}
          name={`${parentControlName}machines.${index}.bins`}
          defaultValue={machines[index] ? machines[index].bins : null}
          render={({ field: { value, onChange, name } }) => (
            <FieldWithTooltipError value={value} name={name} tabActiveKey={tabActiveKey} parentControlTabKey={parentControlTabKey} isLoading={isLoading}>
              <Input
                value={value}
                name={name}
                onChange={(e) => {
                  if (valueIsNumber(e.target.value)) {
                    onChange(e);
                  }
                }}
                disabled={isLoading}
              />
            </FieldWithTooltipError>
          )}
        />
      ),
    },
    {
      title: '',
      key: 'remove',
      width: '10%',
      render: (value, record, index) => (
        <Button
          type="link"
          danger
          shape="circle"
          icon={<MinusCircleOutlined />}
          size="small"
          className={styles.tableRowRemoveButton}
          onClick={() => onRowRemove(index)}
        />
      ),
    },
  ];

  return <Table size="small" columns={columns} dataSource={machines} pagination={false} />;
};

export default MowingFormMachines;
