import {
  Mowing,
  MowingCreateTask,
  MowingField,
  MowingMachines,
  MowingWorkLogItem,
  MowingTaskItem,
  Irrigation,
  IrrigationField,
  IrrigationTaskItem,
  IrrigationCreateTask,
} from './types';

const createWorkLogItems = (machines: MowingMachines[] = [], fieldId: number): MowingWorkLogItem[] => {
  return machines.map(
    ({ operator, machine, minutes, bins }: MowingMachines): MowingWorkLogItem => ({
      OperatorId: operator,
      MachineId: machine,
      FieldId: fieldId,
      Minutes: minutes,
      NumberOfUnits: bins,
      UnitOfMeasure: 'Bins',
    }),
  );
};

const createTaskItems = (fields: MowingField[]): MowingTaskItem[] =>
  fields.map(
    ({ areas, direction, height, machines, id }: MowingField): MowingTaskItem => {
      const Areas = areas.map((id: number) => ({ Id: id }));
      const WorkLogItems = createWorkLogItems(machines, Number(id)); // TODO check if number is required
      return {
        Height: height,
        DirectionId: Number(direction), // TODO check if number is required
        FieldId: Number(id), // TODO check if number is required
        TaskType: 'Mowing',
        Areas,
        WorkLogItems,
      };
    },
  );

export const mowingAdapter = ({ description, time, status, fields, employees }: Mowing): MowingCreateTask => {
  const [Start, End] = ((time as unknown) as [Date, Date]).map((date) => date.toISOString());
  const Collaborators = employees.map((id) => ({ Id: id }));
  const TaskItems = createTaskItems(fields);
  return {
    TaskStatus: Number(status),
    Start,
    End,
    Description: description,
    Collaborators,
    TaskItems,
  };
};

export const irrigationAdapter = ({ description, time, status, fields, employees }: Irrigation): IrrigationCreateTask => {
  const [Start, End] = ((time as unknown) as [Date, Date]).map((date) => date.toISOString());
  const Collaborators = employees.map((id) => ({ Id: id }));
  const TaskItems = fields.map(
    ({ areas, amount, id, duration }: IrrigationField): IrrigationTaskItem => {
      const Areas = areas.map((id: number) => ({ Id: id }));
      const [WateringStart, WateringEnd] = ((duration as unknown) as [Date, Date]).map((date) => date.toISOString());
      return {
        Amount: amount,
        FieldId: Number(id), // TODO check if number is required
        TaskType: 'Irrigation',
        Areas,
        WateringStart,
        WateringEnd,
      };
    },
  );
  return {
    TaskStatus: Number(status),
    Start,
    End,
    Description: description,
    Collaborators,
    TaskItems,
  };
};
