import React from 'react';
import DateItem from './DateItem';
import { ImportantDatesData } from '../../modules/tournamentDashboard/types';

type Props = {
  data: ImportantDatesData[];
};

const ImportantDates: React.FC<Props> = ({ data }: Props) => {
  return (
    <ul className="list-unstyled mb-0">
      {data.map((item, index) => (
        <DateItem data={item} key={`${item.title}_${index.toString()}`} />
      ))}
    </ul>
  );
};

export default ImportantDates;
