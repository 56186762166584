import React from 'react';
import { Button, Col, Input, Radio, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormField from '../../FormField';
import styles from './MowingForm.module.scss';
import MowingFormMachines from './MowingFormMachines';
import MultiSelect from '../../MultiSelect';
import { useSelector } from '../../../store';
import { getProfileAreasByFieldIdForForm, getProfileDirections, getProfileForForm } from '../../../modules/profile/selectors';
import { Direction, FieldsData } from '../../../modules/profile/types';
import { valueIsNumber } from '../../../utilities/helpers';

type Props = {
  index: number;
  field: FieldsData;
  isLoading: boolean;
  tabActiveKey: number;
};

type Machine = {
  machine: null | string;
  operator: null | string;
  minutes: number;
  bins: number;
};

const machinesInitialValues = {
  machine: null,
  operator: null,
  minutes: 0,
  bins: 0,
} as Machine;

const MowingForm: React.FC<Props> = ({ index = 0, field, isLoading, tabActiveKey }: Props) => {
  const { t } = useTranslation('task');
  const directionOptions = useSelector(getProfileDirections)('mowing');
  const areas = useSelector(getProfileAreasByFieldIdForForm)(field.id, 'mowing');
  const profile = useSelector(getProfileForForm)('mowing');

  const { control, register } = useFormContext();

  const { fields: machines, append, remove } = useFieldArray({
    control,
    name: `fields.${index}.machines`,
    keyName: 'key',
  });

  const machineRemoveHandler = (id: number) => remove(id);
  const machineAddHandler = () => append({ ...machinesInitialValues });

  return (
    <>
      <input
        type="hidden"
        {...register(`fields.${index}.id`, {
          valueAsNumber: true,
        })}
        value={field.id}
      />
      <Row gutter={32} className={styles.formCols}>
        <Col span={12} className="ant-form ant-form-large">
          <Controller
            control={control}
            name={`fields.${index}.areas`}
            defaultValue={field.areas || []}
            render={({ field: { value, onChange, name } }) => (
              <FormField name={name} label={t('Areas')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 19 }} loading={isLoading}>
                <MultiSelect value={value} name={name} options={areas} onChange={onChange} isNumber />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name={`fields.${index}.height`}
            defaultValue={field.height || 0}
            render={({ field: { value, onChange, name } }) => (
              <FormField name={name} label={t('Height')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 19 }} loading={isLoading}>
                <Input
                  value={value}
                  onChange={(e) => {
                    if (valueIsNumber(e.target.value)) {
                      onChange(e.target.value);
                    }
                  }}
                  name={name}
                  suffix="mm"
                  className="short text-right"
                  readOnly={isLoading}
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name={`fields.${index}.direction`}
            defaultValue={field.direction || directionOptions[0]?.value}
            render={({ field: { value, onChange, name } }) => (
              <FormField name={name} label={t('Direction')} size="large" labelCol={{ span: 5 }} wrappedCol={{ span: 19 }} loading={isLoading}>
                <Radio.Group name={name} value={value} onChange={(e) => onChange(e.target.value)} className={styles.thinRadioGroup}>
                  {directionOptions.map((direction) => (
                    <Radio.Button value={direction.value} key={direction.value}>
                      {t(Direction[direction.name as keyof typeof Direction])}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </FormField>
            )}
          />
        </Col>

        <Col span={12}>
          <div>
            <MowingFormMachines
              machines={(machines as unknown) as Machine[]}
              parentControlName={`fields.${index}.`}
              parentControlTabKey={field.id}
              machinesOptions={profile.machines}
              operatorsOptions={profile.employees}
              onRowRemove={machineRemoveHandler}
              isLoading={isLoading}
              tabActiveKey={tabActiveKey}
            />
            <div className={styles.buttonAddRow}>
              <Button disabled={isLoading} type="dashed" icon={<PlusOutlined />} block onClick={machineAddHandler}>
                {t('Add Row')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MowingForm;
