import { UserManager, UserManagerSettings } from 'oidc-client';

const baseUrl = window.location.origin;
const authority = process.env.REACT_APP_AUTHORITY_URL || '';

const config: UserManagerSettings = {
  client_id: 'react_spa',
  redirect_uri: `${baseUrl}/authentication/callback`,
  response_type: 'code',
  scope: 'openid calendar admin',
  authority,
  post_logout_redirect_uri: baseUrl,
  silent_redirect_uri: `${baseUrl}/authentication/silent-callback`,
  automaticSilentRenew: false,
  loadUserInfo: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
};

const userManager = createUserManager(config);

export default userManager;

function createUserManager(config: UserManagerSettings) {
  return new UserManager(config);
}
