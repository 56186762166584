import axiosRequest from '../../utilities/axiosRequest';
import { UserNotification } from './types';

export async function loadUserNotifications(): Promise<UserNotification[]> {
  return axiosRequest({
    method: 'GET',
    url: `/UserNotifications`,
    caller: 'loadUserNotifications',
    targetAPI: 'MOCKUP_API',
  });
}

export default {
  loadUserNotifications,
};
