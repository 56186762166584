import { noop } from 'lodash';
import { Button, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import style from './ModalTitle.module.scss';

type Props = {
  title: string;
  icon?: boolean;
  iconClick: () => void;
};

const ModalTitle: React.FC<Props> = ({ title = '', icon = false, iconClick = noop }: Props) => (
  <Space size={16} className={style.modalTitle}>
    <strong>{title}</strong>
    {icon && (
      <Button type="text" className={style.titleIconButton} onClick={() => iconClick()}>
        <QuestionCircleOutlined />
      </Button>
    )}
  </Space>
);

export default ModalTitle;
