import React from 'react';

import ImagesGallery from './ImagesGallery';
import { useSelector } from '../../store';
import { imagesSelector } from '../../modules/dashboard/selectors';

export default function ImagesGalleryIndex(): JSX.Element {
  const data = useSelector(imagesSelector);
  return <ImagesGallery data={data} />;
}
