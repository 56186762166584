import React, { useState, useEffect, Key, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'store';
import { isEmpty } from 'lodash';
import logo from '../../../images/graspro_logo_horizontal_rgb.svg';
import sign from '../../../images/graspro_sign_rgb.svg';
import { Category, MenuLeftProps, MenuSettings } from '../types';
import { getMenuOpenedKeys, getMenuSelectedKeys, themeSettingsSelector } from '../../../modules/core/selectors';
import { setMenuProps, setThemeProps } from '../../../modules/core/slice';
import style from './MenuLeftClassic.module.scss';

const MenuLeftClassic: React.FC<MenuLeftProps> = ({ menuData = [] }: MenuLeftProps) => {
  const dispatch = useDispatch();
  const { isMenuCollapsed, isMobileView, isMenuUnfixed, isMenuShadow, leftMenuWidth, menuColor } = useSelector(themeSettingsSelector);
  const storedSelectedKeys = useSelector(getMenuSelectedKeys);
  const storedOpenedKeys = useSelector(getMenuOpenedKeys);
  const [selectedKeys, setSelectedKeys] = useState(storedSelectedKeys);
  const [openedKeys, setOpenedKeys] = useState(storedOpenedKeys);
  const { params, path } = useRouteMatch();

  const pathname = useMemo(
    () =>
      Object.keys(params).reduce((partPath, key) => {
        return partPath.split(`/:${key}`).join('');
      }, path),
    [params, path],
  );

  useEffect(() => {
    if (isEmpty(menuData)) {
      return;
    }
    const checkItems = (items: Category[], active: string[]): string[] => {
      return items.reduce((activeIn, item) => {
        if (item.url === pathname) {
          return [item.id];
        }
        if (item.children) {
          return checkItems(item.children, activeIn);
        }
        return activeIn;
      }, active);
    };

    setSelectedKeys(
      menuData.reduce((active, group) => {
        return checkItems(group.items, active);
      }, [] as string[]),
    );
  }, [pathname, menuData]);

  const onCollapse = (value: boolean, type: string) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }
    dispatch(
      setThemeProps({
        isMenuCollapsed: !isMenuCollapsed,
      }),
    );

    setOpenedKeys([]);
  };

  const onOpenChange = (keys: Key[]) => {
    dispatch(setMenuProps({ openedKeys: keys }));
    setOpenedKeys(keys as string[]);
  };

  const handleClick = (info: { key: Key }) => {
    dispatch(setMenuProps({ selectedKeys: [info.key] }));
    setSelectedKeys([info.key] as string[]);
  };

  const generateMenuItems = () => {
    const generateItem = (item: Category) => {
      const { id, title, url, icon, disabled, count } = item;

      if (item.url) {
        return (
          <Menu.Item key={id} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`fe ${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url || '#'}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`fe ${icon} ${style.icon} icon-collapsed-hidden`} />}
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={id} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`fe ${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      );
    };

    const generateSubmenu = (items: Category[]) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.id}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.id}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map((menuGroup) => {
      return (
        <React.Fragment key={`${menuGroup.title}_${menuGroup.items.length}`}>
          {menuGroup.title && <Menu.ItemGroup key={menuGroup.title} title={menuGroup.title} />}
          {menuGroup.items.map((menuItem) => {
            if (menuItem.children) {
              const subMenuTitle = (
                <span key={menuItem.id}>
                  <span className={style.title}>{menuItem.title}</span>
                  {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
                  {menuItem.icon && <span className={`fe ${menuItem.icon} ${style.icon}`} />}
                </span>
              );
              return (
                <Menu.SubMenu title={subMenuTitle} key={menuItem.id}>
                  {generateSubmenu(menuItem.children)}
                </Menu.SubMenu>
              );
            }
            return generateItem(menuItem);
          })}
        </React.Fragment>
      );
    });
  };

  const menuSettings: MenuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classnames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={classnames(style.logoContainer, isMenuCollapsed && style.collapsed)}>
          <div className={style.logo}>
            <Link to="/">
              <img src={isMenuCollapsed ? sign : logo} alt="GrasPro" />
            </Link>
          </div>
        </div>
        <div className={style.menuHolder}>
          <div className={style.menuContent}>
            <Menu
              onClick={handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className={style.navigation}
              inlineIndent={15}
            >
              {generateMenuItems()}
            </Menu>
          </div>
        </div>
      </div>
    </Layout.Sider>
  );
};

export default MenuLeftClassic;
