import React, { useMemo } from 'react';
import ICalendarLink from 'react-icalendar-link';
import style from './ImportantDates.module.scss';
import { getFullDate } from '../../utilities/getRelativeDate';
import { ImportantDatesData } from '../../modules/tournamentDashboard/types';
import fileIcon from '../../images/ics-file-format.png';

type Props = {
  data: ImportantDatesData;
};

const DateItem: React.FC<Props> = ({ data }: Props) => {
  const event = useMemo(() => {
    return {
      title: data.title,
      description: data.message,
      startTime: data.startDate,
      endTime: data.endDate,
      location: '',
    };
  }, [data]);

  return (
    <li className={style.item}>
      <div className={`${style.itemMeta} font-weight-bold text-success`}>{getFullDate(data.startDate)}</div>
      <div className="mr-3">
        <div>{data.title}</div>
        <div className="text-muted">{data.message}</div>
      </div>
      <div className={style.itemAction}>
        <ICalendarLink event={event} filename={`${event.title}.ics`}>
          <img src={fileIcon} alt="ics-file" className={style.item__icon} />
        </ICalendarLink>
      </div>
    </li>
  );
};

export default DateItem;
