import React from 'react';
import Table from 'components/Table/Table';
import { PitchTestsTableData } from 'modules/dashboard/types';
import { Column } from '../Table/types';

const columns = [
  {
    title: 'Pitch',
    dataIndex: 'pitch',
    fixed: 'left',
  },
  {
    title: 'Date of Test',
    dataIndex: 'date',
    dataType: 'date',
  },
  {
    title: 'Avg. Moisture',
    dataIndex: 'moisture',
    dataType: 'number',
  },
  {
    title: 'Moisture Trend',
    dataIndex: 'moistureTrend',
    dataType: 'chart',
  },
  {
    title: 'Avg. Traction',
    dataIndex: 'traction',
    dataType: 'number',
  },
  {
    title: 'Traction Trend',
    dataIndex: 'tractionTrend',
    dataType: 'chart',
  },
  {
    title: 'Avg. Firmness',
    dataIndex: 'firmness',
    dataType: 'number',
  },
  {
    title: 'Firmness Trend',
    dataIndex: 'firmnessTrend',
    dataType: 'chart',
  },
] as Column<Record<string, unknown>>[];

type Props = {
  data: PitchTestsTableData[];
};

const PitchTestsTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} data={data} height={265} width={1200} />
    </div>
  );
};

export default PitchTestsTable;
