import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import style from '../Table.module.scss';
import FilterDropdown from '../FilterDropdown';
import { CustomColumnProps } from '../types';

function getSearchColumn<T extends Record<string, unknown>>(dataIndex: keyof T): Pick<ColumnProps<T>, CustomColumnProps> {
  return {
    filterDropdown: (...props) => FilterDropdown(dataIndex, ...props),
    filterIcon: () => <SearchOutlined className={style.searchIcon} />,
    onFilter: (value: string | number | boolean, record: T) => {
      const target = record[dataIndex] as { title: string };
      return typeof value === 'string' || typeof value === 'number' ? target.title.toLowerCase().includes(value.toString().toLowerCase()) : false;
    },
    sorter: (a, b) => {
      const targetA = a[dataIndex] as { title: string };
      const targetB = b[dataIndex] as { title: string };
      return targetA.title.localeCompare(targetB.title);
    },
    sortDirections: ['descend', 'ascend'],
    render: ({ link, title }: { link: string; title: string }) => (
      <Link to={link} className={style.column__link}>
        {title}
      </Link>
    ),
  };
}

export default getSearchColumn;
