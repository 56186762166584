import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table/Table';
import { MediaTableData } from 'modules/tournamentDashboard/types';
import style from '../Table/Table.module.scss';
import { Column } from '../Table/types';

type Props = {
  data: MediaTableData[];
};

const MediaTable: React.FC<Props> = ({ data }: Props) => {
  const { t } = useTranslation('table.mediaTable');

  const columns = useMemo(
    () =>
      [
        {
          title: t('File name'),
          dataIndex: 'fileName',
          dataType: 'string',
        },
        {
          title: t('File format'),
          dataIndex: 'fileFormat',
          dataType: 'file',
        },
        {
          title: t('Size'),
          dataIndex: 'size',
          dataType: 'not filtered',
        },
        {
          title: t('Uploaded'),
          dataIndex: 'uploaded',
          dataType: 'date',
        },
        {
          title: t('User'),
          dataIndex: 'user',
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} data={data} height={317} className={style.table_tournament} size="middle" />
    </div>
  );
};

export default MediaTable;
