import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import style from '../MaintenanceTournamentKPIs.module.scss';
import { MaintenanceTableData } from '../../../../modules/tournamentDashboardKPIs/types';
import getProcessedColumns from '../../../Table/columns/getProcessedColumns';
import { Column } from '../../../Table/types';

// TODO open selected tab on load

type Props = {
  data: MaintenanceTableData[];
  selectedRow: string;
  setSelectedRow: (data: string) => void;
};

const MaintenanceTable: React.FC<Props> = ({ data, selectedRow, setSelectedRow }: Props) => {
  const { t } = useTranslation('table.maintenanceTable');
  const columns = useMemo(
    () =>
      [
        {
          title: t('Pitch'),
          dataIndex: 'pitch',
          dataType: 'string',
          width: 270,
        },
        {
          title: t('City'),
          dataIndex: 'city',
        },
        {
          title: t('Usage'),
          dataIndex: 'usage',
        },
        {
          title: t('Surface'),
          dataIndex: 'surface',
        },
        {
          title: t('Tasks'),
          dataIndex: 'tasks',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Hours of usage'),
          dataIndex: 'hoursOfUsage',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Working hours'),
          dataIndex: 'workingHours',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Pitch marking'),
          dataIndex: 'pitchMarking',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Irrigation (mm)'),
          dataIndex: 'irrigation',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Rainfall (mm)'),
          dataIndex: 'rainfall',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Mowing'),
          dataIndex: 'mowing',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Pitch Repairs'),
          dataIndex: 'pitchRepairs',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Fertilization'),
          dataIndex: 'fertilization',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Photos'),
          dataIndex: 'photos',
          dataType: 'not filtered',
          align: 'center',
        },
        {
          title: t('Comments'),
          dataIndex: 'comments',
          dataType: 'not filtered',
          align: 'center',
        },
      ] as Column<Record<string, unknown>>[],
    [t],
  );

  const processedColumns = useMemo(() => {
    return getProcessedColumns(data, columns);
  }, [data, columns]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        className={style.table_full}
        rowClassName={(record) => (record.id === selectedRow ? style.row_selected : '')}
        columns={processedColumns}
        dataSource={data}
        bordered
        pagination={false}
        size="middle"
        rowKey="id"
        scroll={{ y: 256 }}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedRow(record.id as string);
            },
          };
        }}
        summary={(pageData) => {
          let totalTasks = 0;
          let totalPitchMarking = 0;
          let totalIrrigation = 0;
          let totalRainfall = 0;
          let totalMowing = 0;
          let totalPitchRepairs = 0;
          let totalFertilization = 0;
          let totalComments = 0;
          let totalPhotos = 0;

          pageData.forEach((props) => {
            const numbers = props as Record<string, number>;
            totalTasks += numbers.tasks;
            totalPitchMarking += numbers.pitchMarking;
            totalIrrigation += numbers.irrigation;
            totalRainfall += numbers.rainfall;
            totalMowing += numbers.mowing;
            totalPitchRepairs += numbers.pitchRepairs;
            totalFertilization += numbers.fertilization;
            totalComments += numbers.comments;
            totalPhotos += numbers.photos;
          });

          return (
            <>
              <Table.Summary.Row className={style.row_sum}>
                <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={3} />
                <Table.Summary.Cell index={2}>{totalTasks}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>365:34</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>453:56</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{totalPitchMarking}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{totalIrrigation}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{totalRainfall}</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>{totalMowing}</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>{totalPitchRepairs}</Table.Summary.Cell>
                <Table.Summary.Cell index={10}>{totalFertilization}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{totalComments}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{totalPhotos}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default MaintenanceTable;
