import React from 'react';
import WorkLogTable from './WorkLogTable';
import { useSelector } from '../../../../store';
import { selectTaskEventsByClubMappedFiltered } from '../../../../modules/taskPlanner/slice';

type Props = {
  selectedData: string;
  selectedRow: string;
};

export default function WorkLogTableIndex({ selectedData, selectedRow }: Props): JSX.Element {
  // TODO check what it for
  console.log(selectedData, selectedRow); // eslint-disable-line
  const data = useSelector(selectTaskEventsByClubMappedFiltered)({ taskStatus: { condition: 'or', values: [3] } });

  return <WorkLogTable data={data} />;
}
