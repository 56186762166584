import React, { ReactNode } from 'react';
import classnames from 'classnames';
import CardHeader from '../CardHeader/CardHeader';
import TasksMenu from '../TasksMenu/TasksMenu';
import styles from './CardShadowWithButtons.module.scss';

type Props = {
  icon?: string;
  title?: string;
  children: ReactNode | JSX.Element;
  noButtons?: boolean;
  customHeader?: JSX.Element;
  noHeader?: boolean;
};
const CardShadowWithButtons: React.FC<Props> = ({ icon, title, children, noButtons = false, customHeader, noHeader = false }: Props) => {
  return (
    <div className={classnames('card', styles.card)}>
      {customHeader && !noHeader && <div className="card-header">{customHeader}</div>}
      {!customHeader && !noHeader && (
        <div className="card-header">
          <CardHeader title={title} icon={icon} {...(!noButtons ? { extraContent: <TasksMenu /> } : {})} />
        </div>
      )}

      <div className="card-body">{children}</div>
    </div>
  );
};

export default CardShadowWithButtons;
