import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { isEmpty } from 'lodash';
import LoginCallback from '../LoginCallback';
import ProtectedRoutes from '../ProtectedRoutes';
import { useDispatch, useSelector } from '../../store';
import { removeAllMessages, selectMessagesAll } from '../../modules/core/slice';
import { MessageDuration } from '../../modules/core/types';
import routes from '../../routes/routes';
import routesModules from '../../routes/modules';
import { RouteType } from '../../routes/interface';
import Blank from '../Blank/Blank';
import useLocale from '../../hooks/useLocale';

const routesList = routes.reduce((partRoutes, route) => {
  if (route.module && route.path) {
    partRoutes.push({ ...route });
  }
  if (route.children) {
    partRoutes = [...partRoutes, ...route.children.map((item) => ({ ...item, section: route.section }))];
  }
  return partRoutes;
}, [] as RouteType[]);

const App: React.FC = () => {
  // useColorScheme();
  const dispatch = useDispatch();
  const messages = useSelector(selectMessagesAll);
  const { locale } = useLocale();

  useEffect(() => {
    messages.forEach((item) => {
      message[item.type](item.message, MessageDuration[item.type]);
    });
    if (!isEmpty(messages)) dispatch(removeAllMessages());
  }, [dispatch, messages]);

  return (
    <ConfigProvider locale={locale}>
      <Router>
        <Switch>
          <Route
            path="/authentication"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/callback`} component={LoginCallback} />
              </>
            )}
          />
          {routesList.map((route) => {
            const Module = route.module && routesModules[route.module] ? routesModules[route.module] : Blank;
            /*
<ProtectedRoutes>
            <Switch>
              <Route path="/settings">
                <Suspense fallback={null}>
 */
            return (
              <Route
                key={`route_${route.path}`}
                exact={route.exact}
                path={route.path}
                render={(props): JSX.Element => (
                  <ProtectedRoutes>
                    <Module route={route} {...props} />
                  </ProtectedRoutes>
                )}
              />
            );
          })}
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default App;
