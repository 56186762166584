import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Weather from '../Weather';
import TabCard from '../TabCard';

const WeatherCard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const content = useMemo(() => {
    return [
      {
        title: t('Anfield'),
        content: <Weather />,
      },
      {
        title: t('Melwood'),
        content: <Weather />,
      },
      {
        title: t('Training site'),
        content: <Weather />,
      },
    ];
  }, [t]);

  return <TabCard icon="fe fe-cloud-drizzle" title={t('Weather')} content={content} />;
};

export default WeatherCard;
