import { DateTime } from 'luxon';
import ordinal from 'ordinal';
import i18n from '../i18n';

export default function getRelativeDate(day: string): string {
  if (!day) {
    return day;
  }
  const { language: locale } = i18n;
  const dateTimeDay = DateTime.fromISO(day);
  const isTime = day.includes('T');
  const time = `${i18n.t('at')} ${dateTimeDay.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
  const currentDay = day.slice(0, 10);
  const weekday = dateTimeDay.setLocale(locale).toLocaleString({ weekday: 'long' });
  const fullDate = `${dateTimeDay.setLocale(locale).toLocaleString({ month: 'short' })} ${
    locale.indexOf('en') === 0 ? ordinal(+day.slice(8, 10)) : +day.slice(8, 10)
  }`;
  const today = DateTime.local().toISODate();
  const tomorrow = DateTime.local().plus({ days: 1 }).toISODate();
  const yesterday = DateTime.local().minus({ days: 1 }).toISODate();
  const nextWeek = DateTime.local().plus({ week: 1 }).toISODate();
  const lastWeek = DateTime.local().minus({ week: 1 }).toISODate();
  if (currentDay === today) {
    return isTime ? `${i18n.t('Today')} ${time}` : i18n.t('Today');
  }
  if (currentDay === tomorrow) {
    return isTime ? `${i18n.t('Tomorrow')} ${time}` : i18n.t('Tomorrow');
  }
  if (currentDay === yesterday) {
    return isTime ? `${i18n.t('Yesterday')} ${time}` : i18n.t('Yesterday');
  }
  if (currentDay < nextWeek && currentDay > lastWeek) {
    return isTime ? `${weekday} ${time}` : weekday;
  }
  return isTime ? `${fullDate} ${time}` : fullDate;
}

export function getFullDateWithWeekday(date: string): string {
  const { language: locale } = i18n;
  const dateTimeDay = DateTime.fromISO(date);
  const weekday = dateTimeDay.setLocale(locale).toLocaleString({ weekday: 'long' });
  const day = locale.indexOf('en') === 0 ? ordinal(+date.slice(8, 10)) : +date.slice(8, 10);
  const fullDate = `${dateTimeDay.setLocale(locale).toLocaleString({ month: 'long' })} ${day}`;
  const year = dateTimeDay.setLocale(locale).toLocaleString({ year: 'numeric' });
  return `${weekday}, ${fullDate} ${year}`;
}

export function getFullDate(date: string): string {
  const { language: locale } = i18n;
  const dateTimeDay = DateTime.fromISO(date);
  const year = dateTimeDay.setLocale(locale).toLocaleString({ year: 'numeric' });
  return `${dateTimeDay.setLocale(locale).toLocaleString({ month: 'short' })} ${ordinal(+date.slice(8, 10))} ${year}`;
}

export function getDurationAfterDate(date: string): string | null {
  const { language: locale } = i18n;
  return DateTime.local().setLocale(locale).toISO().slice(0, 16) === date.slice(0, 16) ? i18n.t('now') : DateTime.fromISO(date).setLocale(locale).toRelative();
}
