import React, { useEffect, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap';

heatmap(Highcharts);

type Props = {
  min: number;
  max: number;
  selectedData: string;
  setPopup: (props: { isOpen: boolean; type: string | null; location: string | null }) => void;
  type: string;
  stops: (string | number)[][];
};

const HeatMap: React.FC<Props> = ({ selectedData, setPopup, type, stops, min, max }: Props): JSX.Element => {
  const [randomData, setRandomData] = useState({ a: '60', b: '70', c: '65', d: '45', e: '50' });
  function getRandomValue() {
    return (Math.random() * (max - min) + min).toFixed(1);
  }

  function getRandomArray() {
    return [getRandomValue(), getRandomValue(), getRandomValue()];
  }

  useEffect(() => {
    setRandomData({
      a: getRandomValue(),
      b: getRandomValue(),
      c: getRandomValue(),
      d: getRandomValue(),
      e: getRandomValue(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const options = useMemo(
    () => ({
      plotOptions: {
        series: {
          events: {
            click: (e: MouseEvent) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setPopup({ isOpen: true, type, location: e.point.name });
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '32px',
              fontWeight: 'normal',
              textOutline: null,
              fontFamily: 'Source Sans Pro',
            },
          },
        },
        heatmap: {
          opacity: 0.8,
        },
      },

      tooltip: {
        useHTML: true,
        pointFormat: '{point.name}: <b>{point.value}</b><br/>{point.readings}',
        formatter() {
          let ret = '';
          ret = ret.concat('<b>');
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          ret = ret.concat(this.point.name);
          ret = ret.concat('</b><br><br>');
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          this.point.readings.forEach((e, i) => {
            ret = ret.concat(`Reading ${i + 1}: ${e}<br>`);
          });
          return ret;
        },
      },

      legend: {
        enabled: false,
      },

      exporting: {
        enabled: false,
      },

      chart: {
        type: 'heatmap',
        plotBackgroundImage: 'https://www.schoolsplus.co.uk/thamesview/wp-content/uploads/sites/25/2014/08/football-pitch.jpg',
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
        margin: 0,
      },

      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },

      yAxis: {
        min: 0,
        tickWidth: 0,
        crosshair: false,
        lineWidth: 0,
        gridLineWidth: 0, // Set this to zero
        maxPadding: 0,
        title: '',
        labels: {
          enabled: false,
        },
      },

      xAxis: {
        min: 0,
        tickWidth: 0,
        crosshair: false,
        lineWidth: 0,
        gridLineWidth: 0, // Set this to zero
        maxPadding: 0,
        title: '',
        labels: {
          enabled: false,
        },
      },

      colorAxis: {
        //    minColor: 'rgb(255, 255, 255)', // white
        //     maxColor: 'rgb(0, 0, 255)',  // blue,
        min: 0,
        max: 100,
        stops,
      },

      series: [
        {
          pointPadding: 5,
          name: type,
          animation: false,
          data: [
            {
              name: 'Location 1',
              x: 0,
              y: 2,
              id: 'A',
              value: randomData.a,
              readings: getRandomArray(),
            },
            {
              name: 'Location 2',
              x: 0,
              y: 1,
              id: 'B',
              value: randomData.b,
              readings: getRandomArray(),
            },
            {
              name: 'Location 3',
              x: 1,
              y: 1,
              id: 'C',
              value: randomData.c,
              readings: getRandomArray(),
            },
            {
              name: 'Location 4',
              x: 2,
              y: 1,
              id: 'E',
              value: randomData.e,
              readings: getRandomArray(),
            },
            {
              name: 'Location 5',
              x: 2,
              y: 0,
              id: 'D',
              value: randomData.d,
              readings: getRandomArray(),
            },
          ],
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [randomData, setPopup, type, stops],
  );

  return (
    <div className="heatmap">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HeatMap;
