import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IrrigationCreateTask, Mowing, MowingCreateTask, MowingState } from './types';
import { State } from '../../store';
import api from './api';

const mowingAdapter = createEntityAdapter({
  selectId: (mowing: Mowing) => mowing.id,
});

export const loadMowingTask = createAsyncThunk('tasks/loadMowingTask', async (payload: { clubId: string; mowingId: number }) => api.loadMowingTask(payload));
export const createMowingTask = createAsyncThunk('tasks/createMowingTask', async (payload: { clubId: string; data: MowingCreateTask }) =>
  api.createMowingTask(payload),
);
export const createIrrigationTask = createAsyncThunk('tasks/createMowingTask', async (payload: { clubId: string; data: IrrigationCreateTask }) =>
  api.createIrrigationTask(payload),
);

export const loadIrrigationTask = createAsyncThunk('tasks/loadIrrigationTask', async (payload: { clubId: string; irrigationId: number }) =>
  api.loadIrrigationTask(payload),
);

const initialState: MowingState = {
  fetching: {},
  sending: {},
  mowing: mowingAdapter.getInitialState(),
  updateCriteria: {
    updateCollaborators: true,
    updateTaskItems: true,
    updateWorkLogs: true,
  },
};

export const slice = createSlice({
  name: 'mowing',
  initialState,
  reducers: {
    resetMowing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadMowingTask.pending, (state) => {
      state.fetching.mowing = true;
    });
    builder.addCase(loadMowingTask.fulfilled, (state, action) => {
      state.fetching.mowing = false;
      // TODO data also should be normalized. Now it will not work
      state.mowing = mowingAdapter.upsertOne(state.mowing, { ...action.payload, id: action.meta.arg.mowingId });
    });
    builder.addCase(loadMowingTask.rejected, (state) => {
      state.fetching.mowing = false;
    });

    builder.addCase(createMowingTask.pending, (state) => {
      state.sending.mowing = true;
    });
    builder.addCase(createMowingTask.fulfilled, (state) => {
      state.sending.mowing = false;
      // TODO do something with payload
    });
    builder.addCase(createMowingTask.rejected, (state) => {
      state.sending.mowing = false;
    });
  },
});

export const { selectById: selectMowingByClubId } = mowingAdapter.getSelectors((state: State) => state.tasks.mowing);

export const {
  actions,
  actions: { resetMowing },
} = slice;

export default slice.reducer;
