import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Checkbox, Input, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { SearchDocumentsData, SearchImagesData } from 'modules/dashboard/types';
import classNames from 'classnames';
import { useDispatch, useSelector } from '../../store';
import { loadSearchDocuments, loadSearchImages, selectFetchingSearch } from '../../modules/dashboard/slice';
import style from './Search.module.scss';

type Props = {
  imagesData: SearchImagesData[];
  documentsData: SearchDocumentsData[];
};

const Search: React.FC<Props> = ({ imagesData, documentsData }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('components.menuTop');
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const fetching = useSelector(selectFetchingSearch);

  useEffect(() => {
    if (location.hash === '#search' && !showSearch) {
      setTimeout(() => {
        setShowSearch(true);
      }, 100);
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 200);
    } else if (location.hash !== '#search' && showSearch) {
      setShowSearch(false);
    }
  }, [location.hash, showSearch]);

  const onInputChange = useCallback(() => {
    if (searchText) {
      dispatch(loadSearchImages({ search: searchText }));
      dispatch(loadSearchDocuments({ search: searchText }));
    }
  }, [dispatch, searchText]);

  const hideLiveSearch = () => {
    searchInputRef.current?.blur();
    setSearchText('');
    history.replace({
      hash: '',
    });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { name } = event.target as HTMLInputElement;
    if (event?.code === 'Escape') {
      hideLiveSearch();
    }
    if (name === 'search' && event?.code === 'Enter') {
      onInputChange();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // eslint-disable-line

  const showLiveSearch = useCallback(() => {
    if (location.hash !== '#search') {
      history.push({
        hash: 'search',
      });
    }
  }, [location.hash, history]);

  const changeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="d-inline-block mr-4">
      <Input
        className={`${style.extInput} t-8`}
        placeholder={`${t('Search')}...`}
        prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        style={{ width: 200 }}
        onFocus={showLiveSearch}
      />
      <div className={`${showSearch ? `${style.livesearch} ${style.livesearchVisible}` : style.livesearch}`} id="livesearch">
        <button className={style.close} type="button" onClick={hideLiveSearch}>
          <i className="fe fe-x" />
        </button>
        <div className="container-fluid">
          <div className={style.wrapper}>
            <input
              type="search"
              className={style.searchInput}
              value={searchText}
              onChange={changeSearchText}
              autoComplete="off"
              placeholder={t('Type to search')}
              ref={searchInputRef}
              name="search"
            />
            <ul className={style.options}>
              <li className={style.option}>
                <Checkbox checked>{t('Search within app')}</Checkbox>
              </li>
              <li className={style.option}>{t('Press enter to search')}</li>
            </ul>
            {searchText && !fetching && isEmpty(documentsData) && isEmpty(imagesData) && (
              <div className={style.results}>
                <div className={style.resultsTitle}>
                  <span>{t('No Results Found')}</span>
                </div>
              </div>
            )}

            {fetching && (
              <div className={style.results}>
                <Spin />
              </div>
            )}
            {searchText && !fetching && (
              <div className={style.results}>
                <div className="row">
                  {!isEmpty(documentsData) && (
                    <div className="col-lg-4">
                      <div className={style.resultsTitle}>
                        <span>
                          {t('Documents')} ({documentsData.length})
                        </span>
                      </div>
                      {documentsData.map((item) => (
                        <a href={item.to} rel="noreferrer" target="_blank" key={`${item.title}_${item.size}`}>
                          <div className={style.resultContent}>
                            <div className={style.resultThumb}>
                              <i className={classNames('fe', item.icon)} />
                            </div>
                            <div className={style.result}>
                              <div className={style.resultText}>{item.title}</div>
                              <div className={style.resultSource}>{item.size}</div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  )}
                  {!isEmpty(imagesData) && (
                    <div className="col-lg-4">
                      <div className={style.resultsTitle}>
                        <span>
                          {t('Images')} ({imagesData.length})
                        </span>
                      </div>

                      {imagesData.map((item) => (
                        <a href={item.to} rel="noreferrer" target="_blank" key={`${item.title}`}>
                          <div className={style.resultContent}>
                            <div className={style.resultThumb} style={{ backgroundImage: `url(${item.avatar})` }} />
                            <div className={style.result}>
                              <div className={style.resultText}>{item.title}</div>
                              <div className={style.resultSource}>{item.description}</div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
