import React, { useEffect, useRef } from 'react';

const Weather: React.FC = () => {
  const weatherRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const gotFrame = weatherRef.current ? !!weatherRef.current?.getElementsByTagName('iframe')[0] : false;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof __weatherwidget_init === 'function' && !gotFrame) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      __weatherwidget_init();
    }
  }, []);
  return (
    <div>
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/en/53d41n2d99/liverpool/"
        data-label_1="LIVERPOOL"
        data-label_2="WEATHER"
        data-font="Trebuchet MS"
        data-days="5"
        data-theme="pure"
        data-textcolor="#3f8b3f"
        target="_blank"
        rel="noreferrer"
        ref={weatherRef}
      >
        LIVERPOOL WEATHER
      </a>
    </div>
  );
};

export default Weather;
