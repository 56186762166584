import React, { MouseEvent, SyntheticEvent, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-use';
import { Menu, ConfigProvider } from 'antd';
import { Link } from 'react-router-dom';
import styles from './UserMenu.module.scss';
import { setActiveClub, setActiveTournament } from '../../modules/core/slice';
import { useDispatch } from '../../store';

type City = {
  city: string;
  items: {
    label: string;
    value: string;
  }[];
};

export type ClubEvent = {
  country: string;
  cities: City[];
};

type Props = {
  data: ClubEvent[];
  title: string;
  type: 'tournaments' | 'clubs';
};

const DropdownMenu: React.FC<Props> = ({ type, data, title }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const { dataset } = event.target as HTMLElement;
      if (dataset.id) {
        dispatch((type === 'clubs' ? setActiveClub : setActiveTournament)(dataset.id));
      }
      const targetUrl = type === 'clubs' ? '/' : '/tournament-dashboard-kpi/tests';

      if (location.pathname !== targetUrl) {
        history.push(targetUrl);
      }
    },
    [dispatch, type, location, history],
  );

  const handleMenuClick = ({ domEvent }: { domEvent: SyntheticEvent }) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();
  };

  return (
    <ConfigProvider direction="rtl">
      <Menu mode="vertical" className={styles.menu}>
        <Menu.SubMenu key={1} onTitleClick={handleMenuClick} title={<span>{title}</span>}>
          {data.map((item) => (
            <Menu.ItemGroup key={item.country} className={styles.submenu} title={item.country}>
              {item.cities.map((city) => (
                <Menu.SubMenu key={city.city} className={styles.submenu} title={city.city}>
                  {city.items.map((item) => (
                    <Menu.Item key={item.label} className={styles.menuItem}>
                      <Link to="" onClick={onClick} data-id={item.value}>
                        {item.label}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
            </Menu.ItemGroup>
          ))}
        </Menu.SubMenu>
      </Menu>
    </ConfigProvider>
  );
};

export default DropdownMenu;
