import React, { useCallback } from 'react';
import Chat from './Chat';

import { useDispatch, useSelector } from '../../store';
import { userNameSelector } from '../../modules/user/selectors';
import { addChatMessage } from '../../modules/tournamentDashboard/slice';
import { getChatThreadMessages } from '../../modules/tournamentDashboard/selectors';

export default function ChatIndex({ className }: { className: string }): JSX.Element {
  const dispatch = useDispatch();
  const threads = useSelector(getChatThreadMessages);
  const addMessage = useCallback(
    (message) => {
      dispatch(addChatMessage(message));
    },
    [dispatch],
  );
  const userName = useSelector(userNameSelector);

  return <Chat threads={threads} addMessage={addMessage} userName={userName} className={className} />;
}
