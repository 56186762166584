import { Area, AreaChart, XAxis, YAxis } from 'recharts';
import React from 'react';
import style from '../Table.module.scss';

function getChartColumn(): { render: (item: Record<string, unknown>[]) => JSX.Element; width: string } {
  return {
    render: (item: Record<string, unknown>[]) => (
      <div className={style.column__row}>
        <div className={style.column__chart}>
          <AreaChart width={110} height={50} data={item}>
            <defs>
              <linearGradient id="colorPitch" x1="0" y1="0" x2="0" y2="1">
                <stop offset="15%" stopColor="#669a32" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#669a32" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis tick={false} hide />
            <YAxis tick={false} hide />
            <Area type="linear" dataKey="Pitch" stroke="#669a32" fill="url(#colorPitch)" dot={false} isAnimationActive={false} />
          </AreaChart>
        </div>
      </div>
    ),
    width: '130px',
  };
}

export default getChartColumn;
