import React from 'react';
import { useTranslation } from 'react-i18next';
import sign from '../../images/graspro_sign_rgb.svg';
import { useSelector } from '../../store';
import { themeSettingsSelector } from '../../modules/core/selectors';
import style from './Footer.module.scss';

const Footer = (): JSX.Element => {
  const { t } = useTranslation('components.footer');

  const { logo } = useSelector(themeSettingsSelector);

  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a href="https://www.gras.pro" target="_blank" rel="noopener noreferrer" className={style.logo}>
          <img src={sign} alt={logo} />
          <strong className="mr-2">{logo}</strong>
        </a>
        <br />
        <p className="mb-0">
          {t('Copyright ©')} {new Date().getFullYear()} {t('Get in touch')}:{' '}
          <a href="mailto:info@gras.pro" rel="noopener noreferrer">
            info@gras.pro
          </a>
          {' | '}
          <a href="https://www.gras.pro/policy.pdf" target="_blank" rel="noopener noreferrer">
            {t('Terms and Privacy policy')}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
