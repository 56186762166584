import React, { useCallback, useEffect, useMemo } from 'react';
import { noop } from 'lodash';
import { Divider, Result, Spin, Tabs } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import MaintenanceTasksModal, { timePickerFormat } from '../MaintenanceTasksModal';
import styles from './IrrigationModal.module.scss';
import { useSelector } from '../../store';
import { getProfileFields } from '../../modules/profile/selectors';
import { getMowingByClubId } from '../../modules/tasks/selectors';
import { IrrigationField } from '../../modules/tasks/types';
import IrrigationForm from './IrrigationForm';
import { TaskActionType } from '../TasksMenu/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isSending: boolean;
  onSubmit: <T>(data: T) => void;
  type?: TaskActionType;
};

export type FormInitialValues = {
  date: string;
  time: string[];
  status: number | null;
  employees: number[];
  selectedFields: number[];
  description: string;
  fields: IrrigationField[];
};
const formInitialValues = {
  date: moment().toISOString(),
  time: [moment(moment(), timePickerFormat).startOf('hour').toISOString(), moment(moment(), timePickerFormat).startOf('hour').add(1, 'hour').toISOString()],
  status: null,
  employees: [] as number[],
  selectedFields: [] as number[],
  description: '',
  fields: [] as IrrigationField[],
} as FormInitialValues;

const formFieldInitialValues = {
  id: null,
  amount: 0,
  duration: [moment(moment(), timePickerFormat).startOf('hour'), moment(moment(), timePickerFormat).startOf('hour').add(1, 'hour')],
  areas: [],
  machines: [],
};

const DrawerContent: React.FC = () => <p>Drawer contents...</p>;

const IrrigationModalView: React.FC<Props> = ({ isOpen = false, onClose = noop, isLoading = false, isSending = false, onSubmit = noop, type }: Props) => {
  const { t } = useTranslation('task');
  const { t: validationErrorsT } = useTranslation('common.validationErrors');
  const { t: commonT } = useTranslation('common');
  // get labels for tabs
  const profileFields = useSelector(getProfileFields)('irrigation');

  const translationErrors = useMemo(
    () => ({
      required: validationErrorsT('This field is required'),
      range: (from: number, to: number): string => validationErrorsT('Valid range', { from, to }),
    }),
    [validationErrorsT],
  );

  const schema = yup.object().shape({
    date: yup.date().required(translationErrors.required).typeError(translationErrors.required),
    time: yup.array().of(yup.date()).required(translationErrors.required).typeError(translationErrors.required),
    employees: yup.array().of(yup.number()).required(translationErrors.required).min(1, translationErrors.required),
    selectedFields: yup.array().of(yup.number()).required(translationErrors.required).min(1, translationErrors.required),
    fields: yup.array().of(
      yup.object().shape({
        areas: yup.array().of(yup.number()).min(1, translationErrors.required),
        amount: yup.number().required(translationErrors.required).min(0, translationErrors.range(0, 100)).typeError(translationErrors.required),
        duration: yup.array().of(yup.date()).required(translationErrors.required).typeError(translationErrors.required),
      }),
    ),
    status: yup.number().typeError(translationErrors.required).required(translationErrors.required),
  });

  // init form and tasks form array
  const form = useForm<FormInitialValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(schema),
  });
  const {
    reset,
    getValues,
    control,
    setValue,
    formState: { errors: formErrors },
    clearErrors,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
    keyName: 'fieldsKeyId',
  });

  // setup data from tasks api to form
  const mowingData = useSelector(getMowingByClubId);
  useEffect(() => {
    const values = {
      ...formInitialValues,
      ...getValues(),
      description: mowingData?.description,
      employees: mowingData?.employees,
      status: mowingData?.status,
      fields: mowingData?.fields,
      selectedFields: mowingData?.fields?.map((field) => field.id),
    };
    reset(values);
  }, [reset, mowingData, getValues, isOpen]);

  const getFieldLabel = useCallback(
    (id: number) => {
      return profileFields.find((field) => field.id === Number(id))?.name;
    },
    [profileFields],
  );

  // connect "Fields" and tabs – add/remove
  const onDeselectFields = (id: string | number) => {
    const index = fields.findIndex((field) => field.id === Number(id));
    if (index !== -1) remove(index);
  };

  const onSelectFields = (id: string | number) => {
    append({ ...formFieldInitialValues, id: Number(id) });
  };

  const onTabsEdit = (id: React.MouseEvent | React.KeyboardEvent | string) => {
    const index = fields.findIndex((field) => field.id === Number(id));

    if (index !== -1) {
      remove(index);
      setValue(
        'selectedFields',
        getValues('selectedFields').filter((fieldId) => fieldId !== Number(id)),
      );
      clearErrors();
    }
  };

  const tabErrorsClasses: string | undefined = useMemo(
    () => (formErrors?.fields ? formErrors.fields.map((e, i) => e && styles[`tabs-not-valid-${i + 1}-tab`]).join(' ') : ''),
    [formErrors],
  );

  return (
    <FormProvider {...form}>
      <MaintenanceTasksModal
        title={t('Maintenance Task - Irrigation')}
        isOpen={isOpen}
        drawerTitle={t('Irrigation')}
        onClose={onClose}
        onDeselectFields={onDeselectFields}
        onSelectFields={onSelectFields}
        drawerContent={DrawerContent}
        onSubmit={onSubmit}
        isSending={isSending}
        context="irrigation"
        okButtonDisabled={isSending || isLoading || (type === TaskActionType.EDIT && !mowingData)}
      >
        {isLoading && <Result icon={<Spin indicator={<LoadingOutlined className={styles.loading} spin />} />} title={commonT('Loading')} />}
        {!isLoading && fields.length === 0 && (
          <>
            <Result icon={<InfoCircleOutlined className={styles.noResult} />} title={validationErrorsT('No fields selected')} />
            <Divider />
          </>
        )}
        {!isLoading && fields.length !== 0 && (
          <div className={styles.borderedTab}>
            <Tabs className={tabErrorsClasses} type="editable-card" hideAdd onEdit={onTabsEdit} size="large">
              {fields.map((field, index) => (
                <Tabs.TabPane tab={getFieldLabel(field.id)} key={field.id} forceRender>
                  <IrrigationForm isLoading={isSending} index={index} field={field} />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
        )}
      </MaintenanceTasksModal>
    </FormProvider>
  );
};

export default IrrigationModalView;
