import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ns from './routes/nameSpaces';

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns,
    fallbackLng: 'en',
    cleanCode: true,
    defaultNS: 'translations',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [
        {
          expirationTime: process.env.NODE_ENV === 'development' ? 1000 : 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          projectId: process.env.REACT_APP_LOCIZE_ID || '',
          apiKey: process.env.REACT_APP_LOCIZE_KEY || '',
          referenceLng: 'en',
        },
      ],
    },

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
  });

export default i18n;
