import React from 'react';
import PageLayout from '../PageLayout';
import VenuesTableCard from './VenuesTableCard';
import ImportantDatesCard from './ImportantDatesCard';
import MediaTableCard from './MediaTableCard';
import TournamentLogoCard from './TournamentLogoCard';
import ChatCard from './ChatCard';
import FlipCountDownCard from './FlipCountDownCard';
import { MenuLeftType } from '../PageLayout/types';

const TournamentDashboard: React.FC = () => {
  return (
    <PageLayout menuLeftType={MenuLeftType.TOURNAMENT}>
      <div className="row">
        <div className="col-xs-12 col-xl-4">
          <TournamentLogoCard />
        </div>
        <div className="col-xs-12 col-xl-8">
          <ImportantDatesCard />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xs-12 col-xl-4">
          <FlipCountDownCard />
        </div>
        <div className="col-xs-12 col-xl-8">
          <ChatCard />
        </div>
      </div>
      <div className="mt-4">
        <VenuesTableCard />
      </div>
      <div className="mt-4">
        <MediaTableCard />
      </div>
    </PageLayout>
  );
};

export default TournamentDashboard;
