import React from 'react';
import { MenuLeftType } from '../components/PageLayout/types';

export interface RouteType {
  section?: MenuLeftType; // app section
  group?: string; // group name for menu rendering
  name: string; // menu name for each route path
  href?: string; // menu link
  path?: string[]; // path rule
  module?: string; // component name
  exact?: boolean; // exact for Path
  icon?: string; // icon name for menu
  menu?: boolean; // show in menu
  private: boolean; // auth protected
  namespace?: string; // i18n namespace to load
  disabled?: boolean; // disable click
  children?: RouteType[];
}

export interface RouteProps {
  match: {
    params: { [key: string]: string | undefined };
    url: string;
  };
  route: RouteType;
}

export type RoutesModules = { [key: string]: React.FC<RouteProps> };

export enum RoutesGroups {
  main = '',
  'Soil Analysis' = 'Soil Analysis',
  'Equipment Management' = 'Equipment Management',
}
