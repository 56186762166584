import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Context, Profile, ProfileState } from './types';
import api from './api';

export const profileAdapter = createEntityAdapter({
  selectId: (profile: Profile) => profile.id,
});

export const loadProfile = createAsyncThunk('profile/loadProfile', async (payload: { clubId: string; context: string }) => api.loadProfile(payload));

const initialState: ProfileState = {
  fetching: {},
  mowing: profileAdapter.getInitialState(),
  irrigation: profileAdapter.getInitialState(),
};

export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadProfile.pending, (state) => {
      state.fetching.profile = true;
    });
    builder.addCase(loadProfile.fulfilled, (state, action) => {
      const context = action.meta.arg.context.toLowerCase() as Context;
      state.fetching.profile = false;
      state[context] = profileAdapter.upsertOne(state[context], {
        ...action.payload,
        id: action.meta.arg.clubId,
      });
    });
    builder.addCase(loadProfile.rejected, (state) => {
      state.fetching.profile = false;
    });
  },
});

export const {
  actions,
  actions: { resetProfile },
} = slice;

export default slice.reducer;
