import React, { useCallback, useEffect, useState } from 'react';
import { noop } from 'lodash';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import MowingModalView from './MowingModalView';
import { useDispatch, useSelector } from '../../store';
import { loadProfile } from '../../modules/profile/slice';
import { isProfileLoadingSelector } from '../../modules/profile/selectors';
import { createMowingTask, loadMowingTask } from '../../modules/tasks/slice';
import { mowingAdapter } from '../../modules/tasks/adapter';
import { getMowingFetching, getMowingSending } from '../../modules/tasks/selectors';
import { addMessage } from '../../modules/core/slice';
import { TaskActionType } from '../TasksMenu/types';
import { getActiveClubId } from '../../modules/core/selectors';

type Props = {
  type?: TaskActionType;
  taskId?: number;
  isOpen: boolean;
  onClose: () => void;
};

const MowingModal: React.FC<Props> = ({ type = TaskActionType.CREATE, taskId, isOpen = false, onClose = noop }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('task');
  const clubId = useSelector(getActiveClubId);
  // run load profile api data
  useEffect(() => {
    dispatch(loadProfile({ clubId, context: 'Mowing' }));
  }, [dispatch, clubId]);

  // run load tasks api data
  useEffect(() => {
    if (type === TaskActionType.EDIT && taskId && isOpen) {
      dispatch(loadMowingTask({ clubId, mowingId: taskId }));
    }
  }, [type, taskId, dispatch, clubId, isOpen]);

  // watch loading profile and tasks data
  const isProfileLoading = useSelector(isProfileLoadingSelector);
  const isMowingLoading = useSelector(getMowingFetching)('mowing');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setIsLoading(isProfileLoading || !!isMowingLoading), [setIsLoading, isProfileLoading, isMowingLoading]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        await dispatch(createMowingTask({ clubId, data: mowingAdapter(data) })).then(unwrapResult);
        dispatch(addMessage({ id: nanoid(), message: t('Task successfully created'), type: 'success' }));
        onClose();
      } catch (error) {
        console.log(error); // eslint-disable-line
      }

      // TODO refresh tasks list or add task from response
    },
    [dispatch, clubId, t, onClose],
  );

  const isMowingTaskSending = useSelector(getMowingSending)('mowing');

  return <MowingModalView isOpen={isOpen} onClose={onClose} isLoading={isLoading} onSubmit={onSubmit} isSending={!!isMowingTaskSending} type={type} />;
};

export default MowingModal;
