import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BoardColumn from './BoardColumn';
import { AgileCard, AgileCards, Status } from '../../modules/taskPlanner/types';
import BoardDropdown from './BoardDropdown';

type Props = {
  cards: AgileCards;
  onDrop: (card: AgileCard, index: number, status: Status) => void;
};

const AgileBoard: React.FC<Props> = ({ cards, onDrop }: Props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="row">
        <BoardColumn status="backlog" title="Backlog" onDrop={onDrop} cards={cards.backlog} />
        <BoardColumn status="toDo" title="To do" onDrop={onDrop} cards={cards.toDo} />
        <BoardColumn status="inProgress" title="In progress" onDrop={onDrop} cards={cards.inProgress} />
        <BoardColumn status="completed" title="Completed" onDrop={onDrop} cards={cards.completed} extraContent={<BoardDropdown />} />
      </div>
    </DndProvider>
  );
};

export default AgileBoard;
