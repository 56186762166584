import { createSelector } from 'reselect';
import { State } from 'store';
import { memoize } from 'lodash';
import { profileAdapter } from './slice';
import { Context, DirectionsData, FieldsData } from './types';
import { getActiveClubId } from '../core/selectors';

const selectProfileByClubIdSelector = memoize((state: State, context: string, clubId: string) => {
  const { selectById } = profileAdapter.getSelectors((state: State) => state.profile[context as Context] || profileAdapter.getInitialState());
  return selectById(state, clubId);
});

const selectProfileByClubId = createSelector([getActiveClubId, (state) => state, (state: State, context: string) => context], (clubId, state, context) => {
  return selectProfileByClubIdSelector(state, context, clubId);
});

const getProfileEmployeesForFormSelector = createSelector(selectProfileByClubId, (profile) =>
  profile?.employees
    ? profile.employees.map((employee) => ({
        value: employee.id.toString(),
        label: `${employee.firstName} ${employee.lastName}`.trim(),
      }))
    : [],
);

const getProfileStatusForFormSelector = createSelector(selectProfileByClubId, (profile) =>
  profile?.status
    ? Object.entries(profile.status).map(([key, label]) => ({
        value: key,
        label,
      }))
    : [],
);

const getProfileFieldsForFormSelector = createSelector(selectProfileByClubId, (profile) =>
  profile?.fields
    ? profile.fields.map((field) => ({
        value: field.id.toString(),
        label: field.name,
      }))
    : [],
);

const getProfileMachinesForFormSelector = createSelector(selectProfileByClubId, (profile) =>
  profile?.machines
    ? profile.machines.map((machine) => ({
        value: machine.id.toString(),
        label: machine.model,
      }))
    : [],
);

const getProfileAreasByFieldIdForFormSelector = createSelector(
  [(state: State, fieldId: number, context: string) => selectProfileByClubId(state, context), (state: State, fieldId: number) => fieldId],
  (profile, fieldId) => {
    const field = profile?.fields?.find((field) => field.id === fieldId);
    return (
      field?.areas?.map((area) => ({
        value: area.id.toString(),
        label: area.name,
      })) || []
    );
  },
);

export const getProfileAreasByFieldIdForForm = (state: State) => (fieldId: number, context: string): { value: string; label: string }[] =>
  getProfileAreasByFieldIdForFormSelector(state, fieldId, context);

export const getProfileForFormSelector = createSelector(
  [getProfileEmployeesForFormSelector, getProfileStatusForFormSelector, getProfileFieldsForFormSelector, getProfileMachinesForFormSelector],
  (employees, status, fields, machines) => ({
    employees,
    status,
    fields,
    machines,
  }),
);

export const getProfileForForm = (state: State) => (context: string): { [key: string]: { value: string; label: string }[] } =>
  getProfileForFormSelector(state, context);

const getProfileFieldsSelector = createSelector(selectProfileByClubId, (profile) => profile?.fields || []);
export const getProfileFields = (state: State) => (context: string): FieldsData[] => getProfileFieldsSelector(state, context);

const getProfileFieldsByIdSelector = createSelector(
  [
    (state: State, clubId: string, fieldId: number, context: string) => selectProfileByClubId(state, clubId, context),
    (state: State, clubId: string, fieldId: number) => fieldId,
  ],
  (profile, fieldId) => (profile?.fields ? profile.fields.find((field) => field.id === fieldId) : undefined),
);
export const getProfileFieldsById = (state: State) => (clubId: string, fieldId: number, context: string): FieldsData | undefined =>
  getProfileFieldsByIdSelector(state, clubId, fieldId, context);

const getProfileDirectionsSelector = createSelector(selectProfileByClubId, (profile) => profile?.directions || []);
export const getProfileDirections = (state: State) => (context: string): DirectionsData[] => getProfileDirectionsSelector(state, context);

export const isProfileLoadingSelector = createSelector(
  (state: State) => state.profile.fetching.profile,
  (fetching) => fetching,
);
