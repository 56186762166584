import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'store';

import { addNotification, removeNotification } from './slice';

export function useAddNotification(): (text: string, type: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (text, type) => {
      const id = uuidv4();
      dispatch(addNotification({ id, text, type }));
      setTimeout(() => {
        dispatch(removeNotification(id));
      }, 5000);
    },
    [dispatch],
  );
}

export function useSetUserLanguage(): (lng: string) => void {
  const { i18n } = useTranslation();
  return useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
    },
    [i18n],
  );
}
