import React from 'react';
import Search from './Search';
import { useSelector } from '../../store';
import { searchDocumentsSelector, searchImagesSelector } from '../../modules/dashboard/selectors';

// TODO remove unnecessary index files everywhere
export default function SearchIndex(): JSX.Element {
  const imagesData = useSelector(searchImagesSelector);
  const documentsData = useSelector(searchDocumentsSelector);

  return <Search imagesData={imagesData} documentsData={documentsData} />;
}
