import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import bar from 'highcharts/modules/windbarb';

bar(Highcharts);

function getRandomValue(max: number) {
  const min = 0;
  return (Math.random() * (max - min) + min).toFixed(1);
}

const UsageCategoriesChart: React.FC<{ selectedData: string; selectedRow: string }> = ({
  selectedData,
  selectedRow,
}: {
  selectedData: string;
  selectedRow: string;
}) => {
  const max = useMemo(() => {
    if (selectedData === '7') {
      return 5;
    }
    if (selectedData === '30') {
      return 20;
    }
    return 50;
  }, [selectedData]);

  const data = useMemo(
    () => {
      return {
        training: +getRandomValue(max),
        match: +getRandomValue(max),
        other: +getRandomValue(max),
        extraTime: +getRandomValue(max),
        penalties: +getRandomValue(max),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [max, selectedRow],
  );

  const options = useMemo(() => {
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: ['Total', 'Training', 'Match', 'Other', 'Extra Time', 'Penalties'],
        title: {
          text: null,
        },
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Source Sans Pro',
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Source Sans Pro',
            overflow: 'justify',
          },
        },
        min: 0,
        title: {
          text: 'Hours of use',
          align: 'high',
        },
        plotLines: [
          {
            color: '#f00',
            width: 3,
            value: 6,
            label: {
              text: 'Max Usage',
              verticalAlign: 'middle',
              textAlign: 'center',
              // y: -10,
              style: {
                fontSize: '14px',
                fontWeight: 'normal',
                fontFamily: 'Source Sans Pro',
              },
            },
            zIndex: 4,
          },
        ],
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: <b>{point.y}</b> hours',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Source Sans Pro',
        },
      },
      plotOptions: {
        series: {
          pointWidth: 50,
          borderRadius: 5,
        },
        bar: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14px',
              fontWeight: 'normal',
              fontFamily: 'Source Sans Pro',
            },
          },
          showInLegend: true,
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '100%',
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          animation: false,
          data: [
            {
              y: +(data.extraTime + data.penalties + data.other + data.match + data.training).toFixed(1),
              name: 'Total',
              color: '#f28264',
            },
            { y: data.training, name: 'Training', color: '#439b22' },
            { y: data.match, name: 'Match', color: '#3f424f' },
            { y: data.other, name: 'Other', color: '#5e5e5f' },
            { y: data.extraTime, name: 'Extra Time', color: '#94949f' },
            { y: data.penalties, name: 'Penalties', color: '#eceee3' },
          ],
        },
      ],
    };
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default UsageCategoriesChart;
