import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MaintenanceTable from './MaintenanceTable';
import TabCard from '../../TabCard';

type Props = {
  selectedData: string;
  setSelectedData: (data: string) => void;
  selectedRow: string;
  setSelectedRow: (data: string) => void;
};

const MaintenanceTableCard: React.FC<Props> = ({ setSelectedData, selectedData, selectedRow, setSelectedRow }: Props): JSX.Element => {
  const { t } = useTranslation('tournamentDashboardKPI');
  const onChange = useCallback(
    (tabPosition: string) => {
      setSelectedData(tabPosition);
    },
    [setSelectedData],
  );

  const content = useMemo(() => {
    return [
      {
        title: t('7 Days'),
        content: <MaintenanceTable selectedRow={selectedRow} selectedData={selectedData} setSelectedRow={setSelectedRow} />,
      },
      {
        title: t('30 Days'),
        content: <MaintenanceTable selectedRow={selectedRow} selectedData={selectedData} setSelectedRow={setSelectedRow} />,
      },
      {
        title: t('60 Days'),
        content: <MaintenanceTable selectedRow={selectedRow} selectedData={selectedData} setSelectedRow={setSelectedRow} />,
      },
    ];
  }, [selectedData, selectedRow, t, setSelectedRow]);

  return <TabCard icon="fe fe-link" title={t('Maintenance')} content={content} onChange={onChange} />;
};

export default MaintenanceTableCard;
