import React, { useCallback } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import timeline from 'highcharts/modules/timeline';
import { Modal } from 'antd';

timeline(Highcharts);

const options = {
  plotOptions: {
    timeline: {
      opacity: 0.8,
    },
    series: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          textOutline: null,
          fontFamily: 'Source Sans Pro',
        },
      },
    },
  },
  chart: {
    type: 'timeline',
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    screenReaderSection: {
      beforeChartFormat: '<h5>{chartTitle}</h5><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{viewTableButton}</div>',
    },
    point: {
      valueDescriptionFormat: '{index}. {point.label}. {point.description}.',
    },
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  colors: ['#f5222d', '#f5222d', '#f5222d', '#fa8c16', '#52c41a', '#52c41a'],
  series: [
    {
      animation: false,
      data: [
        {
          x: Date.UTC(2020, 2, 23),
          name: 'Test 16',
          label: '23.02.2020',
          description: 'Rory Ferguson',
        },
        {
          x: Date.UTC(2020, 3, 12),
          name: 'Test 17',
          label: '12.03.2020',
          description: 'Andy Cole',
        },
        {
          x: Date.UTC(2020, 3, 16),
          name: 'Test 18',
          label: '16.03.2020',
          description: 'Andy Cole',
        },
        {
          x: Date.UTC(2020, 3, 22),
          name: 'Test 19',
          label: '22.03.2020',
          description: 'Kris Puzio',
        },
        {
          x: Date.UTC(2020, 4, 7),
          name: 'Test 20',
          label: '07.04.2020',
          description: 'Phil Fifield',
        },
        {
          x: Date.UTC(2020, 4, 28),
          name: 'Test 21',
          label: '28.04.2020',
          description: 'Phil Fifield',
        },
      ],
    },
  ],
};

type Props = {
  popup: { isOpen: boolean; type: string | null; location: string | null };
  setPopup: (props: { isOpen: boolean; type: string | null; location: string | null }) => void;
};

const PopupChart: React.FC<Props> = ({ popup, setPopup }: Props): JSX.Element => {
  const onClose = useCallback(() => {
    setPopup({ isOpen: false, type: null, location: null });
  }, [setPopup]);

  return (
    <Modal title={`${popup.type} - ${popup.location}`} visible={popup.isOpen} width={750} footer={false} onCancel={onClose}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Modal>
  );
};

export default PopupChart;
